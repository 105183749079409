export default {
	breakpoints: ['40em', '52em', '64em'],
	fontSizes: [16, 18, 20, 24, 32, 40, 48],
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	colors: {
		black: '#000',
		white: '#fff',
		highlight: 'hsla(205, 100%, 40%, 0.125)',
		primary: '#027B48',
		gray100: '#666666',
		gray90: '#757575',
		gray80: '#858585',
		gray70: '#949494',
		gray60: '#A3A3A3',
		gray50: '#B2B2B2',
		gray40: '#C2C2C2',
		gray30: '#D1D1D1',
		gray20: '#E0E0E0',
		gray10: '#F0F0F0',
		gray05: '#FAFAFA',
		green100: '#027B48',
		green90: '#1F9362',
		green80: '#389F74',
		green70: '#51AB85',
		green60: '#6AB797',
		green50: '#82C3A8',
		green40: '#9BCFB9',
		green30: '#B4DBCB',
		green20: '#CDE7DC',
		green10: '#E6F3EE',
		deepGreen100: '#084646',
		deepGreen90: '#215858',
		deepGreen80: '#396B6B',
		deepGreen70: '#527E7E',
		deepGreen60: '#6B9090',
		deepGreen40: '#9CB5B5',
		deepGreen30: '#B5C8C8',
		deepGreen20: '#CEDADA',
		deepGreen10: '#E6EDED',
		red100: '#FF372B',
		red90: '#FF4B40',
		red80: '#FF5F55',
		red70: '#FF736A',
		red60: '#FF8780',
		red50: '#FF9B95',
		red40: '#FFAFAA',
		red30: '#FFC3BF',
		red20: '#FFD7D5',
		red10: '#FFEBEA',
		orange100: '#F0694B',
		orange90: '#F2785D',
		orange80: '#F3876F',
		orange70: '#F59681',
		orange60: '#F6A593',
		orange50: '#F8B4A5',
		orange40: '#F9C3B7',
		orange30: '#FBD2C9',
		orange20: '#FCE1DB',
		orange10: '#FEF0ED',
		yellow100: '#FFC350',
		yellow90: '#FFC961',
		yellow80: '#FFCF73',
		yellow70: '#FFD584',
		yellow60: '#FFDB96',
		yellow50: '#FFE1A7',
		yellow40: '#FFE7B9',
		yellow30: '#FFEDCA',
		yellow20: '#FFF3DC',
		yellow10: '#FFF9ED',
	},
	fonts: {
		body: 'founders, sans-serif',
		heading: 'inherit',
		monospace: 'Menlo, monospace',
	},
	forms: {
		input: {
			border: '1px solid gray30',
			color: 'black',
			display: 'block',
		},
		select: {
			borderRadius: 9999,
		},
		textarea: {
			border: '1px solid',
			borderColor: 'gray30',
		},
		label: {
			display: 'block',
		},
		radio: {},
		checkbox: {},
	},
	fontWeights: {
		light: 100,
		regular: 400,
		medium: 500,
		semibold: 600,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.25,
	},
	sizes: {
		avatar: 32,
		avatarXl: 55,
	},
	radii: {
		default: 4,
		callout: '8px',
		circle: 99999,
	},
	shadows: {
		card: '0 0 4px rgba(0, 0, 0, .125)',
	},
	// rebass variants
	text: {
		heading: {
			fontFamily: 'heading',
			lineHeight: 'heading',
			fontWeight: 'heading',
		},
		display: {
			fontFamily: 'heading',
			fontWeight: 'heading',
			lineHeight: 'heading',
			fontSize: [5, 6, 7],
		},
		caps: {
			textTransform: 'uppercase',
			letterSpacing: '0.1em',
		},
	},
	variants: {
		avatar: {
			width: 'avatar',
			height: 'avatar',
			borderRadius: 'circle',
		},
		avatarXl: {
			width: 'avatarXl',
			height: 'avatarXl',
			borderRadius: 'circle',
		},
		card: {
			p: 0,
			bg: 'transparant',
			boxShadow: 'none',
			border: '1px solid #e6e6e6',
		},
		link: {
			color: 'black',
			transition: 'color .4s',
			textDecoration: 'none',
			':hover,:focus,.active': {
				color: 'green100',
			},
		},
		link2: {
			color: 'white',
			transition: 'color .4s',
			textDecoration: 'none',
			':hover,:focus,.active': {
				color: 'white',
			},
		},
		outlineButtonLink: {
			width: '100%',
			display: 'block',
			textDecoration: 'none',
			border: '1px solid',
			borderRadius: '4px',
			borderColor: 'green100',
		},
		menuLink: {
			color: 'black',
			textDecoration: 'none',
			transition: 'color .4s',
			':hover,:focus,.active': {
				color: 'gray90',
			},
		},
		opacityLink: {
			color: 'black',
			textDecoration: 'none',
			transition: 'opacity .4s',
			':hover,:focus,.active': {
				opacity: '0.5',
			},
		},
		reset: {
			display: 'inline-block',
			color: 'currentColor',
			textDecoration: 'none',
		},
		textLink: {
			color: 'black',
			textDecoration: 'none',
			':hover,:focus,.active': {
				color: 'green100',
				textDecoration: 'underline',
				'&:after': {
					content: '"\\2192"',
				},
			},
			'&::after': {
				content: '"\\2192"',
				marginLeft: '.25em',
				height: '100%',
				width: '100%',
			},
		},
		underline: {
			textDecoration: 'underline',
			transition: 'text-decoration-color .2s',
			textDecorationColor: 'currentColor',
			':hover,:focus,.active': {
				textDecorationColor: 'transparent',
			},
		},
		underlineReverse: {
			textDecoration: 'underline',
			transition: 'text-decoration-color .2s',
			textDecorationColor: 'transparent',
			':hover,:focus,.active': {
				textDecorationColor: 'currentColor',
			},
		},
		nav: {
			color: 'inherit',
			textDecoration: 'none',
			':hover,:focus,.active': {
				color: 'green100',
			},
		},
	},
	buttons: {
		textLink: {
			color: 'green100',
			textDecoration: 'none',
			bg: 'transparent',
			border: 'none',
			':hover,:focus,.active': {
				color: 'green100',
				textDecoration: 'underline',
				cursor: 'pointer',
			},
		},
		primary: {
			fontSize: 2,
			fontWeight: 'bold',
			color: 'white',
			bg: 'green100',
			borderRadius: 'default',
		},
		error: {
			fontSize: 2,
			fontWeight: 'bold',
			color: 'white',
			bg: 'red100',
			borderRadius: 'default',
		},
		secondary: {
			variant: 'buttons.primary',
			color: 'green100',
			bg: 'white',
		},
		solid: {
			borderRadius: '4px',
			padding: '13px 50px',
			fontSize: '20px',
			lineHeight: '28px',
			background: 'green100',
			border: '1px solid green100',
			color: 'white',
			outline: '0',
			cursor: 'pointer',
			transition: 'all 0.2s ease-in-out',
			':hover, :focus, .active': {
				borderTopRightRadius: '20px',
				borderBottomLeftRadius: '20px',
			},
		},
		outline: {
			fontSize: '20px',
			borderRadius: '4px',
			padding: '1rem 2rem',
			color: '#027B48',
			bg: 'transparent',
			border: '1px solid #027B48',
			cursor: 'pointer',
		},
		reset: {
			background: 'transparent',
			padding: 0,
			margin: 0,
			border: 0,
			borderRadius: 0,
			outline: 0,
		},
		blank: {
			color: 'black',
			svg: {
				fill: 'black',
			},
			bg: 'transparent',
			border: 'none',
			':hover,:focus,.active': {
				cursor: 'pointer',
			},
		},
	},
}
