import React from 'react'
import styled from 'styled-components'

const StyledSvg = styled.svg`
	width: 100%;
	height: 100%;
	max-width: 300px;
	display: block;
	margin: auto;
`

const Default = (props) => {
	const { color1, color2, color3 } = props
	return (
		<StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 30">
			<path
				fill={color1}
				d="M13.1 26.1l-9.4-5.2h-.1l7.1 7.4L9 30l-9-9.4 2.9-2.8 8.6 4.8L7 13.9l2.9-2.8 9 9.4-1.9 1.8L9.9 15h-.1l4.8 9.5-1.5 1.6zM25.9 14.3c.2.4.6.5.9.3.2-.1.4-.3.6-.5l.6 1.1c-.2.5-.5.9-1.2 1.3-.9.5-1.8.3-2.4-.5 0 1.2-.8 2.2-1.9 2.9-1.7.9-3.1.6-3.9-.7-.8-1.5.1-2.8 1.7-4.2l1.9-1.6-.1-.2c-.5-.9-1.2-1.2-2.2-.7-.9.5-1.1 1.3-.9 2.2l-2 1c-.6-1.8 0-3.6 2.1-4.7s4-.9 5.1 1.2l1.7 3.1zm-3-.5l-1.3 1.1c-.8.7-1.4 1.2-1 2 .3.5.9.6 1.6.3 1.1-.6 1.5-2 .8-3.3l-.1-.1zM26.7 8.6l-1.2.4-.5-1.4.6-.3c.8-.4.9-.9.8-1.8l-.1-1.3 1.4-.5.8 2.4 2.3-.8.6 1.7-2.3.8 1.4 4.3c.3.7.7 1 1.4.7.5-.2.9-.5 1.3-.9l.5 1.5c-.5.7-1.1 1.2-2.1 1.5-1.3.4-2.6.2-3.3-1.6l-1.6-4.7zM32.5 6.7L32.2 5l1.3-.3-.2-.7C32.9 1.9 34 .5 36 .1c1.6-.3 2.8.3 3.4 1.6l-1.5.8c-.3-.5-.8-.8-1.4-.7-.7.1-1 .8-.8 1.6l.2.9 2.4-.5.3 1.7-2.3.5 1.4 7.2-2.4.5-1.4-7.2-1.4.2zM46.3 5.8c-.5-.2-.9-.4-1.5-.3-1.3.1-2.1 1-2 2.6l.2 4.5-2.5.1-.4-9.1 2.5-.1.1 1.7c.3-1.1 1.2-2 2.4-2.1.6 0 1 .1 1.3.3l-.1 2.4zM55.3 11.2c-.1.5.2.7.5.8.2 0 .5 0 .8-.1l-.2 1.3c-.4.3-1 .5-1.8.4-1-.1-1.6-.8-1.7-1.8-.7 1-1.9 1.4-3.3 1.2-1.9-.2-2.9-1.3-2.7-2.9.2-1.7 1.7-2.2 3.8-2.4l2.5-.2v-.2c.1-1-.3-1.7-1.4-1.8-1-.1-1.7.4-2 1.3l-2.2-.5c.5-1.8 2.1-2.9 4.5-2.6 2.3.3 3.7 1.6 3.4 4l-.2 3.5zM53.2 9l-1.7.1c-1.1.1-1.9.2-2 1.1-.1.6.4 1 1.1 1.1 1.3.2 2.4-.7 2.6-2.1V9z"
			/>
			<path
				fill={color2}
				d="M62.9 15.7c-1.5-.6-2.3-2.2-1.6-3.8.7-1.6 2.3-2.2 3.9-1.6 1.5.6 2.3 2.2 1.6 3.9-.7 1.5-2.4 2.1-3.9 1.5z"
			/>
			<path
				fill={color3}
				d="M71 20.3l-2-1.5 5.4-7.3 2 1.5-.9 1.2c1-.6 2.3-.4 3.4.4 2 1.4 1.7 3.3.5 4.9L76 24l-2-1.5 3.1-4.2c.8-1.1.9-2.1-.1-2.8-1-.7-2.2-.4-3.1.8l-2.9 4zM79.6 28.3c-1.9-2.1-1.7-4.9.4-6.7 2.1-1.8 4.9-1.7 6.8.4 1.9 2.1 1.7 4.9-.4 6.8-2.2 1.8-5 1.6-6.8-.5zM81 27c1 1.1 2.4 1 3.7-.2 1.3-1.1 1.7-2.5.7-3.6s-2.4-.9-3.7.2c-1.3 1.1-1.7 2.5-.7 3.6z"
			/>
		</StyledSvg>
	)
}

export default Default
