import React from 'react'
import styled from 'styled-components'
import { Flex, BoxProps } from 'rebass/styled-components'

interface IArrowProps {
	backwards: boolean
}

const Arrow = styled.svg<IArrowProps>`
	transform: ${(props) =>
		props.backwards ? 'translate(-7px, 1px) rotate(0.5turn)' : 'translate(7px, 1px)'};
	transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
`

interface IWrapperProps {
	backwards?: boolean
}

const Wrapper = styled(Flex)<IWrapperProps>`
	flex-direction: ${(props) => (props.backwards ? 'row-reverse' : 'row')};
	&:hover ${Arrow} {
		transform: ${(props) =>
			props.backwards ? 'translate(-11px, 1px) rotate(0.5turn)' : 'translate(11px, 1px)'};
	}
`

interface IArrowLinkProps extends IWrapperProps, BoxProps {
	color?: string
	children?: any
}

const ArrowLink = React.forwardRef(
	({ color, backwards, children, href, ...rest }: IArrowLinkProps, _) => {
		return (
			<Wrapper
				alignItems="center"
				as="a"
				href={href}
				variant="reset"
				backwards={backwards}
				style={{ cursor: 'pointer' }}
				{...rest}
			>
				{children}
				<Arrow
					width="16"
					height="16"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 16 16"
					backwards={backwards}
				>
					<path
						d="M8 0L6.6 1.4 12.2 7H0v2h12.2l-5.6 5.6L8 16l8-8-8-8z"
						fillRule="evenodd"
						clipRule="evenodd"
						fill={color || '#1d1d1d'}
					/>
				</Arrow>
			</Wrapper>
		)
	}
)

export default ArrowLink
