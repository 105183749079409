import React from 'react'
import { Chip } from '@mui/material'

export const ChipPreOrder = () => {
	return <Chip color={'warning'} label={'Forhåndsbestilling'} />
}

export const ChipSoldOut = () => {
	return <Chip color={'error'} label={'Utsolgt'} />
}
