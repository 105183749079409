import React from 'react'
import { Flex } from 'rebass/styled-components'
import { CircularProgress } from '@mui/material'
import theme from '../../styles/theme'

export const Loading = () => {
	return (
		<Flex alignItems="center" justifyContent="center" mt={25} mb={25}>
			<CircularProgress variant={'indeterminate'} style={{ color: theme.colors.deepGreen100 }} />
		</Flex>
	)
}
