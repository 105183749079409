/*

After many hours of attempts and deployments, it seems like CORS features provided by `micro-cors` do not work correctly for our `microrouter` solution with GraphQL.

I tried to:
- add `vercel.json` to set CORS policies 
- add OPTION method check for preflight requests
- try different ways to set and call CORS as middleware maintaining the same libraries.

None of these attempts worked.
The unique way to fix the problem is to set the correct app URL for each environment / deployment.

Therefore I created the following environment variables on Vercel:
- **Production**: matfra.no
- **Preview**: staging.vercel.app
- **Development": ${VERCEL_URL}

Development environment is still to test its working.
Preview works correctly using the stating domain, but it does not work using its VERCEL_URL since we force the preview deployment to use `staging.vercel.app` domain. If CORS doesn't work, this is the "only" drawback that we get.

So, to review the current situation:
- **Production**: ✅
- **Preview** with branch domain: ✅
- **Preview** with vercel URL: ❌
- **Development** (it uses vercel URL): ⌛️ to check, but it should work

If we are able to fix the CORS issue, we can set again `getBackendUrl` and `getFrontendUrl` to `NEXT_PUBLIC_VERCEL_URL` instead of `NEXT_PUBLIC_APP_URL`.

*/

export const backendUrl = getBackendUrl()
export const frontendUrl = getFrontendUrl()
export const apolloUrl = `${backendUrl}/api`

export function getBackendUrl() {
	return process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
		? 'http://localhost:8000'
		: 'https://' + process.env.NEXT_PUBLIC_APP_URL
}

export function getFrontendUrl() {
	return process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
		? 'http://localhost:3000'
		: 'https://' + process.env.NEXT_PUBLIC_APP_URL
}
