import React, { useContext, useEffect, useMemo } from 'react'
import { Flex, Box } from 'rebass/styled-components'
import { Text6 } from '../Typography'
import Stepper from '../Form/Stepper'
import Price from '../Price'
import { CLOUDINARY_BASE_URL } from '../../constants/constants'
import { AppContext, getCartLineItem } from '../../context/store/context'
import { useReadCartProductQuery } from '../../apis/generated/graphql'
import { ChipPreOrder } from '../Chip'

interface ILineItemProps {
	merchantId: string
	productId: string
	lastItem?: boolean
}

const LineItem = ({ merchantId, productId, lastItem }: ILineItemProps) => {
	const {
		state: { cart },
		dispatch,
	} = useContext(AppContext)

	const { data, loading, error } = useReadCartProductQuery({
		variables: {
			productId,
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (error) {
			// TODO: We should check if the product is no longer available and allow the voluntary removing of the item from the cart
			// This dispatch works as intended by removing the item from the cart in case of error.
			// However, we should make a deeper analysis about this behavior.
			removeItemFromCart()
		}
	}, [error])

	const quantity = useMemo(() => {
		const lineItem = getCartLineItem(cart, merchantId, productId)
		return lineItem ? lineItem.quantity : 0
	}, [cart])

	const setQuantity = (qty: number) => {
		dispatch({
			type: 'UPDATE_CART',
			payload: {
				merchantId,
				productId,
				quantity: qty,
				price: data.product.price,
			},
		})
	}

	const removeItemFromCart = () => {
		dispatch({
			type: 'REMOVE_PRODUCT_FROM_CART',
			payload: {
				merchantId,
				productId,
			},
		})
	}

	if (loading) {
		return null
	}

	if (error) {
		return <p>{error.message}</p>
	}

	const { product } = data
	const borderOrNot = lastItem ? {} : { borderBottom: '1px solid #e6e6e6', paddingBottom: '16px' }

	return (
		<Flex mx={[3, 3, 3, 3]} mb={3} alignItems="center" style={borderOrNot}>
			<img
				style={{ width: '64px', height: '64px' }}
				src={`${CLOUDINARY_BASE_URL}/c_scale,dpr_2.0,h_100,w_100/${product.imgId}.jpg`}
				alt={product.title}
			/>
			<Flex width={1} alignItems="center" justifyContent="space-between">
				<Box pl={3}>
					<Text6 fontWeight="medium">{product.title}</Text6>
					<Text6 fontWeight="medium">
						<Price value={product.price} />
					</Text6>
					{product.isPreOrderable && <ChipPreOrder />}
				</Box>
				<Box ml={'auto'}>
					<Stepper setAmount={setQuantity} amount={quantity} />
				</Box>
			</Flex>
		</Flex>
	)
}

export default LineItem
