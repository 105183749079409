import { GdprPreferences, Location, ProductView, StoreData } from './context'

// TODO: We have to create types/Discriminated unions for actions to use for reducer/dispatch
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html

export type Action =
	| {
			type: 'UPDATE_GDPR'
			payload: GdprPreferences
	  }
	| {
			type: 'UPDATE_ORDER'
			payload: {
				shippingOptions: {
					merchantId: string
					shippingOptionId: string
					comments?: string
				}[]
				giftCard?: string
			}
	  }
	| {
			type: 'UPDATE_ORDER_ID'
			payload: {
				id: string
			}
	  }
	| {
			type: 'TOGGLE_SIDEBAR'
			payload: boolean
	  }
	| {
			type: 'TOGGLE_PRODUCTVIEW'
			payload: ProductView
	  }
	| {
			type: 'TOGGLE_MOBILEMENU'
			payload: boolean
	  }
	| {
			type: 'UPDATE_LOCATION'
			payload: Location
	  }
	| {
			type: 'REMOVE_LOCATION'
	  }
	| {
			type: 'EMPTY_CART'
	  }
	| {
			type: 'ADD_TO_CART'
			payload: {
				merchantId: string
				productId: string
				price: number
			}
	  }
	| {
			type: 'UPDATE_CART'
			payload: {
				merchantId: string
				productId: string
				price: number
				quantity: number
			}
	  }
	| {
			type: 'REMOVE_PRODUCT_FROM_CART'
			payload: {
				merchantId: string
				productId: string
			}
	  }

export default function reducer(state: StoreData, action: Action): StoreData {
	switch (action.type) {
		case 'UPDATE_GDPR': {
			console.debug('UPDATE_GDPR', action.payload)
			const gdpr = action.payload as GdprPreferences
			return {
				...state,
				gdpr,
			}
		}
		case 'UPDATE_ORDER': {
			console.debug('UPDATE_ORDER', action.payload)
			const { shippingOptions, giftCard } = action.payload

			// Merchant ID => Shipping Option ID
			const shippingOptionsMap: Record<string, string> = {}

			// Merchant ID => Comments
			const commentsMap: Record<string, string> = {}

			shippingOptions?.forEach(({ merchantId, shippingOptionId, comments }) => {
				shippingOptionsMap[merchantId] = shippingOptionId
				commentsMap[merchantId] = comments
			})

			return {
				...state,
				order: {
					shippingOptions: shippingOptionsMap,
					comments: commentsMap,
					giftCard,
				},
			}
		}
		case 'UPDATE_ORDER_ID': {
			console.debug('UPDATE_ORDER_ID', action.payload)
			const { id } = action.payload
			const order = Object.assign(state.order, {
				id,
			})
			return {
				...state,
				order,
			}
		}
		case 'TOGGLE_SIDEBAR':
			if (state.activeSidebar === action.payload) {
				return state
			}
			return {
				...state,
				activeSidebar: action.payload,
			}

		case 'TOGGLE_PRODUCTVIEW':
			if (state.productView === action.payload) {
				return state
			}
			return {
				...state,
				productView: action.payload,
			}

		case 'TOGGLE_MOBILEMENU':
			if (state.activeMobileMenu === action.payload) {
				return state
			}
			return {
				...state,
				activeMobileMenu: action.payload,
			}

		case 'UPDATE_LOCATION':
			// Return current state if empty
			if (!action.payload) {
				return state
			}
			return {
				...state,
				location: action.payload,
			}

		case 'REMOVE_LOCATION':
			return {
				...state,
				location: undefined,
			}

		case 'EMPTY_CART':
			return {
				...state,
				cart: {
					countUniqueProducts: 0,
					countUniqueMerchants: 0,
					countProducts: 0,
					lines: {},
				},
			}

		case 'ADD_TO_CART': {
			console.debug('ADD_TO_CART', action.payload)

			const { merchantId, productId, price } = action.payload
			const cart = Object.assign({}, state.cart)

			if (!cart.lines[merchantId]) {
				cart.countUniqueMerchants++
				cart.lines[merchantId] = {}
			}

			if (!cart.lines[merchantId][productId]) {
				cart.countUniqueProducts++
				cart.lines[merchantId][productId] = {
					quantity: 1,
					price,
				}
			} else {
				// Added again via "Legg i handlekurv" and not via Stepper component
				cart.lines[merchantId][productId].quantity++
			}

			cart.countProducts++

			return {
				...state,
				cart,
			}
		}

		case 'UPDATE_CART': {
			console.debug('UPDATE_CART', action.payload)

			const { merchantId, productId, quantity, price } = action.payload
			const cart = Object.assign({}, state.cart)

			const currentProduct = cart.lines[merchantId][productId]
			if (currentProduct.quantity < quantity) {
				cart.countProducts++
			} else {
				cart.countProducts--
			}

			if (quantity === 0) {
				// Decrease count of unique products
				cart.countUniqueProducts--

				// If this was the last product for this merchant
				const countMerchantProducts = Object.keys(cart.lines[merchantId]).length
				if (countMerchantProducts === 1) {
					cart.countUniqueMerchants--
					delete cart.lines[merchantId]
				} else {
					delete cart.lines[merchantId][productId]
				}
			} else {
				cart.lines[merchantId][productId] = {
					quantity,
					price,
				}
			}

			return {
				...state,
				cart,
			}
		}

		case 'REMOVE_PRODUCT_FROM_CART': {
			console.debug('REMOVE_PRODUCT_FROM_CART', action.payload)

			const { merchantId, productId } = action.payload
			const cart = Object.assign({}, state.cart)

			const currentProduct = cart.lines[merchantId][productId]

			// Decrease count of products
			cart.countProducts -= currentProduct.quantity

			// Decrease count of unique products
			cart.countUniqueProducts--

			// If this was the last product for this merchant
			const countMerchantProducts = Object.keys(cart.lines[merchantId]).length
			if (countMerchantProducts === 1) {
				cart.countUniqueMerchants--
				delete cart.lines[merchantId]
			} else {
				delete cart.lines[merchantId][productId]
			}

			return {
				...state,
				cart,
			}
		}

		default:
			return state
	}
}
