import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from 'rebass/styled-components'
import MobileMenu from './MobileMenu'
import { AppContext } from '../../context/store/context'

interface IHamburgerWrapper {
	active: string
}

const HamburgerWrapper = styled(Button)<IHamburgerWrapper>`
	position: sticky;
	top: 5px;
	width: 32px;
	height: 20px;
	cursor: pointer;
	z-index: 99;

	> .first {
		top: ${(props) => (props.active === 'closed' ? 0 : '9px')};
		transform: ${(props) => (props.active === 'closed' ? 'rotate(0deg)' : 'rotate(45deg)')};
	}

	> .second {
		opacity: ${(props) => (props.active === 'closed' ? 1 : 0)};
		top: 9px;
	}

	> .last {
		bottom: ${(props) => (props.active === 'closed' ? 0 : '9px')};
		transform: ${(props) => (props.active === 'closed' ? 'rotate(0deg)' : 'rotate(-45deg)')};
	}
`

// TODO: Has problems with Typescript
const HamburgerBar = styled.svg`
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	transition:
		transform 0.5s cubic-bezier(0.23, 1, 0.32, 1),
		opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
	transform-origin: 50% 50%;
	background-color: transparent;
`

const HamburgerPath = styled.path`
	transition: stroke 0.5s cubic-bezier(0.23, 1, 0.32, 1);
`

const Hamburger = (props) => {
	const {
		state: { activeMobileMenu },
		dispatch,
	} = useContext(AppContext)

	const setActiveMobileMenu = (val) => {
		dispatch({ type: 'TOGGLE_MOBILEMENU', payload: val })
	}
	const { isFrontpage } = props

	// TODO: Improve this concept of "active" / "isOpen"
	const isOpen = activeMobileMenu ? 'open' : 'closed'

	return (
		<div>
			<HamburgerWrapper
				active={isOpen}
				onClick={() => setActiveMobileMenu(!activeMobileMenu)}
				variant="reset"
			>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<HamburgerBar
					className="first no-flicker"
					active={isOpen}
					as="svg"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 32 1.5"
				>
					<HamburgerPath
						d="M0 .8h32"
						fill="none"
						strokeWidth="1.5"
						stroke={activeMobileMenu || isFrontpage ? '#fff' : '#000'}
					/>
				</HamburgerBar>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<HamburgerBar
					className="second no-flicker"
					active={isOpen}
					as="svg"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 32 1.5"
				>
					<HamburgerPath
						d="M0 .8h32"
						fill="none"
						strokeWidth="1.5"
						stroke={activeMobileMenu || isFrontpage ? '#fff' : '#000'}
					/>
				</HamburgerBar>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<HamburgerBar
					className="last no-flicker"
					active={isOpen}
					as="svg"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 32 1.5"
				>
					<HamburgerPath
						d="M0 .8h32"
						fill="none"
						strokeWidth="1.5"
						stroke={activeMobileMenu || isFrontpage ? '#fff' : '#000'}
					/>
				</HamburgerBar>
			</HamburgerWrapper>
			<MobileMenu active={activeMobileMenu} setActiveMobileMenu={setActiveMobileMenu} />
		</div>
	)
}

export default Hamburger
