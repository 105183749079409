import { ShippingOption, ShippingOptionTypeEnum } from '../apis/generated/graphql'

export function getShippingOptionTitle(o: ShippingOption) {
	switch (o.type) {
		case ShippingOptionTypeEnum.PickupPoint:
			return `Henteskap i ${o.title}`
		case ShippingOptionTypeEnum.Custom:
			return o.title
		default:
			return o.title
	}
}
