import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Text5, Text7, Text8 } from '../Typography'
import LineItem from './LineItem'
import { Label } from '@rebass/forms/styled-components'
import {
	ShippingOption,
	ShippingOptionTypeEnum,
	useReadCartMerchantQuery,
} from '../../apis/generated/graphql'
import { ErrorMessage, Field } from 'formik'
import Price from '../Price'
import { ShippingOptionLocationMap } from '../Map/ShippingOptionLocationMap'
import { getIconByTemperature } from '../Icon/TemperatureIcon'
import { getShippingOptionTitle } from '../../utils/shippingOption'

interface IProps {
	merchantId: string
	products: Array<string>
	values: any
}

export const MerchantLineWithShippingOption = ({ merchantId, products, values }: IProps) => {
	const { data, loading, error } = useReadCartMerchantQuery({
		variables: {
			merchantId,
		},
	})

	if (loading) {
		return null
	}

	if (error) {
		return <p>{error.message}</p>
	}

	function getOption(o: ShippingOption) {
		let details

		if (o.type === ShippingOptionTypeEnum.PickupPoint) {
			details = o.supportedTemperatures.map((temperature, i) => {
				const IconTemperature = getIconByTemperature(temperature)
				return <IconTemperature key={i} />
			})
		}

		return (
			<>
				{getShippingOptionTitle(o)} – <Price value={o.cost} />
				{details}
			</>
		)
	}

	return (
		<Box key={`group-${merchantId}`} mb={5}>
			<Box
				sx={{
					border: '1px solid #E6EDED',
					borderRadius: '5px',
				}}
			>
				<Flex
					justifyContent="space-between"
					alignItems="center"
					bg="deepGreen10"
					px={3}
					py={2}
					mb={3}
				>
					<Text5 fontWeight="medium">{data.merchant.title}</Text5>
					{data.merchant.distanceFromUser && (
						<Text7 fontWeight="semibold" textAlign="center">
							<span>{data.merchant.distanceFromUser.duration.text} </span>
							<span style={{ fontWeight: 200 }}>kjøretid</span>
						</Text7>
					)}
				</Flex>
				{products.map((productId) => (
					<LineItem key={`lineitem-${productId}`} merchantId={merchantId} productId={productId} />
				))}
			</Box>
			<Box
				p={[3, 3, 3, 3]}
				my={4}
				sx={{
					border: '1px solid #E6EDED',
					borderRadius: '5px',
				}}
			>
				<Label
					as={Flex}
					justifyContent="space-between"
					style={{ borderBottom: '1px solid #e6e6e6' }}
					mb={2}
					pb={3}
					htmlFor="shippingOptions"
				>
					<Text7 fontWeight="semibold">Leveringsalternativ</Text7>
					<Box fontSize="24px">{values.shippingOptions[merchantId] && <span>&#10003;</span>}</Box>
				</Label>
				<Text8 color="red">
					{!values.shippingOptions[merchantId] && 'Du må velge et alternativ'}
				</Text8>
				{data.merchant.shippingOptions &&
					data.merchant.shippingOptions.map((option) => {
						return (
							<Flex
								flexWrap="wrap"
								style={{ borderBottom: '1px solid #e6e6e6' }}
								key={option.id}
								py={[3, 3, 3, 3]}
							>
								<Label
									width={[10 / 12, 10 / 12, 10 / 12, 10 / 12]}
									as={Label}
									mb={1}
									htmlFor={`${option.id}.${merchantId}`}
									flexWrap="wrap"
									alignItems={'center'}
								>
									<Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
										<Field
											component="input"
											type="radio"
											css={{
												margin: '4px 8px',
											}}
											name={`shippingOptions.${merchantId}`}
											id={`${option.id}.${merchantId}`}
											value={JSON.stringify(option)}
										/>
										{getOption(option as ShippingOption)}
									</Box>

									<Text8 width={1} opacity={0.8}>
										{option.description}
									</Text8>
									{option.location?.address && (
										<Text7 width={1} opacity={0.8} mt={2} mb={2}>
											Adresse: {option.location?.address}
										</Text7>
									)}
								</Label>
								{option.location?.latitude && option.location?.longitude && (
									<ShippingOptionLocationMap
										latitude={option.location.latitude}
										longitude={option.location.longitude}
									/>
								)}
							</Flex>
						)
					})}
				<Box>
					<Label my={3} htmlFor={`comments.${merchantId}`}>
						Skriv en kommentar
					</Label>
					<Field
						css={{
							fontSize: '12px',
						}}
						rows="3"
						component="textarea"
						name={`comments.${merchantId}`}
						id={`comments.${merchantId}`}
						placeholder="Her kan du legge igjen en kommentar. F.eks kan du skrive når du planlegger å hente varene."
					/>
					<ErrorMessage name={`comments.${merchantId}`} />
				</Box>
			</Box>
		</Box>
	)
}
