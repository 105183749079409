import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	DateTime: any
	Upload: any
}

export type AcceptableVerificationDocument = {
	__typename?: 'AcceptableVerificationDocument'
	name: Scalars['String']
	needsBackDocument: Scalars['Boolean']
	needsFrontDocument: Scalars['Boolean']
}

export type AcceptableVerificationDocumentResponse = {
	__typename?: 'AcceptableVerificationDocumentResponse'
	company: Array<AcceptableVerificationDocument>
	individual: Array<AcceptableVerificationDocument>
}

export type AdminCategoryInput = {
	id?: InputMaybe<Scalars['ID']>
	title: Scalars['String']
}

export type AdminDeleteProductInput = {
	merchant: Scalars['ID']
	product: Scalars['ID']
}

export type AdminDeleteUserInput = {
	users?: InputMaybe<Array<Scalars['ID']>>
}

export type AdminProductInput = {
	allergens: Array<ProductAllergensEnum>
	allergensStatus: ProductAllergensStatusEnum
	category: Scalars['ID']
	certificates: Array<ProductCertificatesEnum>
	cost: Scalars['Float']
	deliveryCondition?: InputMaybe<Scalars['String']>
	description: Scalars['String']
	diets: Array<ProductDietsEnum>
	discountPct?: InputMaybe<Scalars['Int']>
	enabled: Scalars['Boolean']
	id?: InputMaybe<Scalars['ID']>
	imgId?: InputMaybe<Scalars['String']>
	isPreOrderable?: InputMaybe<Scalars['Boolean']>
	merchant: Scalars['ID']
	packaging: Scalars['String']
	productionYear: Scalars['String']
	reservationCost?: InputMaybe<Scalars['Int']>
	reservationDescription?: InputMaybe<Scalars['String']>
	reservationTitle?: InputMaybe<Scalars['String']>
	stock: Scalars['Int']
	storage?: InputMaybe<Scalars['String']>
	temperatureMode: TemperatureModeEnum
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
	vatPct: Scalars['Int']
}

export type BrregInfo = {
	__typename?: 'BrregInfo'
	company: BrregInfoCompany
	roles: Array<BrregInfoRole>
}

export type BrregInfoCompany = {
	__typename?: 'BrregInfoCompany'
	address: BrregInfoCompanyAddress
	name: Scalars['String']
	orgNumber: Scalars['String']
}

export type BrregInfoCompanyAddress = {
	__typename?: 'BrregInfoCompanyAddress'
	city: Scalars['String']
	postalCode: Scalars['String']
	street: Scalars['String']
}

export type BrregInfoRole = {
	__typename?: 'BrregInfoRole'
	birthdate: Scalars['String']
	firstname: Scalars['String']
	lastname: Scalars['String']
	roleDescription: Scalars['String']
	roleType: Scalars['String']
}

export type CallbackUrlInput = {
	cancelUrl: Scalars['String']
	successUrl: Scalars['String']
}

export type CartControl = {
	__typename?: 'CartControl'
	isPassed: Scalars['Boolean']
	merchantId: Scalars['ID']
	problems?: Maybe<Array<Scalars['String']>>
	shippingOption: Scalars['ID']
}

export type CartControlInput = {
	merchantId: Scalars['ID']
	productsIds: Array<Scalars['ID']>
	shippingOption: Scalars['ID']
}

export type CartMerchant = {
	__typename?: 'CartMerchant'
	distanceFromUser?: Maybe<DistanceFromUser>
	id: Scalars['ID']
	shippingOptions: Array<ShippingOption>
	title: Scalars['String']
}

export type CartProduct = {
	__typename?: 'CartProduct'
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable: Scalars['Boolean']
	price: Scalars['Float']
	title: Scalars['String']
}

export type Category = {
	__typename?: 'Category'
	id: Scalars['ID']
	slug: Scalars['String']
	title: Scalars['String']
}

export type ChargeCustomerAddress = {
	__typename?: 'ChargeCustomerAddress'
	addressLine1: Scalars['String']
	addressLine2?: Maybe<Scalars['String']>
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	country: Scalars['String']
	firstName: Scalars['String']
	lastName: Scalars['String']
}

export type ChargeCustomerOverview = {
	__typename?: 'ChargeCustomerOverview'
	address: ChargeCustomerAddress
	email: Scalars['String']
	firstName: Scalars['String']
	id: Scalars['ID']
	lastName: Scalars['String']
	phoneNumber: Scalars['String']
}

export type ChargeCustomerPreview = {
	__typename?: 'ChargeCustomerPreview'
	firstName: Scalars['String']
	id: Scalars['ID']
	lastName: Scalars['String']
}

export type ChargeFilterInput = {
	merchantStatus?: InputMaybe<ChargeMerchantStatus>
	page?: InputMaybe<Scalars['Int']>
	rowsPerPage?: InputMaybe<Scalars['Int']>
	sort?: InputMaybe<SortInput>
	statuses: Array<OrderStatuses>
}

export type ChargeLineItemOverview = {
	__typename?: 'ChargeLineItemOverview'
	product: ChargeProductOverview
	quantity: Scalars['Int']
}

export type ChargeLinePreview = {
	__typename?: 'ChargeLinePreview'
	product: ChargeProductPreview
	quantity: Scalars['Int']
}

export type ChargeMerchantOverview = {
	__typename?: 'ChargeMerchantOverview'
	id: Scalars['ID']
}

export enum ChargeMerchantStatus {
	Archived = 'Archived',
	NotProvided = 'NotProvided',
}

export type ChargeOverview = {
	__typename?: 'ChargeOverview'
	archiveNumber?: Maybe<Scalars['Int']>
	availableFromDate?: Maybe<Scalars['DateTime']>
	baseOrder?: Maybe<Scalars['ID']>
	comments?: Maybe<Scalars['String']>
	completedAt?: Maybe<Scalars['DateTime']>
	createdAt: Scalars['DateTime']
	customer: ChargeCustomerOverview
	id: Scalars['ID']
	isPreOrder: Scalars['Boolean']
	items: Array<ChargeLineItemOverview>
	merchant: ChargeMerchantOverview
	merchantStatus: ChargeMerchantStatus
	paymentDueDate?: Maybe<Scalars['DateTime']>
	shippingOption?: Maybe<ChargeShippingOptionOverview>
	status: OrderStatuses
	totalCost: Scalars['Float']
	totalVat: Scalars['Float']
	updatedAt: Scalars['DateTime']
}

export type ChargePreview = {
	__typename?: 'ChargePreview'
	archiveNumber?: Maybe<Scalars['Int']>
	comments?: Maybe<Scalars['String']>
	completedAt?: Maybe<Scalars['DateTime']>
	createdAt: Scalars['DateTime']
	customer: ChargeCustomerPreview
	id: Scalars['ID']
	isPreOrder: Scalars['Boolean']
	lines: Array<ChargeLinePreview>
	merchantStatus: ChargeMerchantStatus
	shippingOption?: Maybe<ChargeShippingOptionPreview>
	status: OrderStatuses
	totalCost: Scalars['Float']
	totalVat: Scalars['Float']
}

export type ChargeProductOverview = {
	__typename?: 'ChargeProductOverview'
	cost: Scalars['Float']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable?: Maybe<Scalars['Boolean']>
	reservationCost?: Maybe<Scalars['Int']>
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
	vatPct: Scalars['Int']
}

export type ChargeProductPreview = {
	__typename?: 'ChargeProductPreview'
	id: Scalars['ID']
	title: Scalars['String']
}

export type ChargeShippingOptionOverview = {
	__typename?: 'ChargeShippingOptionOverview'
	cost: Scalars['Float']
	id: Scalars['String']
	pickupInfo?: Maybe<ChargeShippingOptionPickupInfoPreview>
	title: Scalars['String']
	type: ShippingOptionTypeEnum
	vatPct?: Maybe<Scalars['Float']>
}

export type ChargeShippingOptionPickupInfoPreview = {
	__typename?: 'ChargeShippingOptionPickupInfoPreview'
	deliveredInOtherWays: Scalars['Boolean']
	deliveredInOtherWaysNotes?: Maybe<Scalars['String']>
	pin: Scalars['String']
	status: ChargeTrackStatus
	statusNotes?: Maybe<Scalars['String']>
}

export type ChargeShippingOptionPreview = {
	__typename?: 'ChargeShippingOptionPreview'
	id: Scalars['ID']
	pickupInfo?: Maybe<ChargeShippingOptionPickupInfoPreview>
	title: Scalars['String']
}

export enum ChargeTrackStatus {
	Collected = 'Collected',
	CollectingStarted = 'CollectingStarted',
	Expired = 'Expired',
	Loaded = 'Loaded',
	NotProvided = 'NotProvided',
	RemovalStarted = 'RemovalStarted',
	Removed = 'Removed',
}

export type CompleteOrderInput = {
	callbackUrls: CallbackUrlInput
	orderId: Scalars['ID']
}

export type Coordinates = {
	latitude: Scalars['Float']
	longitude: Scalars['Float']
}

export type CreateNotificationInput = {
	email: Scalars['String']
	product: Scalars['String']
}

export type CreateOrderInput = {
	addressId: Scalars['ID']
	callbackUrls: CallbackUrlInput
	giftCardId?: InputMaybe<Scalars['ID']>
	items: Array<ItemInput>
}

export type Distance = {
	__typename?: 'Distance'
	text: Scalars['String']
	value: Scalars['Int']
}

export type DistanceFromUser = {
	__typename?: 'DistanceFromUser'
	distance: Distance
	duration: Duration
}

export type Duration = {
	__typename?: 'Duration'
	text: Scalars['String']
	value: Scalars['Int']
}

export type GiftCard = {
	__typename?: 'GiftCard'
	amount: Scalars['Int']
	deliveryStatus: GiftCardDeliveryStatusEnum
	emailDeliveryDate?: Maybe<Scalars['DateTime']>
	id: Scalars['ID']
	message: Scalars['String']
	payment: GiftCardPayment
	receiver: GiftCardReceiver
	sender: GiftCardSender
	status: GiftCardStatusEnum
}

export type GiftCardCheck = {
	__typename?: 'GiftCardCheck'
	availableAmount: Scalars['Int']
	id: Scalars['ID']
}

export enum GiftCardDeliveryStatusEnum {
	NotSent = 'NotSent',
	Sent = 'Sent',
}

export type GiftCardInput = {
	amount: Scalars['Int']
	callbackUrls: CallbackUrlInput
	emailDeliveryDate?: InputMaybe<Scalars['DateTime']>
	message: Scalars['String']
	receiverEmail: Scalars['String']
	receiverName: Scalars['String']
}

export type GiftCardPayment = {
	__typename?: 'GiftCardPayment'
	sessionId: Scalars['String']
}

export enum GiftCardPaymentStatusEnum {
	Accepted = 'Accepted',
	Pending = 'Pending',
}

export type GiftCardReceiver = {
	__typename?: 'GiftCardReceiver'
	email: Scalars['String']
	id?: Maybe<Scalars['ID']>
	name: Scalars['String']
}

export type GiftCardSender = {
	__typename?: 'GiftCardSender'
	email: Scalars['String']
	id: Scalars['ID']
	name: Scalars['String']
}

export enum GiftCardStatusEnum {
	Applied = 'Applied',
	NotApplied = 'NotApplied',
	PartiallyApplied = 'PartiallyApplied',
}

export type GiftCardWithoutPaymentInput = {
	amount: Scalars['Int']
	emailDeliveryDate?: InputMaybe<Scalars['DateTime']>
	message: Scalars['String']
	receiverEmail: Scalars['String']
	receiverName: Scalars['String']
}

export type ItemInput = {
	comments?: InputMaybe<Scalars['String']>
	lineItems: Array<LineItemInput>
	merchantId: Scalars['ID']
	pickupOption?: InputMaybe<Scalars['String']>
	shippingOption: Scalars['ID']
}

export type LineItemInput = {
	productId: Scalars['ID']
	quantity: Scalars['Int']
}

export type Merchant = {
	__typename?: 'Merchant'
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	categories: Array<Category>
	city: Scalars['String']
	contactName: Scalars['String']
	contactPhoneNumber: Scalars['String']
	description: Scalars['String']
	distanceFromUser?: Maybe<DistanceFromUser>
	email: Scalars['String']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	organisationNumber: Scalars['String']
	pickUpDescription?: Maybe<Scalars['String']>
	shippingOptions: Array<Maybe<ShippingOption>>
	slug: Scalars['String']
	status: MerchantStatus
	title: Scalars['String']
	verificationStatus: MerchantVerificationStatus
	verificationStatusDescription?: Maybe<Scalars['String']>
}

export type MerchantAdmin = {
	__typename?: 'MerchantAdmin'
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	categories: Array<Category>
	city: Scalars['String']
	contactName: Scalars['String']
	contactPhoneNumber: Scalars['String']
	description: Scalars['String']
	email: Scalars['String']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	organisationNumber: Scalars['String']
	pickUpDescription?: Maybe<Scalars['String']>
	shippingOptions: Array<ShippingOption>
	slug: Scalars['String']
	status: MerchantStatus
	stripeAccountId?: Maybe<Scalars['String']>
	title: Scalars['String']
	verificationStatus: MerchantVerificationStatus
	verificationStatusDescription?: Maybe<Scalars['String']>
}

export type MerchantAdminConnection = {
	__typename?: 'MerchantAdminConnection'
	edges: Array<MerchantAdminEdge>
	pageInfo: PageInfo
}

export type MerchantAdminEdge = {
	__typename?: 'MerchantAdminEdge'
	cursor: Scalars['String']
	node: MerchantAdminPreview
}

export type MerchantAdminPreview = {
	__typename?: 'MerchantAdminPreview'
	contactName: Scalars['String']
	contactPhoneNumber: Scalars['String']
	email: Scalars['String']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	slug: Scalars['String']
	status: MerchantStatus
	title: Scalars['String']
}

export type MerchantInput = {
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	contactName: Scalars['String']
	contactPhoneNumber: Scalars['String']
	description?: InputMaybe<Scalars['String']>
	email: Scalars['String']
	id?: InputMaybe<Scalars['ID']>
	imgId?: InputMaybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	organisationNumber: Scalars['String']
	status: MerchantStatus
	stripeAccountId?: InputMaybe<Scalars['String']>
	title: Scalars['String']
}

export type MerchantMap = {
	__typename?: 'MerchantMap'
	distanceFromUser?: Maybe<DistanceFromUser>
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	slug: Scalars['String']
	title: Scalars['String']
}

export type MerchantPreOrderProductInput = {
	cost: Scalars['Int']
	id: Scalars['ID']
}

export type MerchantPreview = {
	__typename?: 'MerchantPreview'
	description: Scalars['String']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	slug: Scalars['String']
	title: Scalars['String']
}

export type MerchantProductPreview = {
	__typename?: 'MerchantProductPreview'
	certificates: Array<ProductCertificatesEnum>
	cost: Scalars['Float']
	diets: Array<ProductDietsEnum>
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable: Scalars['Boolean']
	reservationTitle?: Maybe<Scalars['String']>
	slug: Scalars['String']
	stock: Scalars['Int']
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
}

export type MerchantProducts = {
	__typename?: 'MerchantProducts'
	distanceFromUser?: Maybe<DistanceFromUser>
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	products: Array<MerchantProductPreview>
	shippingOptions: Array<ShippingOptionsPreview>
	slug: Scalars['String']
	title: Scalars['String']
}

export type MerchantProductsConnection = {
	__typename?: 'MerchantProductsConnection'
	edges: Array<MerchantProductsEdge>
	pageInfo: PageInfo
}

export type MerchantProductsEdge = {
	__typename?: 'MerchantProductsEdge'
	cursor: Scalars['String']
	node: MerchantProducts
}

export type MerchantProfile = {
	__typename?: 'MerchantProfile'
	accountNumber: Scalars['String']
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	categories: Array<Category>
	city: Scalars['String']
	contactName: Scalars['String']
	contactPhoneNumber: Scalars['String']
	description: Scalars['String']
	email: Scalars['String']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	organisationNumber: Scalars['String']
	pickUpDescription?: Maybe<Scalars['String']>
	shippingOptions: Array<ShippingOption>
	slug: Scalars['String']
	status: MerchantStatus
	stripeAccountId?: Maybe<Scalars['String']>
	title: Scalars['String']
	verificationStatus: MerchantVerificationStatus
	verificationStatusDescription?: Maybe<Scalars['String']>
}

export type MerchantProfileInput = {
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	contactName: Scalars['String']
	contactPhoneNumber: Scalars['String']
	description: Scalars['String']
	email: Scalars['String']
	imgId?: InputMaybe<Scalars['String']>
	title: Scalars['String']
}

export type MerchantReference = {
	__typename?: 'MerchantReference'
	id: Scalars['ID']
	slug: Scalars['String']
	title: Scalars['String']
}

export type MerchantRegistrationInput = {
	acceptNewsletter: Scalars['Boolean']
	accountNumber: Scalars['String']
	address: Scalars['String']
	city: Scalars['String']
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	name: Scalars['String']
	orgNumber: Scalars['String']
	postCode: Scalars['String']
}

export type MerchantSearchResult = {
	__typename?: 'MerchantSearchResult'
	id: Scalars['String']
	imgId?: Maybe<Scalars['String']>
	title: Scalars['String']
}

export enum MerchantStatus {
	Active = 'active',
	NotActive = 'not_active',
}

export type MerchantStripeAccount = {
	__typename?: 'MerchantStripeAccount'
	stripeAccountId: Scalars['String']
}

export type MerchantStripeAccountInput = {
	accountNumber: Scalars['String']
	birthdate: Scalars['String']
	holderName: Scalars['String']
	holderType: MerchantStripeAccountPaymentTypeEnum
	merchantId: Scalars['ID']
}

export enum MerchantStripeAccountPaymentTypeEnum {
	Company = 'company',
	Individual = 'individual',
}

export enum MerchantVerificationStatus {
	NotVerified = 'NotVerified',
	Pending = 'Pending',
	Verified = 'Verified',
}

export type MessageResponse = {
	__typename?: 'MessageResponse'
	id?: Maybe<Scalars['ID']>
	message?: Maybe<Scalars['String']>
}

export type Mutation = {
	__typename?: 'Mutation'
	_?: Maybe<Scalars['String']>
	acceptStripeAccountTerms: Scalars['Boolean']
	adminCreateOrUpdateCategory: Category
	completePreOrder: Order
	createNotification: NotificationResponse
	createOrUpdateMerchant: Merchant
	createOrUpdatePickupPoint: PickupPoint
	createOrUpdateProduct: Product
	createOrUpdateShippingOption: ShippingOption
	createOrder: Order
	createUserAddress: Array<UserAddress>
	deletePickupPoint: PickupPoint
	deleteProduct: MessageResponse
	deleteShippingOption: MessageResponse
	deleteUser: User
	deleteUserAddress: Array<UserAddress>
	deleteUsers?: Maybe<Array<User>>
	generateStripeAccountId: MerchantStripeAccount
	giftCard: GiftCard
	giftCardWithoutPayment: GiftCard
	registerMerchant: Merchant
	setFeaturedProduct: Array<ProductPreview>
	unsetFeaturedProduct: Array<ProductPreview>
	updateChargeMerchantStatus: ChargePreview
	updateChargeWithPickupPointAsDelivered: ChargePreview
	updateMerchantProductPosition: Array<Product>
	updateMerchantProfile: Merchant
	updatePreOrder: Order
	updateUser: User
	updateUserAddress: Array<UserAddress>
	updateUserAsAdmin: User
	uploadStripeDocuments: Merchant
}

export type MutationAcceptStripeAccountTermsArgs = {
	merchantId: Scalars['ID']
}

export type MutationAdminCreateOrUpdateCategoryArgs = {
	input: AdminCategoryInput
}

export type MutationCompletePreOrderArgs = {
	input: CompleteOrderInput
}

export type MutationCreateNotificationArgs = {
	input: CreateNotificationInput
}

export type MutationCreateOrUpdateMerchantArgs = {
	input: MerchantInput
}

export type MutationCreateOrUpdatePickupPointArgs = {
	input: PickupPointInput
}

export type MutationCreateOrUpdateProductArgs = {
	input: ProductInput
}

export type MutationCreateOrUpdateShippingOptionArgs = {
	input: ShippingOptionInput
}

export type MutationCreateOrderArgs = {
	input: CreateOrderInput
}

export type MutationCreateUserAddressArgs = {
	address: UserAddressCreateInput
}

export type MutationDeletePickupPointArgs = {
	id: Scalars['ID']
}

export type MutationDeleteProductArgs = {
	id: Scalars['ID']
	merchantId: Scalars['ID']
}

export type MutationDeleteShippingOptionArgs = {
	id: Scalars['ID']
}

export type MutationDeleteUserAddressArgs = {
	addressId: Scalars['ID']
}

export type MutationDeleteUsersArgs = {
	input: AdminDeleteUserInput
}

export type MutationGenerateStripeAccountIdArgs = {
	input: MerchantStripeAccountInput
}

export type MutationGiftCardArgs = {
	input: GiftCardInput
}

export type MutationGiftCardWithoutPaymentArgs = {
	input: GiftCardWithoutPaymentInput
}

export type MutationRegisterMerchantArgs = {
	input: MerchantRegistrationInput
}

export type MutationSetFeaturedProductArgs = {
	position?: InputMaybe<Scalars['Int']>
	productId: Scalars['ID']
}

export type MutationUnsetFeaturedProductArgs = {
	productId: Scalars['ID']
}

export type MutationUpdateChargeMerchantStatusArgs = {
	input: UpdateChargeMerchantStatusInput
}

export type MutationUpdateChargeWithPickupPointAsDeliveredArgs = {
	input: UpdateChargeWithPickupPointAsDeliveredInput
}

export type MutationUpdateMerchantProductPositionArgs = {
	id: Scalars['ID']
	merchantId?: InputMaybe<Scalars['ID']>
	position: Scalars['Int']
}

export type MutationUpdateMerchantProfileArgs = {
	input: MerchantProfileInput
}

export type MutationUpdatePreOrderArgs = {
	input: UpdatePreOrderInput
}

export type MutationUpdateUserArgs = {
	input: UserUpdateInput
}

export type MutationUpdateUserAddressArgs = {
	address: UserAddressUpdateInput
}

export type MutationUpdateUserAsAdminArgs = {
	input: UpdateUserAsAdminInput
}

export type MutationUploadStripeDocumentsArgs = {
	input: UploadStripeDocumentsInput
}

export type Notification = {
	__typename?: 'Notification'
	email: Scalars['String']
	id: Scalars['ID']
	product: Product
}

export type NotificationResponse = {
	__typename?: 'NotificationResponse'
	id: Scalars['ID']
}

export type Order = {
	__typename?: 'Order'
	amountDiscount: Scalars['Float']
	archiveNumber?: Maybe<Scalars['Int']>
	id: Scalars['ID']
	isPreOrder?: Maybe<Scalars['Boolean']>
	lines: Array<OrderLine>
	stripeSessionId?: Maybe<Scalars['String']>
	totalCost: Scalars['Float']
	totalVat: Scalars['Float']
}

export type OrderAdminConnection = {
	__typename?: 'OrderAdminConnection'
	edges: Array<OrderAdminEdge>
	pageInfo: PageInfo
}

export type OrderAdminEdge = {
	__typename?: 'OrderAdminEdge'
	cursor: Scalars['String']
	node: OrderPreview
}

export type OrderCustomerAddress = {
	__typename?: 'OrderCustomerAddress'
	addressLine1: Scalars['String']
	addressLine2?: Maybe<Scalars['String']>
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	country: Scalars['String']
	firstName: Scalars['String']
	lastName: Scalars['String']
}

export type OrderCustomerOverview = {
	__typename?: 'OrderCustomerOverview'
	address: OrderCustomerAddress
	email: Scalars['String']
	firstName: Scalars['String']
	id: Scalars['ID']
	lastName: Scalars['String']
	phoneNumber: Scalars['String']
}

export type OrderCustomerPreview = {
	__typename?: 'OrderCustomerPreview'
	firstName: Scalars['String']
	id: Scalars['ID']
	lastName: Scalars['String']
}

export type OrderLine = {
	__typename?: 'OrderLine'
	comments?: Maybe<Scalars['String']>
	items: Array<OrderLineItem>
	merchant: OrderLineMerchant
	shippingOption?: Maybe<ShippingOption>
}

export type OrderLineItem = {
	__typename?: 'OrderLineItem'
	price: Scalars['Float']
	product: OrderLineItemProduct
	quantity: Scalars['Int']
}

export type OrderLineItemOverview = {
	__typename?: 'OrderLineItemOverview'
	product: OrderProductOverview
	quantity: Scalars['Int']
}

export type OrderLineItemProduct = {
	__typename?: 'OrderLineItemProduct'
	cost: Scalars['Float']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable: Scalars['Boolean']
	reservationCost?: Maybe<Scalars['Int']>
	reservationTitle?: Maybe<Scalars['String']>
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
	vatPct: Scalars['Int']
}

export type OrderLineMerchant = {
	__typename?: 'OrderLineMerchant'
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	contactPhoneNumber: Scalars['String']
	distanceFromUser?: Maybe<DistanceFromUser>
	id: Scalars['ID']
	title: Scalars['String']
}

export type OrderLineOverview = {
	__typename?: 'OrderLineOverview'
	comments?: Maybe<Scalars['String']>
	items: Array<OrderLineItemOverview>
	merchant: OrderMerchantOverview
	shippingOption: OrderShippingOptionOverview
}

export type OrderMerchantOverview = {
	__typename?: 'OrderMerchantOverview'
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	contactName: Scalars['String']
	id: Scalars['ID']
	organisationNumber: Scalars['String']
	title: Scalars['String']
}

export type OrderMerchantPreview = {
	__typename?: 'OrderMerchantPreview'
	id: Scalars['ID']
	title: Scalars['String']
}

export type OrderOverview = {
	__typename?: 'OrderOverview'
	archiveNumber?: Maybe<Scalars['Int']>
	availableFromDate?: Maybe<Scalars['DateTime']>
	baseOrder?: Maybe<Scalars['ID']>
	completedAt?: Maybe<Scalars['DateTime']>
	createdAt: Scalars['DateTime']
	customer: OrderCustomerOverview
	id: Scalars['ID']
	isPreOrder: Scalars['Boolean']
	lines: Array<OrderLineOverview>
	paymentDueDate?: Maybe<Scalars['DateTime']>
	status: OrderStatuses
	totalCost: Scalars['Float']
	totalVat: Scalars['Float']
	updatedAt: Scalars['DateTime']
}

export type OrderPreview = {
	__typename?: 'OrderPreview'
	archiveNumber?: Maybe<Scalars['Int']>
	completedAt?: Maybe<Scalars['DateTime']>
	createdAt: Scalars['DateTime']
	customer: OrderCustomerPreview
	id: Scalars['ID']
	isPreOrder: Scalars['Boolean']
	merchants: Array<OrderMerchantPreview>
	products: Array<OrderProductPreview>
	status: OrderStatuses
	totalCost: Scalars['Float']
	totalVat: Scalars['Float']
}

export type OrderProductOverview = {
	__typename?: 'OrderProductOverview'
	cost: Scalars['Float']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable?: Maybe<Scalars['Boolean']>
	reservationCost?: Maybe<Scalars['Int']>
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
	vatPct: Scalars['Int']
}

export type OrderProductPreview = {
	__typename?: 'OrderProductPreview'
	id: Scalars['ID']
	title: Scalars['String']
}

export type OrderShippingOptionOverview = {
	__typename?: 'OrderShippingOptionOverview'
	cost: Scalars['Float']
	id: Scalars['String']
	title: Scalars['String']
	vatPct?: Maybe<Scalars['Float']>
}

export enum OrderStatuses {
	Cancelled = 'cancelled',
	Completed = 'completed',
	Failed = 'failed',
	Pending = 'pending',
	PendingCustomerPayment = 'pending_customer_payment',
	PendingMerchantInput = 'pending_merchant_input',
}

export type OrderUserConnection = {
	__typename?: 'OrderUserConnection'
	edges: Array<OrderUserEdge>
	pageInfo: PageInfo
}

export type OrderUserEdge = {
	__typename?: 'OrderUserEdge'
	cursor: Scalars['String']
	node: OrderUserPreview
}

export type OrderUserInput = {
	addressLine1?: InputMaybe<Scalars['String']>
	addressLine2?: InputMaybe<Scalars['String']>
	addressPostalCode?: InputMaybe<Scalars['String']>
	city?: InputMaybe<Scalars['String']>
	country?: InputMaybe<Scalars['String']>
	email: Scalars['String']
	firstName: Scalars['String']
	lastName: Scalars['String']
	locale?: InputMaybe<Scalars['String']>
	phoneNumber: Scalars['String']
	profileImage?: InputMaybe<Scalars['String']>
}

export type OrderUserItem = {
	__typename?: 'OrderUserItem'
	merchant: OrderUserMerchantPreview
	products: Array<OrderUserProductPreview>
	shippingOption?: Maybe<ChargeShippingOptionPreview>
}

export type OrderUserMerchantPreview = {
	__typename?: 'OrderUserMerchantPreview'
	id: Scalars['ID']
	title: Scalars['String']
}

export type OrderUserPreview = {
	__typename?: 'OrderUserPreview'
	archiveNumber?: Maybe<Scalars['Int']>
	completedAt?: Maybe<Scalars['DateTime']>
	createdAt: Scalars['DateTime']
	id: Scalars['ID']
	isPreOrder: Scalars['Boolean']
	items: Array<OrderUserItem>
	paymentDueDate?: Maybe<Scalars['DateTime']>
	status: OrderStatuses
	totalCost: Scalars['Float']
	totalPrePaidCost: Scalars['Float']
	totalVat: Scalars['Float']
}

export type OrderUserProductPreview = {
	__typename?: 'OrderUserProductPreview'
	id: Scalars['ID']
	img?: Maybe<Scalars['String']>
	quantity: Scalars['Int']
	title: Scalars['String']
}

export type PageInfo = {
	__typename?: 'PageInfo'
	endCursor?: Maybe<Scalars['String']>
	hasNextPage: Scalars['Boolean']
}

export type PickupPoint = {
	__typename?: 'PickupPoint'
	cost: Scalars['Float']
	currency?: Maybe<Scalars['String']>
	description?: Maybe<Scalars['String']>
	id: Scalars['ID']
	terminal: Terminal
	title: Scalars['String']
	vatPct: Scalars['Float']
}

export type PickupPointInput = {
	cost: Scalars['Float']
	currency?: InputMaybe<Scalars['String']>
	description?: InputMaybe<Scalars['String']>
	id?: InputMaybe<Scalars['ID']>
	terminalId: Scalars['String']
	title: Scalars['String']
	vatPct: Scalars['Float']
}

export type PickupPointStatus = {
	__typename?: 'PickupPointStatus'
	modules: Array<PickupPointStatusModule>
	pendingOrders: Array<PickupPointStatusOrder>
}

export type PickupPointStatusModule = {
	__typename?: 'PickupPointStatusModule'
	boxes: Array<TerminalBox>
	numberOfBoxesPerColumn: Scalars['Int']
	numberOfBoxesPerRow: Scalars['Int']
}

export type PickupPointStatusOrder = {
	__typename?: 'PickupPointStatusOrder'
	chargeId: Scalars['ID']
	orderId: Scalars['ID']
	pickupOrderId: Scalars['String']
}

export type PickupPointUnpaired = {
	__typename?: 'PickupPointUnpaired'
	address: Scalars['String']
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	terminalId: Scalars['ID']
}

export type PickupPointsListItem = {
	__typename?: 'PickupPointsListItem'
	cost: Scalars['Float']
	currency?: Maybe<Scalars['String']>
	description?: Maybe<Scalars['String']>
	id: Scalars['ID']
	slug: Scalars['String']
	terminal: Terminal
	title: Scalars['String']
	vatPct: Scalars['Float']
}

export type Product = {
	__typename?: 'Product'
	allergens: Array<ProductAllergensEnum>
	allergensStatus: ProductAllergensStatusEnum
	category: Category
	certificates: Array<ProductCertificatesEnum>
	cost: Scalars['Float']
	deliveryCondition?: Maybe<Scalars['String']>
	description: Scalars['String']
	diets: Array<ProductDietsEnum>
	discountPct?: Maybe<Scalars['Int']>
	enabled: Scalars['Boolean']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable?: Maybe<Scalars['Boolean']>
	merchant: ProductMerchant
	notification?: Maybe<Array<Maybe<Notification>>>
	packaging: Scalars['String']
	productionYear: Scalars['String']
	reservationCost?: Maybe<Scalars['Int']>
	reservationDescription?: Maybe<Scalars['String']>
	reservationTitle?: Maybe<Scalars['String']>
	slug: Scalars['String']
	stock: Scalars['Int']
	storage?: Maybe<Scalars['String']>
	temperatureMode: TemperatureModeEnum
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
	vatPct: Scalars['Int']
}

export type ProductMerchantArgs = {
	location?: InputMaybe<Coordinates>
}

export type ProductAdminMerchant = {
	__typename?: 'ProductAdminMerchant'
	id: Scalars['ID']
	slug: Scalars['String']
	status: MerchantStatus
	title: Scalars['String']
}

export type ProductAdminPreview = {
	__typename?: 'ProductAdminPreview'
	adminFeatured?: Maybe<ProductFeatured>
	cost: Scalars['Int']
	enabled: Scalars['Boolean']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	merchant: ProductAdminMerchant
	notification: Scalars['Int']
	slug: Scalars['String']
	title: Scalars['String']
}

export enum ProductAllergensEnum {
	Celery = 'Celery',
	Eggs = 'Eggs',
	Fish = 'Fish',
	Gluten = 'Gluten',
	Lupine = 'Lupine',
	Milk = 'Milk',
	Molluscs = 'Molluscs',
	Mustard = 'Mustard',
	Nuts = 'Nuts',
	Peanuts = 'Peanuts',
	SesameSeeds = 'SesameSeeds',
	Shellfish = 'Shellfish',
	Sulfites = 'Sulfites',
	SulfurDioxide = 'SulfurDioxide',
}

export enum ProductAllergensStatusEnum {
	NotProvided = 'NotProvided',
	With = 'With',
	Without = 'Without',
}

export enum ProductCertificatesEnum {
	Biodynamic = 'Biodynamic',
	EcologicalWithDebio = 'EcologicalWithDebio',
	EcologicalWithoutDebio = 'EcologicalWithoutDebio',
	Regenerative = 'Regenerative',
}

export enum ProductDietsEnum {
	Vegan = 'Vegan',
	Vegetarian = 'Vegetarian',
}

export type ProductFeatured = {
	__typename?: 'ProductFeatured'
	isFeatured: Scalars['Boolean']
	position: Scalars['Int']
}

export type ProductInput = {
	allergens: Array<ProductAllergensEnum>
	allergensStatus: ProductAllergensStatusEnum
	category: Scalars['ID']
	certificates: Array<ProductCertificatesEnum>
	cost: Scalars['Float']
	deliveryCondition?: InputMaybe<Scalars['String']>
	description: Scalars['String']
	diets: Array<ProductDietsEnum>
	discountPct?: InputMaybe<Scalars['Int']>
	enabled: Scalars['Boolean']
	id?: InputMaybe<Scalars['ID']>
	imgId?: InputMaybe<Scalars['String']>
	isPreOrderable?: InputMaybe<Scalars['Boolean']>
	merchant: Scalars['ID']
	packaging: Scalars['String']
	productionYear: Scalars['String']
	reservationCost?: InputMaybe<Scalars['Int']>
	reservationDescription?: InputMaybe<Scalars['String']>
	reservationTitle?: InputMaybe<Scalars['String']>
	stock: Scalars['Int']
	storage?: InputMaybe<Scalars['String']>
	temperatureMode: TemperatureModeEnum
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
	vatPct: Scalars['Int']
}

export type ProductMerchant = {
	__typename?: 'ProductMerchant'
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	description: Scalars['String']
	distanceFromUser?: Maybe<DistanceFromUser>
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	pickUpDescription?: Maybe<Scalars['String']>
	shippingOptions: Array<Maybe<ShippingOption>>
	slug: Scalars['String']
	title: Scalars['String']
}

export type ProductPreview = {
	__typename?: 'ProductPreview'
	cost: Scalars['Float']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable: Scalars['Boolean']
	merchant: MerchantReference
	slug: Scalars['String']
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
}

export type ProductsAdminConnection = {
	__typename?: 'ProductsAdminConnection'
	edges: Array<ProductsAdminEdge>
	pageInfo: PageInfo
}

export type ProductsAdminEdge = {
	__typename?: 'ProductsAdminEdge'
	cursor: Scalars['String']
	node: ProductAdminPreview
}

export type ProductsConnection = {
	__typename?: 'ProductsConnection'
	edges: Array<ProductsEdge>
	pageInfo: PageInfo
}

export type ProductsEdge = {
	__typename?: 'ProductsEdge'
	cursor: Scalars['String']
	node: MerchantProductPreview
}

export type QrCodeDelivery = {
	__typename?: 'QrCodeDelivery'
	address: Scalars['String']
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	orderId: Scalars['String']
	value: Scalars['String']
}

export type Query = {
	__typename?: 'Query'
	_?: Maybe<Scalars['String']>
	categories?: Maybe<Array<Category>>
	category: Category
	checkCart: Array<CartControl>
	giftCardCheck: GiftCardCheck
	giftCards: Array<GiftCard>
	giftCardsPurchased: Array<GiftCard>
	giftCardsReceived: Array<GiftCard>
	readAcceptableVerificationDocumentsByCountry: AcceptableVerificationDocumentResponse
	readCartMerchant: CartMerchant
	readCartProduct: CartProduct
	readChargeOverview: ChargeOverview
	readCharges: Array<ChargePreview>
	readCompanyInfoByOrgNumber: BrregInfo
	readMerchantById: Merchant
	readMerchantByIdAdmin: MerchantAdmin
	readMerchantBySlug: Merchant
	readMerchantProfile: MerchantProfile
	readMerchants: MerchantAdminConnection
	readMerchantsMap: Array<MerchantMap>
	readMerchantsPreview: Array<MerchantPreview>
	readMerchantsWithProducts: MerchantProductsConnection
	readOrderById: Order
	readOrderOverviewById: OrderOverview
	readOrdersAsAdmin: OrderAdminConnection
	readOrdersAsUser: OrderUserConnection
	readPickupPointById: PickupPoint
	readPickupPointBySlug: PickupPoint
	readPickupPointStatusById: PickupPointStatus
	readPickupPoints: Array<PickupPoint>
	readPickupPointsList: Array<PickupPointsListItem>
	readProductById: Product
	readProductByIdAsMerchant: Product
	readProductBySlug: Product
	readProductsAsAdmin: ProductsAdminConnection
	readProductsAsMerchant: Array<Product>
	readProductsByMerchantId: ProductsConnection
	readProductsPreview: Array<ProductPreview>
	readQrCodeForDelivery: QrCodeDelivery
	readSalesRapport: SalesRapport
	readSalesRapportDocument: SalesRapportDocument
	readSalesRapportStatisticsAsMerchant: SalesRapportStatisticsMerchant
	readShippingOptions: Array<ShippingOption>
	readUnpairedPickupPoints: Array<PickupPointUnpaired>
	readUser: User
	readUserAddressById: UserAddress
	readUserAddresses: Array<UserAddress>
	readUsers: Array<User>
	searchEntities: SearchResult
	searchFeaturableProducts: Array<ProductPreview>
	searchMerchants: Array<MerchantSearchResult>
}

export type QueryCategoryArgs = {
	id: Scalars['ID']
}

export type QueryCheckCartArgs = {
	input: Array<CartControlInput>
}

export type QueryGiftCardCheckArgs = {
	code: Scalars['String']
}

export type QueryReadAcceptableVerificationDocumentsByCountryArgs = {
	country: Scalars['String']
}

export type QueryReadCartMerchantArgs = {
	location?: InputMaybe<Coordinates>
	merchantId: Scalars['ID']
}

export type QueryReadCartProductArgs = {
	productId: Scalars['ID']
}

export type QueryReadChargeOverviewArgs = {
	id: Scalars['ID']
	merchantId?: InputMaybe<Scalars['ID']>
}

export type QueryReadChargesArgs = {
	filter: ChargeFilterInput
	merchantId?: InputMaybe<Scalars['ID']>
}

export type QueryReadCompanyInfoByOrgNumberArgs = {
	orgNumber: Scalars['String']
}

export type QueryReadMerchantByIdArgs = {
	id: Scalars['ID']
	location?: InputMaybe<Coordinates>
}

export type QueryReadMerchantByIdAdminArgs = {
	id: Scalars['ID']
}

export type QueryReadMerchantBySlugArgs = {
	location?: InputMaybe<Coordinates>
	slug: Scalars['String']
}

export type QueryReadMerchantsArgs = {
	after?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	searchTerm?: InputMaybe<Scalars['String']>
	status?: InputMaybe<MerchantStatus>
}

export type QueryReadMerchantsMapArgs = {
	categoryId?: InputMaybe<Scalars['ID']>
	location: Coordinates
	maxDistanceInKm: Scalars['Int']
}

export type QueryReadMerchantsPreviewArgs = {
	limit?: InputMaybe<Scalars['Int']>
}

export type QueryReadMerchantsWithProductsArgs = {
	after?: InputMaybe<Scalars['String']>
	categoryId?: InputMaybe<Scalars['ID']>
	filterByAllergens?: InputMaybe<Array<ProductAllergensEnum>>
	filterByCertificates?: InputMaybe<Array<ProductCertificatesEnum>>
	filterByDiets?: InputMaybe<Array<ProductDietsEnum>>
	first?: InputMaybe<Scalars['Int']>
	location?: InputMaybe<Coordinates>
	maxDistanceInKm?: InputMaybe<Scalars['Int']>
	maxProductsPerMerchant?: InputMaybe<Scalars['Int']>
	pickupId?: InputMaybe<Scalars['ID']>
	searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryReadOrderByIdArgs = {
	id: Scalars['ID']
}

export type QueryReadOrderOverviewByIdArgs = {
	id: Scalars['ID']
}

export type QueryReadOrdersAsAdminArgs = {
	after?: InputMaybe<Scalars['String']>
	archiveNumber?: InputMaybe<Scalars['Int']>
	first?: InputMaybe<Scalars['Int']>
	searchTerm?: InputMaybe<Scalars['String']>
	statuses?: InputMaybe<Array<InputMaybe<OrderStatuses>>>
}

export type QueryReadOrdersAsUserArgs = {
	after?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
}

export type QueryReadPickupPointByIdArgs = {
	id: Scalars['ID']
}

export type QueryReadPickupPointBySlugArgs = {
	slug: Scalars['String']
}

export type QueryReadPickupPointStatusByIdArgs = {
	id: Scalars['ID']
}

export type QueryReadProductByIdArgs = {
	id: Scalars['ID']
}

export type QueryReadProductByIdAsMerchantArgs = {
	id: Scalars['ID']
	merchantId?: InputMaybe<Scalars['ID']>
}

export type QueryReadProductBySlugArgs = {
	slugMerchant: Scalars['String']
	slugProduct: Scalars['String']
}

export type QueryReadProductsAsAdminArgs = {
	after?: InputMaybe<Scalars['String']>
	enabled?: InputMaybe<Scalars['Boolean']>
	first?: InputMaybe<Scalars['Int']>
	searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryReadProductsAsMerchantArgs = {
	merchantId?: InputMaybe<Scalars['ID']>
}

export type QueryReadProductsByMerchantIdArgs = {
	after?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	merchantId: Scalars['ID']
}

export type QueryReadProductsPreviewArgs = {
	limit?: InputMaybe<Scalars['Int']>
}

export type QueryReadQrCodeForDeliveryArgs = {
	id: Scalars['ID']
	merchantId?: InputMaybe<Scalars['ID']>
}

export type QueryReadSalesRapportArgs = {
	fromDate: Scalars['DateTime']
	merchantId?: InputMaybe<Scalars['ID']>
	toDate: Scalars['DateTime']
}

export type QueryReadSalesRapportDocumentArgs = {
	fromDate: Scalars['DateTime']
	merchantId?: InputMaybe<Scalars['ID']>
	toDate: Scalars['DateTime']
}

export type QueryReadSalesRapportStatisticsAsMerchantArgs = {
	fromDate: Scalars['DateTime']
	groupByTime?: InputMaybe<StatisticalTime>
	merchantId?: InputMaybe<Scalars['ID']>
	toDate: Scalars['DateTime']
}

export type QueryReadShippingOptionsArgs = {
	merchantId?: InputMaybe<Scalars['ID']>
}

export type QueryReadUserAddressByIdArgs = {
	addressId: Scalars['ID']
}

export type QueryReadUsersArgs = {
	filter: UserFilterInput
}

export type QuerySearchEntitiesArgs = {
	limit: Scalars['Int']
	location?: InputMaybe<Coordinates>
	maxDistanceInKm?: InputMaybe<Scalars['Int']>
	searchTerm: Scalars['String']
}

export type QuerySearchFeaturableProductsArgs = {
	howMany?: InputMaybe<Scalars['Int']>
	searchTerm?: InputMaybe<Scalars['String']>
}

export type QuerySearchMerchantsArgs = {
	howMany?: InputMaybe<Scalars['Int']>
	searchTerm?: InputMaybe<Scalars['String']>
}

export enum Role {
	Admin = 'admin',
	Merchant = 'merchant',
	User = 'user',
}

export type SalesRapport = {
	__typename?: 'SalesRapport'
	fee: Scalars['Float']
	feePercent: Scalars['Int']
	gross: Scalars['Float']
	net: Scalars['Float']
	period: Scalars['String']
	result: Scalars['Float']
	vat: Scalars['Float']
}

export type SalesRapportDocument = {
	__typename?: 'SalesRapportDocument'
	url: Scalars['String']
}

export type SalesRapportStatisticsMerchant = {
	__typename?: 'SalesRapportStatisticsMerchant'
	data: Array<SalesRapportStatisticsMerchantData>
	totalCount: Scalars['Int']
	totalFee: Scalars['Float']
	totalGross: Scalars['Float']
	totalResult: Scalars['Float']
	totalVat: Scalars['Float']
}

export type SalesRapportStatisticsMerchantData = {
	__typename?: 'SalesRapportStatisticsMerchantData'
	date: Scalars['DateTime']
	subCount: Scalars['Int']
	subFee: Scalars['Float']
	subGross: Scalars['Float']
	subResult: Scalars['Float']
	subVat: Scalars['Float']
}

export type SearchResult = {
	__typename?: 'SearchResult'
	merchants: Array<SearchResultMerchant>
	products: Array<SearchResultProduct>
}

export type SearchResultMerchant = {
	__typename?: 'SearchResultMerchant'
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	slug: Scalars['String']
	title: Scalars['String']
}

export type SearchResultProduct = {
	__typename?: 'SearchResultProduct'
	cost: Scalars['Float']
	id: Scalars['ID']
	imgId?: Maybe<Scalars['String']>
	isPreOrderable: Scalars['Boolean']
	slug: Scalars['String']
	title: Scalars['String']
	unitType: Scalars['String']
	unitsPerProduct: Scalars['Float']
}

export type ShippingOption = {
	__typename?: 'ShippingOption'
	cost: Scalars['Float']
	currency?: Maybe<Scalars['String']>
	dateRange?: Maybe<ShippingOptionDateRange>
	description?: Maybe<Scalars['String']>
	id: Scalars['ID']
	location?: Maybe<ShippingOptionLocation>
	merchant: Scalars['ID']
	ref?: Maybe<Scalars['ID']>
	supportedTemperatures?: Maybe<Array<TemperatureModeEnum>>
	title: Scalars['String']
	type: ShippingOptionTypeEnum
	vatPct?: Maybe<Scalars['Float']>
}

export type ShippingOptionDateRange = {
	__typename?: 'ShippingOptionDateRange'
	fromDate: Scalars['DateTime']
	toDate: Scalars['DateTime']
}

export type ShippingOptionDateRangeInput = {
	fromDate: Scalars['DateTime']
	toDate: Scalars['DateTime']
}

export type ShippingOptionInput = {
	cost: Scalars['Float']
	currency?: InputMaybe<Scalars['String']>
	dateRange?: InputMaybe<ShippingOptionDateRangeInput>
	description?: InputMaybe<Scalars['String']>
	id?: InputMaybe<Scalars['ID']>
	merchant: Scalars['ID']
	ref?: InputMaybe<Scalars['ID']>
	title: Scalars['String']
	type: ShippingOptionTypeEnum
	vatPct?: InputMaybe<Scalars['Float']>
}

export type ShippingOptionLocation = {
	__typename?: 'ShippingOptionLocation'
	address: Scalars['String']
	latitude: Scalars['Float']
	longitude: Scalars['Float']
}

export enum ShippingOptionTypeEnum {
	Custom = 'Custom',
	PickupPoint = 'PickupPoint',
}

export type ShippingOptionsPreview = {
	__typename?: 'ShippingOptionsPreview'
	id: Scalars['String']
	title: Scalars['String']
}

export type SortInput = {
	order: SortOrders
	property: Scalars['String']
}

export enum SortOrders {
	Asc = 'asc',
	Desc = 'desc',
}

export enum StatisticalTime {
	Daily = 'daily',
	Monthly = 'monthly',
	Yearly = 'yearly',
}

export type Subscription = {
	__typename?: 'Subscription'
	_?: Maybe<Scalars['String']>
}

export enum TemperatureModeEnum {
	Ambient = 'Ambient',
	Chilled = 'Chilled',
	Frozen = 'Frozen',
	NotProvided = 'NotProvided',
}

export type Terminal = {
	__typename?: 'Terminal'
	address: Scalars['String']
	boxes: Array<TerminalBox>
	hardReservation: Scalars['Boolean']
	id: Scalars['ID']
	isEnabled: Scalars['Boolean']
	latitude: Scalars['Float']
	longitude: Scalars['Float']
	useReservedBoxes: Scalars['Boolean']
}

export type TerminalBox = {
	__typename?: 'TerminalBox'
	boxIndex: Scalars['Int']
	boxLabel: Scalars['String']
	depth: Scalars['Float']
	height: Scalars['Float']
	isDeleted: Scalars['Boolean']
	isDisabled: Scalars['Boolean']
	moduleNumber: Scalars['Int']
	order?: Maybe<TerminalBoxOrder>
	size: Scalars['String']
	temperatureMode: TemperatureModeEnum
	width: Scalars['Float']
	x: Scalars['Float']
	y: Scalars['Float']
}

export type TerminalBoxOrder = {
	__typename?: 'TerminalBoxOrder'
	orderId: Scalars['String']
	status: ChargeTrackStatus
}

export type UpdateChargeMerchantStatusInput = {
	chargeId: Scalars['ID']
	status: ChargeMerchantStatus
}

export type UpdateChargeWithPickupPointAsDeliveredInput = {
	chargeId: Scalars['ID']
	notes: Scalars['String']
}

export type UpdatePreOrderInput = {
	availableFromDate: Scalars['DateTime']
	customerBasePaymentUrl: Scalars['String']
	finalWeight: Scalars['Float']
	id: Scalars['ID']
	merchant: Scalars['ID']
	paymentDueDate: Scalars['DateTime']
	totalCost: Scalars['Float']
}

export type UpdateUserAsAdminInput = {
	addressLine1?: InputMaybe<Scalars['String']>
	addressLine2?: InputMaybe<Scalars['String']>
	addressPostalCode?: InputMaybe<Scalars['String']>
	city?: InputMaybe<Scalars['String']>
	country?: InputMaybe<Scalars['String']>
	email: Scalars['String']
	firstName?: InputMaybe<Scalars['String']>
	id: Scalars['ID']
	lastName?: InputMaybe<Scalars['String']>
	locale?: InputMaybe<Scalars['String']>
	merchant?: InputMaybe<Scalars['ID']>
	phoneNumber?: InputMaybe<Scalars['String']>
	profileImage?: InputMaybe<Scalars['String']>
	roles?: InputMaybe<Array<Role>>
}

export type UploadStripeDocumentsInput = {
	backDocument?: InputMaybe<Scalars['Upload']>
	frontDocument: Scalars['Upload']
	holderType: MerchantStripeAccountPaymentTypeEnum
	merchantId: Scalars['ID']
}

export type User = {
	__typename?: 'User'
	addresses: Array<UserAddress>
	birthdate?: Maybe<Scalars['DateTime']>
	createdAt: Scalars['DateTime']
	email: Scalars['String']
	firstName?: Maybe<Scalars['String']>
	fullName?: Maybe<Scalars['String']>
	id: Scalars['ID']
	lastName?: Maybe<Scalars['String']>
	locale?: Maybe<Scalars['String']>
	merchant?: Maybe<Merchant>
	nickName?: Maybe<Scalars['String']>
	orders?: Maybe<Array<Maybe<Order>>>
	phoneNumber?: Maybe<Scalars['String']>
	profileImage?: Maybe<Scalars['String']>
	roles: Array<Role>
	slug?: Maybe<Scalars['String']>
	updatedAt: Scalars['DateTime']
}

export type UserAddress = {
	__typename?: 'UserAddress'
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	country: Scalars['String']
	firstName: Scalars['String']
	id: Scalars['String']
	isDefault: Scalars['Boolean']
	lastName: Scalars['String']
}

export type UserAddressCreateInput = {
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	country: Scalars['String']
	firstName: Scalars['String']
	lastName: Scalars['String']
}

export type UserAddressUpdateInput = {
	addressLine1: Scalars['String']
	addressPostalCode: Scalars['String']
	city: Scalars['String']
	country: Scalars['String']
	firstName: Scalars['String']
	id: Scalars['String']
	isDefault: Scalars['Boolean']
	lastName: Scalars['String']
}

export type UserFilterInput = {
	id?: InputMaybe<Scalars['ID']>
	page?: InputMaybe<Scalars['Int']>
	rowsPerPage?: InputMaybe<Scalars['Int']>
	searchString?: InputMaybe<Scalars['String']>
	sort?: InputMaybe<SortInput>
}

export type UserUpdateInput = {
	birthdate: Scalars['DateTime']
	firstName: Scalars['String']
	lastName: Scalars['String']
	phoneNumber: Scalars['String']
	profileImage?: InputMaybe<Scalars['String']>
}

export type GetCategoriesEssentialsQueryVariables = Exact<{ [key: string]: never }>

export type GetCategoriesEssentialsQuery = {
	__typename?: 'Query'
	categories?:
		| Array<{ __typename?: 'Category'; title: string; slug: string; id: string }>
		| null
		| undefined
}

export type GetCategoryEssentialsQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetCategoryEssentialsQuery = {
	__typename?: 'Query'
	category: { __typename?: 'Category'; title: string; id: string; slug: string }
}

export type GiftCardCheckQueryVariables = Exact<{
	code: Scalars['String']
}>

export type GiftCardCheckQuery = {
	__typename?: 'Query'
	check: { __typename?: 'GiftCardCheck'; id: string; availableAmount: number }
}

export type ReadMerchantsPreviewQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']>
}>

export type ReadMerchantsPreviewQuery = {
	__typename?: 'Query'
	merchants: Array<{
		__typename?: 'MerchantPreview'
		id: string
		slug: string
		title: string
		description: string
		imgId?: string | null | undefined
	}>
}

export type ReadMerchantsWithProductsQueryVariables = Exact<{
	categoryId?: InputMaybe<Scalars['ID']>
	pickupId?: InputMaybe<Scalars['ID']>
	maxProductsPerMerchant?: InputMaybe<Scalars['Int']>
	location?: InputMaybe<Coordinates>
	maxDistanceInKm?: InputMaybe<Scalars['Int']>
	searchTerm?: InputMaybe<Scalars['String']>
	filterByAllergens?: InputMaybe<Array<ProductAllergensEnum> | ProductAllergensEnum>
	filterByCertificates?: InputMaybe<Array<ProductCertificatesEnum> | ProductCertificatesEnum>
	filterByDiets?: InputMaybe<Array<ProductDietsEnum> | ProductDietsEnum>
	after?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
}>

export type ReadMerchantsWithProductsQuery = {
	__typename?: 'Query'
	merchants: {
		__typename?: 'MerchantProductsConnection'
		edges: Array<{
			__typename?: 'MerchantProductsEdge'
			cursor: string
			node: {
				__typename?: 'MerchantProducts'
				id: string
				title: string
				imgId?: string | null | undefined
				slug: string
				latitude: number
				longitude: number
				shippingOptions: Array<{
					__typename?: 'ShippingOptionsPreview'
					id: string
					title: string
				}>
				products: Array<{
					__typename?: 'MerchantProductPreview'
					id: string
					slug: string
					title: string
					imgId?: string | null | undefined
					cost: number
					unitsPerProduct: number
					unitType: string
					stock: number
					isPreOrderable: boolean
					reservationTitle?: string | null | undefined
					certificates: Array<ProductCertificatesEnum>
					diets: Array<ProductDietsEnum>
				}>
				distanceFromUser?:
					| {
							__typename?: 'DistanceFromUser'
							distance: { __typename?: 'Distance'; text: string; value: number }
							duration: { __typename?: 'Duration'; text: string; value: number }
					  }
					| null
					| undefined
			}
		}>
		pageInfo: {
			__typename?: 'PageInfo'
			hasNextPage: boolean
			endCursor?: string | null | undefined
		}
	}
}

export type ReadMerchantByIdQueryVariables = Exact<{
	id: Scalars['ID']
	location?: InputMaybe<Coordinates>
}>

export type ReadMerchantByIdQuery = {
	__typename?: 'Query'
	merchant: {
		__typename?: 'Merchant'
		id: string
		slug: string
		title: string
		description: string
		imgId?: string | null | undefined
		latitude: number
		longitude: number
		pickUpDescription?: string | null | undefined
		addressLine1: string
		addressPostalCode: string
		city: string
		email: string
		contactName: string
		contactPhoneNumber: string
		organisationNumber: string
		status: MerchantStatus
		shippingOptions: Array<
			| {
					__typename?: 'ShippingOption'
					id: string
					title: string
					description?: string | null | undefined
					cost: number
					vatPct?: number | null | undefined
					currency?: string | null | undefined
			  }
			| null
			| undefined
		>
		categories: Array<{ __typename?: 'Category'; id: string; slug: string; title: string }>
		distanceFromUser?:
			| {
					__typename?: 'DistanceFromUser'
					distance: { __typename?: 'Distance'; text: string; value: number }
					duration: { __typename?: 'Duration'; text: string; value: number }
			  }
			| null
			| undefined
	}
}

export type ReadMerchantBySlugQueryVariables = Exact<{
	slug: Scalars['String']
	location?: InputMaybe<Coordinates>
}>

export type ReadMerchantBySlugQuery = {
	__typename?: 'Query'
	merchant: {
		__typename?: 'Merchant'
		id: string
		slug: string
		title: string
		description: string
		imgId?: string | null | undefined
		latitude: number
		longitude: number
		pickUpDescription?: string | null | undefined
		addressLine1: string
		addressPostalCode: string
		city: string
		email: string
		contactName: string
		contactPhoneNumber: string
		organisationNumber: string
		status: MerchantStatus
		shippingOptions: Array<
			| {
					__typename?: 'ShippingOption'
					id: string
					title: string
					description?: string | null | undefined
					cost: number
					vatPct?: number | null | undefined
					currency?: string | null | undefined
			  }
			| null
			| undefined
		>
		categories: Array<{ __typename?: 'Category'; id: string; slug: string; title: string }>
		distanceFromUser?:
			| {
					__typename?: 'DistanceFromUser'
					distance: { __typename?: 'Distance'; text: string; value: number }
					duration: { __typename?: 'Duration'; text: string; value: number }
			  }
			| null
			| undefined
	}
}

export type ReadMerchantsMapQueryVariables = Exact<{
	categoryId?: InputMaybe<Scalars['ID']>
	location: Coordinates
	maxDistanceInKm: Scalars['Int']
}>

export type ReadMerchantsMapQuery = {
	__typename?: 'Query'
	merchants: Array<{
		__typename?: 'MerchantMap'
		id: string
		title: string
		slug: string
		imgId?: string | null | undefined
		latitude: number
		longitude: number
		distanceFromUser?:
			| {
					__typename?: 'DistanceFromUser'
					distance: { __typename?: 'Distance'; text: string; value: number }
					duration: { __typename?: 'Duration'; text: string; value: number }
			  }
			| null
			| undefined
	}>
}

export type RegisterMerchantMutationVariables = Exact<{
	input: MerchantRegistrationInput
}>

export type RegisterMerchantMutation = {
	__typename?: 'Mutation'
	merchant: { __typename?: 'Merchant'; id: string; title: string }
}

export type ReadCompanyInfoByOrgNumberQueryVariables = Exact<{
	orgNumber: Scalars['String']
}>

export type ReadCompanyInfoByOrgNumberQuery = {
	__typename?: 'Query'
	info: {
		__typename?: 'BrregInfo'
		company: {
			__typename?: 'BrregInfoCompany'
			name: string
			orgNumber: string
			address: {
				__typename?: 'BrregInfoCompanyAddress'
				street: string
				postalCode: string
				city: string
			}
		}
		roles: Array<{
			__typename?: 'BrregInfoRole'
			firstname: string
			lastname: string
			birthdate: string
			roleType: string
			roleDescription: string
		}>
	}
}

export type CreateOrderMutationVariables = Exact<{
	input: CreateOrderInput
}>

export type CreateOrderMutation = {
	__typename?: 'Mutation'
	order: { __typename?: 'Order'; id: string; stripeSessionId?: string | null | undefined }
}

export type CompletePreOrderMutationVariables = Exact<{
	input: CompleteOrderInput
}>

export type CompletePreOrderMutation = {
	__typename?: 'Mutation'
	preOrder: { __typename?: 'Order'; id: string; stripeSessionId?: string | null | undefined }
}

export type ReadOrderByIdQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type ReadOrderByIdQuery = {
	__typename?: 'Query'
	order: {
		__typename?: 'Order'
		id: string
		totalCost: number
		totalVat: number
		amountDiscount: number
		isPreOrder?: boolean | null | undefined
		archiveNumber?: number | null | undefined
		lines: Array<{
			__typename?: 'OrderLine'
			comments?: string | null | undefined
			merchant: {
				__typename?: 'OrderLineMerchant'
				id: string
				title: string
				addressLine1: string
				addressPostalCode: string
				city: string
				contactPhoneNumber: string
			}
			shippingOption?:
				| {
						__typename?: 'ShippingOption'
						id: string
						title: string
						description?: string | null | undefined
						cost: number
						vatPct?: number | null | undefined
						currency?: string | null | undefined
				  }
				| null
				| undefined
			items: Array<{
				__typename?: 'OrderLineItem'
				quantity: number
				price: number
				product: {
					__typename?: 'OrderLineItemProduct'
					id: string
					title: string
					imgId?: string | null | undefined
					unitsPerProduct: number
					unitType: string
					vatPct: number
					cost: number
					isPreOrderable: boolean
					reservationCost?: number | null | undefined
					reservationTitle?: string | null | undefined
				}
			}>
		}>
	}
}

export type ReadCartMerchantQueryVariables = Exact<{
	merchantId: Scalars['ID']
	location?: InputMaybe<Coordinates>
}>

export type ReadCartMerchantQuery = {
	__typename?: 'Query'
	merchant: {
		__typename?: 'CartMerchant'
		id: string
		title: string
		distanceFromUser?:
			| {
					__typename?: 'DistanceFromUser'
					distance: { __typename?: 'Distance'; text: string; value: number }
					duration: { __typename?: 'Duration'; text: string; value: number }
			  }
			| null
			| undefined
		shippingOptions: Array<{
			__typename?: 'ShippingOption'
			id: string
			type: ShippingOptionTypeEnum
			title: string
			description?: string | null | undefined
			cost: number
			vatPct?: number | null | undefined
			currency?: string | null | undefined
			supportedTemperatures?: Array<TemperatureModeEnum> | null | undefined
			location?:
				| {
						__typename?: 'ShippingOptionLocation'
						address: string
						latitude: number
						longitude: number
				  }
				| null
				| undefined
		}>
	}
}

export type ReadCartProductQueryVariables = Exact<{
	productId: Scalars['ID']
}>

export type ReadCartProductQuery = {
	__typename?: 'Query'
	product: {
		__typename?: 'CartProduct'
		id: string
		title: string
		imgId?: string | null | undefined
		price: number
		isPreOrderable: boolean
	}
}

export type ReadOrdersAsUserQueryVariables = Exact<{
	after?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
}>

export type ReadOrdersAsUserQuery = {
	__typename?: 'Query'
	orders: {
		__typename?: 'OrderUserConnection'
		edges: Array<{
			__typename?: 'OrderUserEdge'
			cursor: string
			node: {
				__typename?: 'OrderUserPreview'
				id: string
				totalCost: number
				totalPrePaidCost: number
				totalVat: number
				status: OrderStatuses
				isPreOrder: boolean
				archiveNumber?: number | null | undefined
				completedAt?: any | null | undefined
				createdAt: any
				paymentDueDate?: any | null | undefined
				items: Array<{
					__typename?: 'OrderUserItem'
					merchant: { __typename?: 'OrderUserMerchantPreview'; id: string; title: string }
					products: Array<{
						__typename?: 'OrderUserProductPreview'
						id: string
						title: string
						img?: string | null | undefined
						quantity: number
					}>
					shippingOption?:
						| {
								__typename?: 'ChargeShippingOptionPreview'
								id: string
								title: string
								pickupInfo?:
									| {
											__typename?: 'ChargeShippingOptionPickupInfoPreview'
											status: ChargeTrackStatus
											pin: string
									  }
									| null
									| undefined
						  }
						| null
						| undefined
				}>
			}
		}>
		pageInfo: {
			__typename?: 'PageInfo'
			hasNextPage: boolean
			endCursor?: string | null | undefined
		}
	}
}

export type CheckCartQueryVariables = Exact<{
	input: Array<CartControlInput> | CartControlInput
}>

export type CheckCartQuery = {
	__typename?: 'Query'
	control: Array<{
		__typename?: 'CartControl'
		merchantId: string
		shippingOption: string
		isPassed: boolean
		problems?: Array<string> | null | undefined
	}>
}

export type CreateProductNotificationMutationVariables = Exact<{
	input: CreateNotificationInput
}>

export type CreateProductNotificationMutation = {
	__typename?: 'Mutation'
	notification: { __typename?: 'NotificationResponse'; id: string }
}

export type SearchEntitiesQueryVariables = Exact<{
	searchTerm: Scalars['String']
	limit: Scalars['Int']
}>

export type SearchEntitiesQuery = {
	__typename?: 'Query'
	results: {
		__typename?: 'SearchResult'
		merchants: Array<{
			__typename?: 'SearchResultMerchant'
			id: string
			slug: string
			title: string
			imgId?: string | null | undefined
		}>
		products: Array<{
			__typename?: 'SearchResultProduct'
			id: string
			slug: string
			title: string
			imgId?: string | null | undefined
			cost: number
			unitsPerProduct: number
			unitType: string
			isPreOrderable: boolean
		}>
	}
}

export type ReadProductsPreviewQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']>
}>

export type ReadProductsPreviewQuery = {
	__typename?: 'Query'
	products: Array<{
		__typename?: 'ProductPreview'
		id: string
		slug: string
		title: string
		imgId?: string | null | undefined
		cost: number
		unitsPerProduct: number
		unitType: string
		isPreOrderable: boolean
		merchant: { __typename?: 'MerchantReference'; id: string; slug: string; title: string }
	}>
}

export type ReadProductByIdQueryVariables = Exact<{
	id: Scalars['ID']
	location?: InputMaybe<Coordinates>
}>

export type ReadProductByIdQuery = {
	__typename?: 'Query'
	product: {
		__typename?: 'Product'
		id: string
		slug: string
		title: string
		imgId?: string | null | undefined
		description: string
		cost: number
		unitsPerProduct: number
		unitType: string
		stock: number
		isPreOrderable?: boolean | null | undefined
		reservationTitle?: string | null | undefined
		reservationDescription?: string | null | undefined
		reservationCost?: number | null | undefined
		productionYear: string
		packaging: string
		storage?: string | null | undefined
		deliveryCondition?: string | null | undefined
		allergens: Array<ProductAllergensEnum>
		allergensStatus: ProductAllergensStatusEnum
		certificates: Array<ProductCertificatesEnum>
		diets: Array<ProductDietsEnum>
		merchant: {
			__typename?: 'ProductMerchant'
			id: string
			slug: string
			title: string
			latitude: number
			longitude: number
			imgId?: string | null | undefined
			addressLine1: string
			addressPostalCode: string
			city: string
			distanceFromUser?:
				| {
						__typename?: 'DistanceFromUser'
						distance: { __typename?: 'Distance'; text: string; value: number }
						duration: { __typename?: 'Duration'; text: string; value: number }
				  }
				| null
				| undefined
			shippingOptions: Array<
				| {
						__typename?: 'ShippingOption'
						id: string
						type: ShippingOptionTypeEnum
						title: string
						description?: string | null | undefined
						cost: number
						vatPct?: number | null | undefined
						currency?: string | null | undefined
				  }
				| null
				| undefined
			>
		}
	}
}

export type ReadProductBySlugQueryVariables = Exact<{
	slugMerchant: Scalars['String']
	slugProduct: Scalars['String']
	location?: InputMaybe<Coordinates>
}>

export type ReadProductBySlugQuery = {
	__typename?: 'Query'
	product: {
		__typename?: 'Product'
		id: string
		slug: string
		title: string
		imgId?: string | null | undefined
		description: string
		cost: number
		unitsPerProduct: number
		unitType: string
		stock: number
		isPreOrderable?: boolean | null | undefined
		reservationTitle?: string | null | undefined
		reservationDescription?: string | null | undefined
		reservationCost?: number | null | undefined
		productionYear: string
		packaging: string
		storage?: string | null | undefined
		deliveryCondition?: string | null | undefined
		allergens: Array<ProductAllergensEnum>
		allergensStatus: ProductAllergensStatusEnum
		certificates: Array<ProductCertificatesEnum>
		diets: Array<ProductDietsEnum>
		merchant: {
			__typename?: 'ProductMerchant'
			id: string
			slug: string
			title: string
			latitude: number
			longitude: number
			imgId?: string | null | undefined
			addressLine1: string
			addressPostalCode: string
			city: string
			distanceFromUser?:
				| {
						__typename?: 'DistanceFromUser'
						distance: { __typename?: 'Distance'; text: string; value: number }
						duration: { __typename?: 'Duration'; text: string; value: number }
				  }
				| null
				| undefined
			shippingOptions: Array<
				| {
						__typename?: 'ShippingOption'
						id: string
						type: ShippingOptionTypeEnum
						title: string
						description?: string | null | undefined
						cost: number
						vatPct?: number | null | undefined
						currency?: string | null | undefined
				  }
				| null
				| undefined
			>
		}
	}
}

export type ReadProductsByMerchantIdQueryVariables = Exact<{
	merchantId: Scalars['ID']
	first?: InputMaybe<Scalars['Int']>
	after?: InputMaybe<Scalars['String']>
}>

export type ReadProductsByMerchantIdQuery = {
	__typename?: 'Query'
	products: {
		__typename?: 'ProductsConnection'
		edges: Array<{
			__typename?: 'ProductsEdge'
			cursor: string
			node: {
				__typename?: 'MerchantProductPreview'
				id: string
				slug: string
				title: string
				imgId?: string | null | undefined
				cost: number
				unitsPerProduct: number
				unitType: string
				isPreOrderable: boolean
				reservationTitle?: string | null | undefined
				stock: number
				certificates: Array<ProductCertificatesEnum>
			}
		}>
		pageInfo: {
			__typename?: 'PageInfo'
			hasNextPage: boolean
			endCursor?: string | null | undefined
		}
	}
}

export type ReadPickupPointByIdQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type ReadPickupPointByIdQuery = {
	__typename?: 'Query'
	pickup: {
		__typename?: 'PickupPoint'
		id: string
		title: string
		description?: string | null | undefined
		terminal: {
			__typename?: 'Terminal'
			id: string
			address: string
			latitude: number
			longitude: number
			boxes: Array<{ __typename?: 'TerminalBox'; temperatureMode: TemperatureModeEnum }>
		}
	}
}

export type ReadPickupPointBySlugQueryVariables = Exact<{
	slug: Scalars['String']
}>

export type ReadPickupPointBySlugQuery = {
	__typename?: 'Query'
	pickup: {
		__typename?: 'PickupPoint'
		id: string
		title: string
		description?: string | null | undefined
		terminal: {
			__typename?: 'Terminal'
			id: string
			address: string
			latitude: number
			longitude: number
			boxes: Array<{ __typename?: 'TerminalBox'; temperatureMode: TemperatureModeEnum }>
		}
	}
}

export type ReadPickupPointsListQueryVariables = Exact<{ [key: string]: never }>

export type ReadPickupPointsListQuery = {
	__typename?: 'Query'
	pickups: Array<{
		__typename?: 'PickupPointsListItem'
		id: string
		slug: string
		title: string
		description?: string | null | undefined
		terminal: {
			__typename?: 'Terminal'
			id: string
			address: string
			latitude: number
			longitude: number
			boxes: Array<{ __typename?: 'TerminalBox'; temperatureMode: TemperatureModeEnum }>
		}
	}>
}

export type ReadUserQueryVariables = Exact<{ [key: string]: never }>

export type ReadUserQuery = {
	__typename?: 'Query'
	user: {
		__typename?: 'User'
		id: string
		slug?: string | null | undefined
		firstName?: string | null | undefined
		nickName?: string | null | undefined
		lastName?: string | null | undefined
		fullName?: string | null | undefined
		birthdate?: any | null | undefined
		profileImage?: string | null | undefined
		email: string
		phoneNumber?: string | null | undefined
		locale?: string | null | undefined
		roles: Array<Role>
		createdAt: any
		updatedAt: any
		merchant?:
			| {
					__typename?: 'Merchant'
					id: string
					title: string
					imgId?: string | null | undefined
			  }
			| null
			| undefined
	}
}

export type ReadUserAddressesQueryVariables = Exact<{ [key: string]: never }>

export type ReadUserAddressesQuery = {
	__typename?: 'Query'
	addresses: Array<{
		__typename?: 'UserAddress'
		id: string
		firstName: string
		lastName: string
		addressLine1: string
		addressPostalCode: string
		city: string
		country: string
		isDefault: boolean
	}>
}

export type ReadUserAddressByIdQueryVariables = Exact<{
	addressId: Scalars['ID']
}>

export type ReadUserAddressByIdQuery = {
	__typename?: 'Query'
	address: {
		__typename?: 'UserAddress'
		id: string
		firstName: string
		lastName: string
		addressLine1: string
		addressPostalCode: string
		city: string
		country: string
		isDefault: boolean
	}
}

export type UpdateUserMutationVariables = Exact<{
	input: UserUpdateInput
}>

export type UpdateUserMutation = {
	__typename?: 'Mutation'
	user: {
		__typename?: 'User'
		id: string
		slug?: string | null | undefined
		firstName?: string | null | undefined
		nickName?: string | null | undefined
		lastName?: string | null | undefined
		fullName?: string | null | undefined
		birthdate?: any | null | undefined
		profileImage?: string | null | undefined
		email: string
		phoneNumber?: string | null | undefined
		locale?: string | null | undefined
		roles: Array<Role>
		createdAt: any
		updatedAt: any
		merchant?: { __typename?: 'Merchant'; id: string; title: string } | null | undefined
	}
}

export type CreateUserAddressMutationVariables = Exact<{
	address: UserAddressCreateInput
}>

export type CreateUserAddressMutation = {
	__typename?: 'Mutation'
	addresses: Array<{
		__typename?: 'UserAddress'
		id: string
		firstName: string
		lastName: string
		addressLine1: string
		addressPostalCode: string
		city: string
		country: string
		isDefault: boolean
	}>
}

export type UpdateUserAddressMutationVariables = Exact<{
	address: UserAddressUpdateInput
}>

export type UpdateUserAddressMutation = {
	__typename?: 'Mutation'
	addresses: Array<{
		__typename?: 'UserAddress'
		id: string
		firstName: string
		lastName: string
		addressLine1: string
		addressPostalCode: string
		city: string
		country: string
		isDefault: boolean
	}>
}

export type DeleteUserAddressMutationVariables = Exact<{
	addressId: Scalars['ID']
}>

export type DeleteUserAddressMutation = {
	__typename?: 'Mutation'
	addresses: Array<{
		__typename?: 'UserAddress'
		id: string
		firstName: string
		lastName: string
		addressLine1: string
		addressPostalCode: string
		city: string
		country: string
		isDefault: boolean
	}>
}

export const GetCategoriesEssentialsDocument = gql`
	query getCategoriesEssentials {
		categories: categories {
			title
			slug
			id
		}
	}
`

/**
 * __useGetCategoriesEssentialsQuery__
 *
 * To run a query within a React component, call `useGetCategoriesEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesEssentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesEssentialsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetCategoriesEssentialsQuery,
		GetCategoriesEssentialsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetCategoriesEssentialsQuery, GetCategoriesEssentialsQueryVariables>(
		GetCategoriesEssentialsDocument,
		options
	)
}
export function useGetCategoriesEssentialsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCategoriesEssentialsQuery,
		GetCategoriesEssentialsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetCategoriesEssentialsQuery, GetCategoriesEssentialsQueryVariables>(
		GetCategoriesEssentialsDocument,
		options
	)
}
export type GetCategoriesEssentialsQueryHookResult = ReturnType<
	typeof useGetCategoriesEssentialsQuery
>
export type GetCategoriesEssentialsLazyQueryHookResult = ReturnType<
	typeof useGetCategoriesEssentialsLazyQuery
>
export type GetCategoriesEssentialsQueryResult = Apollo.QueryResult<
	GetCategoriesEssentialsQuery,
	GetCategoriesEssentialsQueryVariables
>
export const GetCategoryEssentialsDocument = gql`
	query getCategoryEssentials($id: ID!) {
		category: category(id: $id) {
			title
			id
			slug
		}
	}
`

/**
 * __useGetCategoryEssentialsQuery__
 *
 * To run a query within a React component, call `useGetCategoryEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryEssentialsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCategoryEssentialsQuery,
		GetCategoryEssentialsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetCategoryEssentialsQuery, GetCategoryEssentialsQueryVariables>(
		GetCategoryEssentialsDocument,
		options
	)
}
export function useGetCategoryEssentialsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCategoryEssentialsQuery,
		GetCategoryEssentialsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetCategoryEssentialsQuery, GetCategoryEssentialsQueryVariables>(
		GetCategoryEssentialsDocument,
		options
	)
}
export type GetCategoryEssentialsQueryHookResult = ReturnType<typeof useGetCategoryEssentialsQuery>
export type GetCategoryEssentialsLazyQueryHookResult = ReturnType<
	typeof useGetCategoryEssentialsLazyQuery
>
export type GetCategoryEssentialsQueryResult = Apollo.QueryResult<
	GetCategoryEssentialsQuery,
	GetCategoryEssentialsQueryVariables
>
export const GiftCardCheckDocument = gql`
	query giftCardCheck($code: String!) {
		check: giftCardCheck(code: $code) {
			id
			availableAmount
		}
	}
`

/**
 * __useGiftCardCheckQuery__
 *
 * To run a query within a React component, call `useGiftCardCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftCardCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftCardCheckQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGiftCardCheckQuery(
	baseOptions: Apollo.QueryHookOptions<GiftCardCheckQuery, GiftCardCheckQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GiftCardCheckQuery, GiftCardCheckQueryVariables>(
		GiftCardCheckDocument,
		options
	)
}
export function useGiftCardCheckLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GiftCardCheckQuery, GiftCardCheckQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GiftCardCheckQuery, GiftCardCheckQueryVariables>(
		GiftCardCheckDocument,
		options
	)
}
export type GiftCardCheckQueryHookResult = ReturnType<typeof useGiftCardCheckQuery>
export type GiftCardCheckLazyQueryHookResult = ReturnType<typeof useGiftCardCheckLazyQuery>
export type GiftCardCheckQueryResult = Apollo.QueryResult<
	GiftCardCheckQuery,
	GiftCardCheckQueryVariables
>
export const ReadMerchantsPreviewDocument = gql`
	query readMerchantsPreview($limit: Int) {
		merchants: readMerchantsPreview(limit: $limit) {
			id
			slug
			title
			description
			imgId
		}
	}
`

/**
 * __useReadMerchantsPreviewQuery__
 *
 * To run a query within a React component, call `useReadMerchantsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMerchantsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMerchantsPreviewQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useReadMerchantsPreviewQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ReadMerchantsPreviewQuery,
		ReadMerchantsPreviewQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadMerchantsPreviewQuery, ReadMerchantsPreviewQueryVariables>(
		ReadMerchantsPreviewDocument,
		options
	)
}
export function useReadMerchantsPreviewLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadMerchantsPreviewQuery,
		ReadMerchantsPreviewQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadMerchantsPreviewQuery, ReadMerchantsPreviewQueryVariables>(
		ReadMerchantsPreviewDocument,
		options
	)
}
export type ReadMerchantsPreviewQueryHookResult = ReturnType<typeof useReadMerchantsPreviewQuery>
export type ReadMerchantsPreviewLazyQueryHookResult = ReturnType<
	typeof useReadMerchantsPreviewLazyQuery
>
export type ReadMerchantsPreviewQueryResult = Apollo.QueryResult<
	ReadMerchantsPreviewQuery,
	ReadMerchantsPreviewQueryVariables
>
export const ReadMerchantsWithProductsDocument = gql`
	query readMerchantsWithProducts(
		$categoryId: ID
		$pickupId: ID
		$maxProductsPerMerchant: Int
		$location: Coordinates
		$maxDistanceInKm: Int
		$searchTerm: String
		$filterByAllergens: [ProductAllergensEnum!]
		$filterByCertificates: [ProductCertificatesEnum!]
		$filterByDiets: [ProductDietsEnum!]
		$after: String
		$first: Int
	) {
		merchants: readMerchantsWithProducts(
			categoryId: $categoryId
			pickupId: $pickupId
			location: $location
			maxDistanceInKm: $maxDistanceInKm
			maxProductsPerMerchant: $maxProductsPerMerchant
			searchTerm: $searchTerm
			filterByAllergens: $filterByAllergens
			filterByCertificates: $filterByCertificates
			filterByDiets: $filterByDiets
			after: $after
			first: $first
		) {
			edges {
				node {
					id
					title
					imgId
					slug
					latitude
					longitude
					shippingOptions {
						id
						title
					}
					products {
						id
						slug
						title
						imgId
						cost
						unitsPerProduct
						unitType
						stock
						isPreOrderable
						reservationTitle
						certificates
						diets
					}
					distanceFromUser {
						distance {
							text
							value
						}
						duration {
							text
							value
						}
					}
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`

/**
 * __useReadMerchantsWithProductsQuery__
 *
 * To run a query within a React component, call `useReadMerchantsWithProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMerchantsWithProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMerchantsWithProductsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      pickupId: // value for 'pickupId'
 *      maxProductsPerMerchant: // value for 'maxProductsPerMerchant'
 *      location: // value for 'location'
 *      maxDistanceInKm: // value for 'maxDistanceInKm'
 *      searchTerm: // value for 'searchTerm'
 *      filterByAllergens: // value for 'filterByAllergens'
 *      filterByCertificates: // value for 'filterByCertificates'
 *      filterByDiets: // value for 'filterByDiets'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReadMerchantsWithProductsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ReadMerchantsWithProductsQuery,
		ReadMerchantsWithProductsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadMerchantsWithProductsQuery, ReadMerchantsWithProductsQueryVariables>(
		ReadMerchantsWithProductsDocument,
		options
	)
}
export function useReadMerchantsWithProductsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadMerchantsWithProductsQuery,
		ReadMerchantsWithProductsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReadMerchantsWithProductsQuery,
		ReadMerchantsWithProductsQueryVariables
	>(ReadMerchantsWithProductsDocument, options)
}
export type ReadMerchantsWithProductsQueryHookResult = ReturnType<
	typeof useReadMerchantsWithProductsQuery
>
export type ReadMerchantsWithProductsLazyQueryHookResult = ReturnType<
	typeof useReadMerchantsWithProductsLazyQuery
>
export type ReadMerchantsWithProductsQueryResult = Apollo.QueryResult<
	ReadMerchantsWithProductsQuery,
	ReadMerchantsWithProductsQueryVariables
>
export const ReadMerchantByIdDocument = gql`
	query readMerchantById($id: ID!, $location: Coordinates) {
		merchant: readMerchantById(id: $id, location: $location) {
			id
			slug
			title
			description
			imgId
			latitude
			longitude
			pickUpDescription
			addressLine1
			addressPostalCode
			city
			email
			contactName
			contactPhoneNumber
			organisationNumber
			status
			shippingOptions {
				id
				title
				description
				cost
				vatPct
				currency
			}
			categories {
				id
				slug
				title
			}
			distanceFromUser {
				distance {
					text
					value
				}
				duration {
					text
					value
				}
			}
		}
	}
`

/**
 * __useReadMerchantByIdQuery__
 *
 * To run a query within a React component, call `useReadMerchantByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMerchantByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMerchantByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useReadMerchantByIdQuery(
	baseOptions: Apollo.QueryHookOptions<ReadMerchantByIdQuery, ReadMerchantByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadMerchantByIdQuery, ReadMerchantByIdQueryVariables>(
		ReadMerchantByIdDocument,
		options
	)
}
export function useReadMerchantByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadMerchantByIdQuery, ReadMerchantByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadMerchantByIdQuery, ReadMerchantByIdQueryVariables>(
		ReadMerchantByIdDocument,
		options
	)
}
export type ReadMerchantByIdQueryHookResult = ReturnType<typeof useReadMerchantByIdQuery>
export type ReadMerchantByIdLazyQueryHookResult = ReturnType<typeof useReadMerchantByIdLazyQuery>
export type ReadMerchantByIdQueryResult = Apollo.QueryResult<
	ReadMerchantByIdQuery,
	ReadMerchantByIdQueryVariables
>
export const ReadMerchantBySlugDocument = gql`
	query readMerchantBySlug($slug: String!, $location: Coordinates) {
		merchant: readMerchantBySlug(slug: $slug, location: $location) {
			id
			slug
			title
			description
			imgId
			latitude
			longitude
			pickUpDescription
			addressLine1
			addressPostalCode
			city
			email
			contactName
			contactPhoneNumber
			organisationNumber
			status
			shippingOptions {
				id
				title
				description
				cost
				vatPct
				currency
			}
			categories {
				id
				slug
				title
			}
			distanceFromUser {
				distance {
					text
					value
				}
				duration {
					text
					value
				}
			}
		}
	}
`

/**
 * __useReadMerchantBySlugQuery__
 *
 * To run a query within a React component, call `useReadMerchantBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMerchantBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMerchantBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useReadMerchantBySlugQuery(
	baseOptions: Apollo.QueryHookOptions<ReadMerchantBySlugQuery, ReadMerchantBySlugQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadMerchantBySlugQuery, ReadMerchantBySlugQueryVariables>(
		ReadMerchantBySlugDocument,
		options
	)
}
export function useReadMerchantBySlugLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadMerchantBySlugQuery,
		ReadMerchantBySlugQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadMerchantBySlugQuery, ReadMerchantBySlugQueryVariables>(
		ReadMerchantBySlugDocument,
		options
	)
}
export type ReadMerchantBySlugQueryHookResult = ReturnType<typeof useReadMerchantBySlugQuery>
export type ReadMerchantBySlugLazyQueryHookResult = ReturnType<
	typeof useReadMerchantBySlugLazyQuery
>
export type ReadMerchantBySlugQueryResult = Apollo.QueryResult<
	ReadMerchantBySlugQuery,
	ReadMerchantBySlugQueryVariables
>
export const ReadMerchantsMapDocument = gql`
	query readMerchantsMap($categoryId: ID, $location: Coordinates!, $maxDistanceInKm: Int!) {
		merchants: readMerchantsMap(
			categoryId: $categoryId
			location: $location
			maxDistanceInKm: $maxDistanceInKm
		) {
			id
			title
			slug
			imgId
			latitude
			longitude
			distanceFromUser {
				distance {
					text
					value
				}
				duration {
					text
					value
				}
			}
		}
	}
`

/**
 * __useReadMerchantsMapQuery__
 *
 * To run a query within a React component, call `useReadMerchantsMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMerchantsMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMerchantsMapQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      location: // value for 'location'
 *      maxDistanceInKm: // value for 'maxDistanceInKm'
 *   },
 * });
 */
export function useReadMerchantsMapQuery(
	baseOptions: Apollo.QueryHookOptions<ReadMerchantsMapQuery, ReadMerchantsMapQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadMerchantsMapQuery, ReadMerchantsMapQueryVariables>(
		ReadMerchantsMapDocument,
		options
	)
}
export function useReadMerchantsMapLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadMerchantsMapQuery, ReadMerchantsMapQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadMerchantsMapQuery, ReadMerchantsMapQueryVariables>(
		ReadMerchantsMapDocument,
		options
	)
}
export type ReadMerchantsMapQueryHookResult = ReturnType<typeof useReadMerchantsMapQuery>
export type ReadMerchantsMapLazyQueryHookResult = ReturnType<typeof useReadMerchantsMapLazyQuery>
export type ReadMerchantsMapQueryResult = Apollo.QueryResult<
	ReadMerchantsMapQuery,
	ReadMerchantsMapQueryVariables
>
export const RegisterMerchantDocument = gql`
	mutation registerMerchant($input: MerchantRegistrationInput!) {
		merchant: registerMerchant(input: $input) {
			id
			title
		}
	}
`
export type RegisterMerchantMutationFn = Apollo.MutationFunction<
	RegisterMerchantMutation,
	RegisterMerchantMutationVariables
>

/**
 * __useRegisterMerchantMutation__
 *
 * To run a mutation, you first call `useRegisterMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMerchantMutation, { data, loading, error }] = useRegisterMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMerchantMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RegisterMerchantMutation,
		RegisterMerchantMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<RegisterMerchantMutation, RegisterMerchantMutationVariables>(
		RegisterMerchantDocument,
		options
	)
}
export type RegisterMerchantMutationHookResult = ReturnType<typeof useRegisterMerchantMutation>
export type RegisterMerchantMutationResult = Apollo.MutationResult<RegisterMerchantMutation>
export type RegisterMerchantMutationOptions = Apollo.BaseMutationOptions<
	RegisterMerchantMutation,
	RegisterMerchantMutationVariables
>
export const ReadCompanyInfoByOrgNumberDocument = gql`
	query readCompanyInfoByOrgNumber($orgNumber: String!) {
		info: readCompanyInfoByOrgNumber(orgNumber: $orgNumber) {
			company {
				name
				orgNumber
				address {
					street
					postalCode
					city
				}
			}
			roles {
				firstname
				lastname
				birthdate
				roleType
				roleDescription
			}
		}
	}
`

/**
 * __useReadCompanyInfoByOrgNumberQuery__
 *
 * To run a query within a React component, call `useReadCompanyInfoByOrgNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCompanyInfoByOrgNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCompanyInfoByOrgNumberQuery({
 *   variables: {
 *      orgNumber: // value for 'orgNumber'
 *   },
 * });
 */
export function useReadCompanyInfoByOrgNumberQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReadCompanyInfoByOrgNumberQuery,
		ReadCompanyInfoByOrgNumberQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadCompanyInfoByOrgNumberQuery, ReadCompanyInfoByOrgNumberQueryVariables>(
		ReadCompanyInfoByOrgNumberDocument,
		options
	)
}
export function useReadCompanyInfoByOrgNumberLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadCompanyInfoByOrgNumberQuery,
		ReadCompanyInfoByOrgNumberQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReadCompanyInfoByOrgNumberQuery,
		ReadCompanyInfoByOrgNumberQueryVariables
	>(ReadCompanyInfoByOrgNumberDocument, options)
}
export type ReadCompanyInfoByOrgNumberQueryHookResult = ReturnType<
	typeof useReadCompanyInfoByOrgNumberQuery
>
export type ReadCompanyInfoByOrgNumberLazyQueryHookResult = ReturnType<
	typeof useReadCompanyInfoByOrgNumberLazyQuery
>
export type ReadCompanyInfoByOrgNumberQueryResult = Apollo.QueryResult<
	ReadCompanyInfoByOrgNumberQuery,
	ReadCompanyInfoByOrgNumberQueryVariables
>
export const CreateOrderDocument = gql`
	mutation createOrder($input: CreateOrderInput!) {
		order: createOrder(input: $input) {
			id
			stripeSessionId
		}
	}
`
export type CreateOrderMutationFn = Apollo.MutationFunction<
	CreateOrderMutation,
	CreateOrderMutationVariables
>

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
		CreateOrderDocument,
		options
	)
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
	CreateOrderMutation,
	CreateOrderMutationVariables
>
export const CompletePreOrderDocument = gql`
	mutation completePreOrder($input: CompleteOrderInput!) {
		preOrder: completePreOrder(input: $input) {
			id
			stripeSessionId
		}
	}
`
export type CompletePreOrderMutationFn = Apollo.MutationFunction<
	CompletePreOrderMutation,
	CompletePreOrderMutationVariables
>

/**
 * __useCompletePreOrderMutation__
 *
 * To run a mutation, you first call `useCompletePreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePreOrderMutation, { data, loading, error }] = useCompletePreOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePreOrderMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CompletePreOrderMutation,
		CompletePreOrderMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CompletePreOrderMutation, CompletePreOrderMutationVariables>(
		CompletePreOrderDocument,
		options
	)
}
export type CompletePreOrderMutationHookResult = ReturnType<typeof useCompletePreOrderMutation>
export type CompletePreOrderMutationResult = Apollo.MutationResult<CompletePreOrderMutation>
export type CompletePreOrderMutationOptions = Apollo.BaseMutationOptions<
	CompletePreOrderMutation,
	CompletePreOrderMutationVariables
>
export const ReadOrderByIdDocument = gql`
	query readOrderById($id: ID!) {
		order: readOrderById(id: $id) {
			id
			totalCost
			totalVat
			amountDiscount
			isPreOrder
			archiveNumber
			lines {
				merchant {
					id
					title
					addressLine1
					addressPostalCode
					city
					contactPhoneNumber
				}
				shippingOption {
					id
					title
					description
					cost
					vatPct
					currency
				}
				comments
				items {
					product {
						id
						title
						imgId
						unitsPerProduct
						unitType
						vatPct
						cost
						isPreOrderable
						reservationCost
						reservationTitle
					}
					quantity
					price
				}
			}
		}
	}
`

/**
 * __useReadOrderByIdQuery__
 *
 * To run a query within a React component, call `useReadOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadOrderByIdQuery(
	baseOptions: Apollo.QueryHookOptions<ReadOrderByIdQuery, ReadOrderByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadOrderByIdQuery, ReadOrderByIdQueryVariables>(
		ReadOrderByIdDocument,
		options
	)
}
export function useReadOrderByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadOrderByIdQuery, ReadOrderByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadOrderByIdQuery, ReadOrderByIdQueryVariables>(
		ReadOrderByIdDocument,
		options
	)
}
export type ReadOrderByIdQueryHookResult = ReturnType<typeof useReadOrderByIdQuery>
export type ReadOrderByIdLazyQueryHookResult = ReturnType<typeof useReadOrderByIdLazyQuery>
export type ReadOrderByIdQueryResult = Apollo.QueryResult<
	ReadOrderByIdQuery,
	ReadOrderByIdQueryVariables
>
export const ReadCartMerchantDocument = gql`
	query readCartMerchant($merchantId: ID!, $location: Coordinates) {
		merchant: readCartMerchant(merchantId: $merchantId, location: $location) {
			id
			title
			distanceFromUser {
				distance {
					text
					value
				}
				duration {
					text
					value
				}
			}
			shippingOptions {
				id
				type
				title
				description
				cost
				vatPct
				currency
				location {
					address
					latitude
					longitude
				}
				supportedTemperatures
			}
		}
	}
`

/**
 * __useReadCartMerchantQuery__
 *
 * To run a query within a React component, call `useReadCartMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCartMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCartMerchantQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useReadCartMerchantQuery(
	baseOptions: Apollo.QueryHookOptions<ReadCartMerchantQuery, ReadCartMerchantQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadCartMerchantQuery, ReadCartMerchantQueryVariables>(
		ReadCartMerchantDocument,
		options
	)
}
export function useReadCartMerchantLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadCartMerchantQuery, ReadCartMerchantQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadCartMerchantQuery, ReadCartMerchantQueryVariables>(
		ReadCartMerchantDocument,
		options
	)
}
export type ReadCartMerchantQueryHookResult = ReturnType<typeof useReadCartMerchantQuery>
export type ReadCartMerchantLazyQueryHookResult = ReturnType<typeof useReadCartMerchantLazyQuery>
export type ReadCartMerchantQueryResult = Apollo.QueryResult<
	ReadCartMerchantQuery,
	ReadCartMerchantQueryVariables
>
export const ReadCartProductDocument = gql`
	query readCartProduct($productId: ID!) {
		product: readCartProduct(productId: $productId) {
			id
			title
			imgId
			price
			isPreOrderable
		}
	}
`

/**
 * __useReadCartProductQuery__
 *
 * To run a query within a React component, call `useReadCartProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCartProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCartProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useReadCartProductQuery(
	baseOptions: Apollo.QueryHookOptions<ReadCartProductQuery, ReadCartProductQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadCartProductQuery, ReadCartProductQueryVariables>(
		ReadCartProductDocument,
		options
	)
}
export function useReadCartProductLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadCartProductQuery, ReadCartProductQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadCartProductQuery, ReadCartProductQueryVariables>(
		ReadCartProductDocument,
		options
	)
}
export type ReadCartProductQueryHookResult = ReturnType<typeof useReadCartProductQuery>
export type ReadCartProductLazyQueryHookResult = ReturnType<typeof useReadCartProductLazyQuery>
export type ReadCartProductQueryResult = Apollo.QueryResult<
	ReadCartProductQuery,
	ReadCartProductQueryVariables
>
export const ReadOrdersAsUserDocument = gql`
	query readOrdersAsUser($after: String, $first: Int) {
		orders: readOrdersAsUser(after: $after, first: $first) {
			edges {
				cursor
				node {
					id
					totalCost
					totalPrePaidCost
					totalVat
					status
					isPreOrder
					archiveNumber
					completedAt
					createdAt
					paymentDueDate
					items {
						merchant {
							id
							title
						}
						products {
							id
							title
							img
							quantity
						}
						shippingOption {
							id
							title
							pickupInfo {
								status
								pin
							}
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`

/**
 * __useReadOrdersAsUserQuery__
 *
 * To run a query within a React component, call `useReadOrdersAsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrdersAsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrdersAsUserQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReadOrdersAsUserQuery(
	baseOptions?: Apollo.QueryHookOptions<ReadOrdersAsUserQuery, ReadOrdersAsUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadOrdersAsUserQuery, ReadOrdersAsUserQueryVariables>(
		ReadOrdersAsUserDocument,
		options
	)
}
export function useReadOrdersAsUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadOrdersAsUserQuery, ReadOrdersAsUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadOrdersAsUserQuery, ReadOrdersAsUserQueryVariables>(
		ReadOrdersAsUserDocument,
		options
	)
}
export type ReadOrdersAsUserQueryHookResult = ReturnType<typeof useReadOrdersAsUserQuery>
export type ReadOrdersAsUserLazyQueryHookResult = ReturnType<typeof useReadOrdersAsUserLazyQuery>
export type ReadOrdersAsUserQueryResult = Apollo.QueryResult<
	ReadOrdersAsUserQuery,
	ReadOrdersAsUserQueryVariables
>
export const CheckCartDocument = gql`
	query checkCart($input: [CartControlInput!]!) {
		control: checkCart(input: $input) {
			merchantId
			shippingOption
			isPassed
			problems
		}
	}
`

/**
 * __useCheckCartQuery__
 *
 * To run a query within a React component, call `useCheckCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckCartQuery(
	baseOptions: Apollo.QueryHookOptions<CheckCartQuery, CheckCartQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<CheckCartQuery, CheckCartQueryVariables>(CheckCartDocument, options)
}
export function useCheckCartLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CheckCartQuery, CheckCartQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<CheckCartQuery, CheckCartQueryVariables>(CheckCartDocument, options)
}
export type CheckCartQueryHookResult = ReturnType<typeof useCheckCartQuery>
export type CheckCartLazyQueryHookResult = ReturnType<typeof useCheckCartLazyQuery>
export type CheckCartQueryResult = Apollo.QueryResult<CheckCartQuery, CheckCartQueryVariables>
export const CreateProductNotificationDocument = gql`
	mutation createProductNotification($input: CreateNotificationInput!) {
		notification: createNotification(input: $input) {
			id
		}
	}
`
export type CreateProductNotificationMutationFn = Apollo.MutationFunction<
	CreateProductNotificationMutation,
	CreateProductNotificationMutationVariables
>

/**
 * __useCreateProductNotificationMutation__
 *
 * To run a mutation, you first call `useCreateProductNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductNotificationMutation, { data, loading, error }] = useCreateProductNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductNotificationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateProductNotificationMutation,
		CreateProductNotificationMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateProductNotificationMutation,
		CreateProductNotificationMutationVariables
	>(CreateProductNotificationDocument, options)
}
export type CreateProductNotificationMutationHookResult = ReturnType<
	typeof useCreateProductNotificationMutation
>
export type CreateProductNotificationMutationResult =
	Apollo.MutationResult<CreateProductNotificationMutation>
export type CreateProductNotificationMutationOptions = Apollo.BaseMutationOptions<
	CreateProductNotificationMutation,
	CreateProductNotificationMutationVariables
>
export const SearchEntitiesDocument = gql`
	query searchEntities($searchTerm: String!, $limit: Int!) {
		results: searchEntities(searchTerm: $searchTerm, limit: $limit) {
			merchants {
				id
				slug
				title
				imgId
			}
			products {
				id
				slug
				title
				imgId
				cost
				unitsPerProduct
				unitType
				isPreOrderable
			}
		}
	}
`

/**
 * __useSearchEntitiesQuery__
 *
 * To run a query within a React component, call `useSearchEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEntitiesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchEntitiesQuery(
	baseOptions: Apollo.QueryHookOptions<SearchEntitiesQuery, SearchEntitiesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<SearchEntitiesQuery, SearchEntitiesQueryVariables>(
		SearchEntitiesDocument,
		options
	)
}
export function useSearchEntitiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SearchEntitiesQuery, SearchEntitiesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<SearchEntitiesQuery, SearchEntitiesQueryVariables>(
		SearchEntitiesDocument,
		options
	)
}
export type SearchEntitiesQueryHookResult = ReturnType<typeof useSearchEntitiesQuery>
export type SearchEntitiesLazyQueryHookResult = ReturnType<typeof useSearchEntitiesLazyQuery>
export type SearchEntitiesQueryResult = Apollo.QueryResult<
	SearchEntitiesQuery,
	SearchEntitiesQueryVariables
>
export const ReadProductsPreviewDocument = gql`
	query readProductsPreview($limit: Int) {
		products: readProductsPreview(limit: $limit) {
			id
			slug
			title
			imgId
			cost
			unitsPerProduct
			unitType
			isPreOrderable
			merchant {
				id
				slug
				title
			}
		}
	}
`

/**
 * __useReadProductsPreviewQuery__
 *
 * To run a query within a React component, call `useReadProductsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProductsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProductsPreviewQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useReadProductsPreviewQuery(
	baseOptions?: Apollo.QueryHookOptions<ReadProductsPreviewQuery, ReadProductsPreviewQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadProductsPreviewQuery, ReadProductsPreviewQueryVariables>(
		ReadProductsPreviewDocument,
		options
	)
}
export function useReadProductsPreviewLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadProductsPreviewQuery,
		ReadProductsPreviewQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadProductsPreviewQuery, ReadProductsPreviewQueryVariables>(
		ReadProductsPreviewDocument,
		options
	)
}
export type ReadProductsPreviewQueryHookResult = ReturnType<typeof useReadProductsPreviewQuery>
export type ReadProductsPreviewLazyQueryHookResult = ReturnType<
	typeof useReadProductsPreviewLazyQuery
>
export type ReadProductsPreviewQueryResult = Apollo.QueryResult<
	ReadProductsPreviewQuery,
	ReadProductsPreviewQueryVariables
>
export const ReadProductByIdDocument = gql`
	query readProductById($id: ID!, $location: Coordinates) {
		product: readProductById(id: $id) {
			id
			slug
			title
			imgId
			description
			cost
			unitsPerProduct
			unitType
			stock
			isPreOrderable
			reservationTitle
			reservationDescription
			reservationCost
			productionYear
			packaging
			storage
			deliveryCondition
			allergens
			allergensStatus
			certificates
			diets
			merchant(location: $location) {
				id
				slug
				title
				latitude
				longitude
				imgId
				addressLine1
				addressPostalCode
				city
				distanceFromUser {
					distance {
						text
						value
					}
					duration {
						text
						value
					}
				}
				shippingOptions {
					id
					type
					title
					description
					cost
					vatPct
					currency
				}
			}
		}
	}
`

/**
 * __useReadProductByIdQuery__
 *
 * To run a query within a React component, call `useReadProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useReadProductByIdQuery(
	baseOptions: Apollo.QueryHookOptions<ReadProductByIdQuery, ReadProductByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadProductByIdQuery, ReadProductByIdQueryVariables>(
		ReadProductByIdDocument,
		options
	)
}
export function useReadProductByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadProductByIdQuery, ReadProductByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadProductByIdQuery, ReadProductByIdQueryVariables>(
		ReadProductByIdDocument,
		options
	)
}
export type ReadProductByIdQueryHookResult = ReturnType<typeof useReadProductByIdQuery>
export type ReadProductByIdLazyQueryHookResult = ReturnType<typeof useReadProductByIdLazyQuery>
export type ReadProductByIdQueryResult = Apollo.QueryResult<
	ReadProductByIdQuery,
	ReadProductByIdQueryVariables
>
export const ReadProductBySlugDocument = gql`
	query readProductBySlug($slugMerchant: String!, $slugProduct: String!, $location: Coordinates) {
		product: readProductBySlug(slugMerchant: $slugMerchant, slugProduct: $slugProduct) {
			id
			slug
			title
			imgId
			description
			cost
			unitsPerProduct
			unitType
			stock
			isPreOrderable
			reservationTitle
			reservationDescription
			reservationCost
			productionYear
			packaging
			storage
			deliveryCondition
			allergens
			allergensStatus
			certificates
			diets
			merchant(location: $location) {
				id
				slug
				title
				latitude
				longitude
				imgId
				addressLine1
				addressPostalCode
				city
				distanceFromUser {
					distance {
						text
						value
					}
					duration {
						text
						value
					}
				}
				shippingOptions {
					id
					type
					title
					description
					cost
					vatPct
					currency
				}
			}
		}
	}
`

/**
 * __useReadProductBySlugQuery__
 *
 * To run a query within a React component, call `useReadProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProductBySlugQuery({
 *   variables: {
 *      slugMerchant: // value for 'slugMerchant'
 *      slugProduct: // value for 'slugProduct'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useReadProductBySlugQuery(
	baseOptions: Apollo.QueryHookOptions<ReadProductBySlugQuery, ReadProductBySlugQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadProductBySlugQuery, ReadProductBySlugQueryVariables>(
		ReadProductBySlugDocument,
		options
	)
}
export function useReadProductBySlugLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadProductBySlugQuery, ReadProductBySlugQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadProductBySlugQuery, ReadProductBySlugQueryVariables>(
		ReadProductBySlugDocument,
		options
	)
}
export type ReadProductBySlugQueryHookResult = ReturnType<typeof useReadProductBySlugQuery>
export type ReadProductBySlugLazyQueryHookResult = ReturnType<typeof useReadProductBySlugLazyQuery>
export type ReadProductBySlugQueryResult = Apollo.QueryResult<
	ReadProductBySlugQuery,
	ReadProductBySlugQueryVariables
>
export const ReadProductsByMerchantIdDocument = gql`
	query readProductsByMerchantId($merchantId: ID!, $first: Int, $after: String) {
		products: readProductsByMerchantId(merchantId: $merchantId, first: $first, after: $after) {
			edges {
				cursor
				node {
					id
					slug
					title
					imgId
					cost
					unitsPerProduct
					unitType
					isPreOrderable
					reservationTitle
					stock
					certificates
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`

/**
 * __useReadProductsByMerchantIdQuery__
 *
 * To run a query within a React component, call `useReadProductsByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProductsByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProductsByMerchantIdQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useReadProductsByMerchantIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReadProductsByMerchantIdQuery,
		ReadProductsByMerchantIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadProductsByMerchantIdQuery, ReadProductsByMerchantIdQueryVariables>(
		ReadProductsByMerchantIdDocument,
		options
	)
}
export function useReadProductsByMerchantIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadProductsByMerchantIdQuery,
		ReadProductsByMerchantIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadProductsByMerchantIdQuery, ReadProductsByMerchantIdQueryVariables>(
		ReadProductsByMerchantIdDocument,
		options
	)
}
export type ReadProductsByMerchantIdQueryHookResult = ReturnType<
	typeof useReadProductsByMerchantIdQuery
>
export type ReadProductsByMerchantIdLazyQueryHookResult = ReturnType<
	typeof useReadProductsByMerchantIdLazyQuery
>
export type ReadProductsByMerchantIdQueryResult = Apollo.QueryResult<
	ReadProductsByMerchantIdQuery,
	ReadProductsByMerchantIdQueryVariables
>
export const ReadPickupPointByIdDocument = gql`
	query readPickupPointById($id: ID!) {
		pickup: readPickupPointById(id: $id) {
			id
			title
			description
			terminal {
				id
				address
				latitude
				longitude
				boxes {
					temperatureMode
				}
			}
		}
	}
`

/**
 * __useReadPickupPointByIdQuery__
 *
 * To run a query within a React component, call `useReadPickupPointByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPickupPointByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPickupPointByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadPickupPointByIdQuery(
	baseOptions: Apollo.QueryHookOptions<ReadPickupPointByIdQuery, ReadPickupPointByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadPickupPointByIdQuery, ReadPickupPointByIdQueryVariables>(
		ReadPickupPointByIdDocument,
		options
	)
}
export function useReadPickupPointByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadPickupPointByIdQuery,
		ReadPickupPointByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadPickupPointByIdQuery, ReadPickupPointByIdQueryVariables>(
		ReadPickupPointByIdDocument,
		options
	)
}
export type ReadPickupPointByIdQueryHookResult = ReturnType<typeof useReadPickupPointByIdQuery>
export type ReadPickupPointByIdLazyQueryHookResult = ReturnType<
	typeof useReadPickupPointByIdLazyQuery
>
export type ReadPickupPointByIdQueryResult = Apollo.QueryResult<
	ReadPickupPointByIdQuery,
	ReadPickupPointByIdQueryVariables
>
export const ReadPickupPointBySlugDocument = gql`
	query readPickupPointBySlug($slug: String!) {
		pickup: readPickupPointBySlug(slug: $slug) {
			id
			title
			description
			terminal {
				id
				address
				latitude
				longitude
				boxes {
					temperatureMode
				}
			}
		}
	}
`

/**
 * __useReadPickupPointBySlugQuery__
 *
 * To run a query within a React component, call `useReadPickupPointBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPickupPointBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPickupPointBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReadPickupPointBySlugQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReadPickupPointBySlugQuery,
		ReadPickupPointBySlugQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadPickupPointBySlugQuery, ReadPickupPointBySlugQueryVariables>(
		ReadPickupPointBySlugDocument,
		options
	)
}
export function useReadPickupPointBySlugLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadPickupPointBySlugQuery,
		ReadPickupPointBySlugQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadPickupPointBySlugQuery, ReadPickupPointBySlugQueryVariables>(
		ReadPickupPointBySlugDocument,
		options
	)
}
export type ReadPickupPointBySlugQueryHookResult = ReturnType<typeof useReadPickupPointBySlugQuery>
export type ReadPickupPointBySlugLazyQueryHookResult = ReturnType<
	typeof useReadPickupPointBySlugLazyQuery
>
export type ReadPickupPointBySlugQueryResult = Apollo.QueryResult<
	ReadPickupPointBySlugQuery,
	ReadPickupPointBySlugQueryVariables
>
export const ReadPickupPointsListDocument = gql`
	query readPickupPointsList {
		pickups: readPickupPointsList {
			id
			slug
			title
			description
			terminal {
				id
				address
				latitude
				longitude
				boxes {
					temperatureMode
				}
			}
		}
	}
`

/**
 * __useReadPickupPointsListQuery__
 *
 * To run a query within a React component, call `useReadPickupPointsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPickupPointsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPickupPointsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadPickupPointsListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ReadPickupPointsListQuery,
		ReadPickupPointsListQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadPickupPointsListQuery, ReadPickupPointsListQueryVariables>(
		ReadPickupPointsListDocument,
		options
	)
}
export function useReadPickupPointsListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadPickupPointsListQuery,
		ReadPickupPointsListQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadPickupPointsListQuery, ReadPickupPointsListQueryVariables>(
		ReadPickupPointsListDocument,
		options
	)
}
export type ReadPickupPointsListQueryHookResult = ReturnType<typeof useReadPickupPointsListQuery>
export type ReadPickupPointsListLazyQueryHookResult = ReturnType<
	typeof useReadPickupPointsListLazyQuery
>
export type ReadPickupPointsListQueryResult = Apollo.QueryResult<
	ReadPickupPointsListQuery,
	ReadPickupPointsListQueryVariables
>
export const ReadUserDocument = gql`
	query readUser {
		user: readUser {
			id
			slug
			firstName
			nickName
			lastName
			fullName
			birthdate
			profileImage
			email
			phoneNumber
			locale
			roles
			merchant {
				id
				title
				imgId
			}
			createdAt
			updatedAt
		}
	}
`

/**
 * __useReadUserQuery__
 *
 * To run a query within a React component, call `useReadUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUserQuery(
	baseOptions?: Apollo.QueryHookOptions<ReadUserQuery, ReadUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options)
}
export function useReadUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadUserQuery, ReadUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options)
}
export type ReadUserQueryHookResult = ReturnType<typeof useReadUserQuery>
export type ReadUserLazyQueryHookResult = ReturnType<typeof useReadUserLazyQuery>
export type ReadUserQueryResult = Apollo.QueryResult<ReadUserQuery, ReadUserQueryVariables>
export const ReadUserAddressesDocument = gql`
	query readUserAddresses {
		addresses: readUserAddresses {
			id
			firstName
			lastName
			addressLine1
			addressPostalCode
			city
			country
			isDefault
		}
	}
`

/**
 * __useReadUserAddressesQuery__
 *
 * To run a query within a React component, call `useReadUserAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUserAddressesQuery(
	baseOptions?: Apollo.QueryHookOptions<ReadUserAddressesQuery, ReadUserAddressesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadUserAddressesQuery, ReadUserAddressesQueryVariables>(
		ReadUserAddressesDocument,
		options
	)
}
export function useReadUserAddressesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReadUserAddressesQuery, ReadUserAddressesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadUserAddressesQuery, ReadUserAddressesQueryVariables>(
		ReadUserAddressesDocument,
		options
	)
}
export type ReadUserAddressesQueryHookResult = ReturnType<typeof useReadUserAddressesQuery>
export type ReadUserAddressesLazyQueryHookResult = ReturnType<typeof useReadUserAddressesLazyQuery>
export type ReadUserAddressesQueryResult = Apollo.QueryResult<
	ReadUserAddressesQuery,
	ReadUserAddressesQueryVariables
>
export const ReadUserAddressByIdDocument = gql`
	query readUserAddressById($addressId: ID!) {
		address: readUserAddressById(addressId: $addressId) {
			id
			firstName
			lastName
			addressLine1
			addressPostalCode
			city
			country
			isDefault
		}
	}
`

/**
 * __useReadUserAddressByIdQuery__
 *
 * To run a query within a React component, call `useReadUserAddressByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserAddressByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserAddressByIdQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useReadUserAddressByIdQuery(
	baseOptions: Apollo.QueryHookOptions<ReadUserAddressByIdQuery, ReadUserAddressByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReadUserAddressByIdQuery, ReadUserAddressByIdQueryVariables>(
		ReadUserAddressByIdDocument,
		options
	)
}
export function useReadUserAddressByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReadUserAddressByIdQuery,
		ReadUserAddressByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReadUserAddressByIdQuery, ReadUserAddressByIdQueryVariables>(
		ReadUserAddressByIdDocument,
		options
	)
}
export type ReadUserAddressByIdQueryHookResult = ReturnType<typeof useReadUserAddressByIdQuery>
export type ReadUserAddressByIdLazyQueryHookResult = ReturnType<
	typeof useReadUserAddressByIdLazyQuery
>
export type ReadUserAddressByIdQueryResult = Apollo.QueryResult<
	ReadUserAddressByIdQuery,
	ReadUserAddressByIdQueryVariables
>
export const UpdateUserDocument = gql`
	mutation updateUser($input: UserUpdateInput!) {
		user: updateUser(input: $input) {
			id
			slug
			firstName
			nickName
			lastName
			fullName
			birthdate
			profileImage
			email
			phoneNumber
			locale
			roles
			merchant {
				id
				title
			}
			createdAt
			updatedAt
		}
	}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
	UpdateUserMutation,
	UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
		UpdateUserDocument,
		options
	)
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserMutation,
	UpdateUserMutationVariables
>
export const CreateUserAddressDocument = gql`
	mutation createUserAddress($address: UserAddressCreateInput!) {
		addresses: createUserAddress(address: $address) {
			id
			firstName
			lastName
			addressLine1
			addressPostalCode
			city
			country
			isDefault
		}
	}
`
export type CreateUserAddressMutationFn = Apollo.MutationFunction<
	CreateUserAddressMutation,
	CreateUserAddressMutationVariables
>

/**
 * __useCreateUserAddressMutation__
 *
 * To run a mutation, you first call `useCreateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAddressMutation, { data, loading, error }] = useCreateUserAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateUserAddressMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateUserAddressMutation,
		CreateUserAddressMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateUserAddressMutation, CreateUserAddressMutationVariables>(
		CreateUserAddressDocument,
		options
	)
}
export type CreateUserAddressMutationHookResult = ReturnType<typeof useCreateUserAddressMutation>
export type CreateUserAddressMutationResult = Apollo.MutationResult<CreateUserAddressMutation>
export type CreateUserAddressMutationOptions = Apollo.BaseMutationOptions<
	CreateUserAddressMutation,
	CreateUserAddressMutationVariables
>
export const UpdateUserAddressDocument = gql`
	mutation updateUserAddress($address: UserAddressUpdateInput!) {
		addresses: updateUserAddress(address: $address) {
			id
			firstName
			lastName
			addressLine1
			addressPostalCode
			city
			country
			isDefault
		}
	}
`
export type UpdateUserAddressMutationFn = Apollo.MutationFunction<
	UpdateUserAddressMutation,
	UpdateUserAddressMutationVariables
>

/**
 * __useUpdateUserAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAddressMutation, { data, loading, error }] = useUpdateUserAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateUserAddressMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateUserAddressMutation,
		UpdateUserAddressMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>(
		UpdateUserAddressDocument,
		options
	)
}
export type UpdateUserAddressMutationHookResult = ReturnType<typeof useUpdateUserAddressMutation>
export type UpdateUserAddressMutationResult = Apollo.MutationResult<UpdateUserAddressMutation>
export type UpdateUserAddressMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserAddressMutation,
	UpdateUserAddressMutationVariables
>
export const DeleteUserAddressDocument = gql`
	mutation deleteUserAddress($addressId: ID!) {
		addresses: deleteUserAddress(addressId: $addressId) {
			id
			firstName
			lastName
			addressLine1
			addressPostalCode
			city
			country
			isDefault
		}
	}
`
export type DeleteUserAddressMutationFn = Apollo.MutationFunction<
	DeleteUserAddressMutation,
	DeleteUserAddressMutationVariables
>

/**
 * __useDeleteUserAddressMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddressMutation, { data, loading, error }] = useDeleteUserAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteUserAddressMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteUserAddressMutation,
		DeleteUserAddressMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>(
		DeleteUserAddressDocument,
		options
	)
}
export type DeleteUserAddressMutationHookResult = ReturnType<typeof useDeleteUserAddressMutation>
export type DeleteUserAddressMutationResult = Apollo.MutationResult<DeleteUserAddressMutation>
export type DeleteUserAddressMutationOptions = Apollo.BaseMutationOptions<
	DeleteUserAddressMutation,
	DeleteUserAddressMutationVariables
>
