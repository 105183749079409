import { Action, Profile } from './context'

export const initialState: Profile = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	img: null,
	admin: null,
	birthdate: null,
}

export const reducer = (state: Profile, action: Action) => {
	switch (action.type) {
		case 'update':
			return action.value
		case 'logout':
			return initialState
		default:
			return initialState
	}
}
