import { useRef, useState } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'
import { Avatar } from '@mui/material'
import theme from '../../styles/theme'

interface IProps {
	latitude: number
	longitude: number
}

interface Coordinates {
	latitude: number
	longitude: number
	zoom: number
}

export const ShippingOptionLocationMap = ({ latitude, longitude }: IProps) => {
	const mapRef = useRef(null)
	const [viewport, setViewport] = useState<Coordinates>({
		latitude: latitude,
		longitude: longitude,
		zoom: 14,
	})

	const onViewportChange = (args) => {
		const { latitude, longitude, zoom } = args
		setViewport({
			latitude,
			longitude,
			zoom,
		})
	}

	return (
		<ReactMapGL
			mapStyle="mapbox://styles/mapbox/light-v10"
			mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
			width="100%"
			height="300px"
			onViewportChange={onViewportChange}
			ref={mapRef}
			{...viewport}
		>
			<Marker latitude={latitude} longitude={longitude} offsetLeft={-10} offsetTop={-10}>
				<Avatar sx={{ width: 80, height: 80, padding: 1.2, bgcolor: theme.colors.deepGreen80 }}>
					<img src={'/static/icons/pickup_white.png'} alt="Henteskap" />
				</Avatar>
			</Marker>
		</ReactMapGL>
	)
}
