import React from 'react'

const White = () => {
	return (
		<svg width="88" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.6 88.6">
			<path
				d="M19.1 80.4l-15 4.3v.1l14.9.3-.1 3.5L0 88.2l.1-5.9L14 78.5v-.1L.3 74.1l.1-5.8 18.9.4-.1 3.8-14.8-.3v.1l14.8 4.8-.1 3.3zM20.3 55c.7.2 1.1-.1 1.2-.6.1-.3.1-.7.1-1.1l1.8.5c.3.7.5 1.5.1 2.6-.4 1.4-1.5 2.1-3 2 1.3 1.2 1.5 3.1 1 5-.8 2.7-2.6 3.9-4.8 3.2-2.4-.7-2.8-3-2.6-6l.3-3.7-.3-.1c-1.4-.4-2.5.1-2.9 1.5-.4 1.4.2 2.5 1.4 3.1l-1.2 3c-2.5-1.2-3.7-3.7-2.7-7.1 1-3.3 3.2-5 6.5-4l5.1 1.7zm-3.7 2.6l-.2 2.6c-.1 1.6-.2 2.7 1.1 3.1.9.2 1.6-.3 1.9-1.4.6-1.8-.4-3.6-2.5-4.2l-.3-.1zm-1.4-9.4l-.8 1.7-2-.9.3-.9c.4-1.3 0-1.9-1-2.7l-1.4-1.2 1-2 3.3 1.6 1.6-3.2 2.2 1.1-1.6 3.3 5.9 2.9c1 .5 1.7.3 2.2-.7.3-.7.4-1.4.5-2.3l2.1 1.1c.2 1.2.1 2.3-.6 3.8-.9 1.8-2.5 3-5 1.7l-6.7-3.3zm4-7.9l-2.1-1.4 1.1-1.6-.9-.6c-2.5-1.7-3-4.3-1.3-6.8 1.4-2 3.1-2.6 5.2-1.9l-.7 2.3c-.9-.2-1.7-.1-2.2.7-.6.9-.2 1.8.8 2.5l1.2.8 2-2.9 2.1 1.4-2 2.9 8.9 6-2 3-8.9-6-1.2 1.6zm13.3-15.2c-.7.3-1.3.6-1.9 1.2-1.2 1.4-1.1 3.2.6 4.8l4.9 4.4-2.4 2.7-9.8-8.9 2.4-2.7 1.9 1.7c-.8-1.4-.9-3.3.3-4.7.6-.6 1.1-.9 1.6-1l2.4 2.5zm14.9-3.6c.4.6.9.6 1.4.2.3-.2.5-.5.7-.9l1.2 1.5c-.2.7-.5 1.5-1.5 2.2-1.2.9-2.5.8-3.6-.1.3 1.7-.6 3.4-2.2 4.6-2.2 1.7-4.4 1.6-5.8-.2-1.5-2-.6-4.1 1.5-6.4l2.4-2.8-.2-.2c-.9-1.2-2.1-1.4-3.3-.5-1.2.9-1.3 2.1-.7 3.3L34.6 24c-1.3-2.4-.8-5.2 2-7.3 2.7-2.1 5.5-2.2 7.6.6l3.2 4.2zm-4.5-.2l-1.6 1.9c-1 1.2-1.8 2.1-.9 3.2.6.7 1.4.7 2.3 0 1.5-1.2 1.8-3.2.5-4.9l-.3-.2zm16.9-3c-2.2.9-4.7.1-5.7-2.3-1-2.3.1-4.7 2.3-5.7 2.2-.9 4.7-.1 5.7 2.3s-.1 4.7-2.3 5.7zM73 14.6l-3.6.5-2-13.1 3.6-.5.3 2.2c.4-1.6 1.9-2.9 3.9-3.1 3.5-.5 5.1 1.7 5.6 4.6l1.2 8.2-3.6.5-1.1-7.5c-.3-2-1.3-3.1-3-2.8-1.8.3-2.7 1.9-2.4 4.1l1.1 6.9zm17.1-.6c-4.1-.3-6.8-3.3-6.5-7.4.3-4.2 3.3-6.8 7.4-6.6 4.1.3 6.8 3.4 6.5 7.4-.2 4.2-3.3 6.8-7.4 6.6zm.2-2.7c2.2.1 3.5-1.5 3.7-4 .2-2.5-.8-4.3-3.1-4.5-2.2-.1-3.5 1.5-3.6 4-.3 2.5.8 4.3 3 4.5z"
				fill="#fff"
			/>
		</svg>
	)
}

export default White
