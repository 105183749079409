import styled from 'styled-components'
import { Box } from '@mui/material'

export const Container = styled(Box)`
	max-width: 1500px;
	margin-left: auto !important;
	margin-right: auto !important;
	padding-left: 16px;
	padding-right: 16px;
	@media screen and (min-width: 52em) {
		padding-left: 35px;
		padding-right: 35px;
	}
	@media screen and (min-width: 64em) {
		padding-left: 48px;
		padding-right: 48px;
	}
`

export const Row = styled(Box)`
	display: flex;
`

export const Col = styled(Box)`
	padding-left: 8px;
	padding-right: 8px;
	@media screen and (min-width: 52em) {
		padding-left: 16px;
		padding-right: 16px;
	}
`
