import React, { Context, createContext, useMemo, useReducer } from 'react'
import { initialState, reducer } from './reducer'
import { IProductFilterContext } from './context'
import { isEqual } from 'lodash'

const ProductFilterContext: Context<IProductFilterContext> = createContext(
	{} as IProductFilterContext
)

const ProductFilterProvider: React.FC = ({ children }) => {
	const [filter, dispatch] = useReducer(reducer, initialState)
	const isFilterDefault: boolean = useMemo(() => {
		return isEqual(filter, initialState)
	}, [filter, initialState])

	return (
		<ProductFilterContext.Provider
			value={{
				filter,
				defaultFilter: initialState,
				isFilterDefault,
				dispatch,
			}}
		>
			{children}
		</ProductFilterContext.Provider>
	)
}

export { ProductFilterProvider, ProductFilterContext }
