import { createContext, Dispatch } from 'react'
import Cart from '../../pages/ordre/handlekurv'
import { Action } from './reducer'

export type StoreData = {
	activeSidebar: boolean
	activeMobileMenu: boolean
	productView: ProductView
	// User Location
	location?: Location
	// Cart line items
	cart: Cart
	// Order ID
	order: Order
	// GDPR
	gdpr: GdprPreferences
}

export type GdprPreferences = {
	isEssentialAccepted: boolean
	// TODO: More cookies preferences (Google, Facebook Pixel, etc.)
}

type MerchantId = string
type ProductId = string
type ShippingOptionId = string

// Cart

export type Cart = {
	lines: Record<MerchantId, Record<ProductId, LineItem>>
	countUniqueProducts: number
	countUniqueMerchants: number
	countProducts: number
}

// TODO: To move all this helpers somewhere (store/helper.ts ?)
export const getMerchantIdsFromCart = (c: Cart): Array<MerchantId> => {
	return Object.keys(c.lines)
}

export const isCartEmpty = (c: Cart): boolean => {
	return c.countUniqueMerchants === 0
}

export const getProductIdsByMerchant = (cart: Cart, merchantId: string): Array<string> => {
	if (cart.lines[merchantId]) {
		return Object.keys(cart.lines[merchantId])
	}
	return []
}

export const getCartLineItem = (
	cart: Cart,
	merchantId: string,
	productId: string
): LineItem | null => {
	if (cart.lines[merchantId] && cart.lines[merchantId][productId]) {
		return cart.lines[merchantId][productId]
	}
	return null
}

export const getCartTotal = (cart: Cart) => {
	let total = 0
	Object.keys(cart.lines).forEach((merchantId) => {
		total += Object.keys(cart.lines[merchantId])
			.map((productId) => cart.lines[merchantId][productId])
			.reduce((acc: number, { price, quantity }) => {
				return acc + price * quantity
			}, 0)
	})
	return total
}

type LineItem = {
	quantity: number
	price: number
}

// Order

export type Order = {
	id?: string
	shippingOptions: Record<MerchantId, ShippingOptionId>
	comments: Record<MerchantId, string>
	giftCard?: string
}

export type Location = {
	latitude: number
	longitude: number
	address: string
	googlePlacesValue: any
}

export enum ProductView {
	List = 'list',
	Map = 'map',
}

const initialState: StoreData = {
	activeSidebar: false,
	activeMobileMenu: false,
	productView: ProductView.List,
	cart: {
		countUniqueProducts: 0,
		countUniqueMerchants: 0,
		countProducts: 0,
		lines: {},
	},
	order: {
		comments: {},
		shippingOptions: {},
	},
	gdpr: {
		isEssentialAccepted: false,
	},
}

export const AppContext = createContext<{
	state: StoreData
	dispatch: Dispatch<Action>
}>({
	state: initialState,
	dispatch: () => null,
})
