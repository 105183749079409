import React from 'react'

interface IProps {
	value?: number
}

const Price = ({ value }: IProps) => {
	if (value === undefined) {
		return <></>
	}
	return (
		<>{Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(value / 100)}</>
	)
}

export default Price
