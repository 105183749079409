import React from 'react'
import NextLink from 'next/link'
import { Link, Box, Flex } from 'rebass/styled-components'
import { Col } from '../Grid'
import { Text5 } from '../Typography'
import { White as WhiteLogo } from '../Logo'
import ArrowLink from '../ArrowLink'
import { Container } from '@mui/material'

const Footer = () => {
	return (
		<Box as="footer" bg="deepGreen100" mt={4}>
			<Container sx={{ p: 5 }}>
				<Flex mb={[5, 5, 5, 0]} flexWrap="wrap">
					<Col width={[1, 1, 1, 3 / 12]}>
						<Text5 as="b" color="white" textAlign="left">
							Kontakt oss
						</Text5>
						<Link color="white" variant="underlineReverse" href={`mailto:kontakt@matfra.no`}>
							<Text5 fontWeight="regular" textAlign="left" mb={2}>
								kontakt@matfra.no
							</Text5>
						</Link>
						<Text5 as="b" color="white" display="block" textAlign="left">
							Følg oss på
						</Text5>
						<ArrowLink rel="noopener" color="white" href="https://www.facebook.com/ekteogdirekte">
							<Text5 color="white" fontWeight="regular" textAlign="left">
								Facebook
							</Text5>
						</ArrowLink>
						<ArrowLink rel="noopener" color="white" href="https://www.instagram.com/matfra.no">
							<Text5 color="white" fontWeight="regular" textAlign="left">
								Instagram
							</Text5>
						</ArrowLink>
					</Col>
					<Col mt={[4, 4, 4, 0]} width={[1, 1, 1, 3 / 12]}>
						<NextLink href={`/produkter`} passHref>
							<Link color="white" variant="underlineReverse">
								<Text5 fontWeight="regular" textAlign="left">
									Produkter
								</Text5>
							</Link>
						</NextLink>
						<NextLink href={`/bli-produsent`} passHref>
							<Link color="white" variant="underlineReverse">
								<Text5 fontWeight="regular" textAlign="left">
									Selg lokalt
								</Text5>
							</Link>
						</NextLink>
						<NextLink href={`/om-oss`} passHref>
							<Link color="white" variant="underlineReverse">
								<Text5 fontWeight="regular" textAlign="left">
									Om oss
								</Text5>
							</Link>
						</NextLink>
						{/*<NextLink href={`/faq`} passHref>*/}
						{/*  <Link color="white" variant="underlineReverse">*/}
						{/*    <Text5 fontWeight="regular" textAlign="left">*/}
						{/*      Ofte stilte spørsmål*/}
						{/*    </Text5>*/}
						{/*  </Link>*/}
						{/*</NextLink>*/}
						<NextLink href={`/betingelser`} passHref>
							<Link color="white" variant="underlineReverse">
								<Text5 fontWeight="regular" textAlign="left">
									Betingelser
								</Text5>
							</Link>
						</NextLink>
						<NextLink href={`/personvern`} passHref>
							<Link color="white" variant="underlineReverse">
								<Text5 fontWeight="regular" textAlign="left">
									Personvernerklæring
								</Text5>
							</Link>
						</NextLink>
					</Col>
					<Col
						mt={[5, 5, 5, 0]}
						width={[1, 1, 1, 6 / 12]}
						sx={{
							textAlign: 'right',
							'@media screen and (max-width: 64em)': {
								textAlign: 'left',
							},
						}}
					>
						<Link
							rel="noopener"
							href="https://matfra.us20.list-manage.com/subscribe?u=585507429ece56af935eca19d&id=23f1e7fc79"
							target="_blank"
						>
							<Text5 as="b" color="white">
								Meld deg på vårt nyhetsbrev
							</Text5>
						</Link>
					</Col>
				</Flex>
				<Col width={[1]} textAlign="right">
					<NextLink href={`/`} passHref>
						<Link style={{ display: 'block' }}>
							<WhiteLogo />
						</Link>
					</NextLink>
				</Col>
			</Container>
		</Box>
	)
}

export default Footer
