import { Action, ProductFilter } from './context'

export const initialState: ProductFilter = {
	allergens: [],
	certificates: [],
	diets: [],
	categoryId: null,
	pickupId: null,
	searchTerm: '',
	merchantDistance: 100,
}

export const reducer = (state: ProductFilter, action: Action) => {
	switch (action.type) {
		case 'update':
			return action.value
		case 'reset':
			return initialState
		default:
			return initialState
	}
}
