import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import Head from 'next/head'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Box, Button, Flex } from 'rebass/styled-components'
import styled from 'styled-components'
import ArrowLink from '../../components/ArrowLink'
import { Col, Row } from '../../components/Grid'
import { Alert, Chip, Container, NoSsr, Input, InputLabel, InputAdornment } from '@mui/material'
import Price from '../../components/Price'
import { Text2, Text5, Text7 } from '../../components/Typography'
import {
	AppContext,
	getCartTotal,
	getMerchantIdsFromCart,
	getProductIdsByMerchant,
	isCartEmpty,
} from '../../context/store/context'
import { MerchantLineWithShippingOption } from '../../components/CartSidebar/MerchantLineWithShippingOption'
import BreadcrumbOrder from '../../components/Breadcrumb/BreadcrumbOrder'
import { useCheckCartLazyQuery, useGiftCardCheckLazyQuery } from '../../apis/generated/graphql'
import { CardGiftcard } from '@mui/icons-material'
import { useDebounce } from '../../hooks/useDebounce'
import { ProfileContext } from '../../context/profile/provider'
import { frontendUrl } from '../../lib/url'

const Cart = () => {
	const router = useRouter()

	const {
		state: { cart },
		dispatch,
	} = useContext(AppContext)

	const { isLogged, login } = useContext(ProfileContext)

	const [checkCart] = useCheckCartLazyQuery()
	const [checkGiftCard, { data: dataCheckGiftCard, error: errorCheckGiftCard }] =
		useGiftCardCheckLazyQuery()

	const [problems, setProblems] = useState<string[]>([])
	const [giftCard, setGiftCard] = useState<string>('')
	const debouncedGiftCard = useDebounce(giftCard, 500)

	useEffect(() => {
		if (debouncedGiftCard) {
			checkGiftCard({
				variables: {
					code: debouncedGiftCard,
				},
			})
		}
	}, [debouncedGiftCard])

	const getLines = (values) => {
		return getMerchantIdsFromCart(cart).map((merchantId) => {
			const productsIds = getProductIdsByMerchant(cart, merchantId)
			return (
				<MerchantLineWithShippingOption
					key={merchantId}
					merchantId={merchantId}
					products={productsIds}
					values={values}
				/>
			)
		})
	}

	const getShippingCosts = (values) => {
		if (Object.keys(values.shippingOptions).length) {
			const shippingTotal = Object.keys(values.shippingOptions).reduce((curr, key) => {
				const item = JSON.parse(values.shippingOptions[key])
				return item.cost + curr
			}, 0)
			return parseInt(shippingTotal)
		}
		return 0
	}

	const merchantsTotalPrice = getCartTotal(cart)

	const getFinalPriceWithShipping = (values) => {
		let total = merchantsTotalPrice + getShippingCosts(values)
		if (dataCheckGiftCard) {
			total -= dataCheckGiftCard.check.availableAmount
		}
		return total >= 0 ? total : 0
	}

	const allShippingOptionsChecked = (values) => {
		const countShippingOptions = Object.keys(values.shippingOptions).length
		if (countShippingOptions) {
			return countShippingOptions === cart.countUniqueMerchants
		}
		return false
	}

	const onSubmit = async (values, { setSubmitting, setStatus }) => {
		try {
			dispatch({
				type: 'UPDATE_ORDER',
				payload: {
					giftCard: dataCheckGiftCard?.check.id,
					shippingOptions: Object.keys(values.shippingOptions).map((merchantId) => ({
						merchantId,
						shippingOptionId: JSON.parse(values.shippingOptions[merchantId]).id,
						comments: values.comments[merchantId],
					})),
				},
			})

			// Prepare data for check
			const checkInputs = getMerchantIdsFromCart(cart).map((merchantId) => {
				return {
					merchantId,
					shippingOption: JSON.parse(values.shippingOptions[merchantId]).id,
					productsIds: getProductIdsByMerchant(cart, merchantId),
				}
			})

			// Check cart before order
			const { data: dataCheck } = await checkCart({
				variables: {
					input: checkInputs,
				},
			})

			// Get possible check problems
			const checkProblems = dataCheck.control.map((c) => c.problems).flat()
			if (checkProblems.length > 0) {
				setProblems(checkProblems)
			} else {
				if (isLogged) {
					await router.push('/ordre/personopplysninger')
				} else {
					await login({
						redirectUri: `${frontendUrl}/ordre/personopplysninger`,
					})
				}
				window?.scrollTo(0, 0)
			}
		} catch (error) {
			console.debug(error)
			setStatus(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<NoSsr>
			<Head>
				<title>Matfra.no – Handlekurv - Ferske råvarer, direkte fra din lokale bonde</title>
				<meta
					name="description"
					content="Utforsk lokale produsenter og varer, og betal på nett. Hent dine varer ferdig pakket hos din lokale bonde. Spis med god samvittighet!"
				/>
			</Head>
			<Container>
				<BreadcrumbOrder />
				<Row justifyContent="center">
					<Col width={[1, 1, 10 / 12, 10 / 12]}>
						<Text2 textAlign="center" mb={[2, 3]} fontWeight="semibold">
							Handlekurv
						</Text2>
						{cart && (
							<Text5 textAlign="center" mb={[3, 5]}>
								Bestillingen inneholder <strong>{`${cart.countUniqueProducts} varer`}</strong> fra{' '}
								<strong>{`${cart.countUniqueMerchants} produsenter`}</strong>
							</Text5>
						)}
						<Flex flexDirection="column" justifyContent="space-between">
							<Formik
								initialValues={{
									comments: {},
									shippingOptions: {},
								}}
								validate={(values) => {
									const merchantIds = getMerchantIdsFromCart(cart)
									const shippingOptionsArray = Object.keys(values.shippingOptions).map((key) => {
										merchantIds.find((id) => id === key)
									})
									if (merchantIds.length !== shippingOptionsArray.length) {
										return {
											shippingOptions:
												'Du må velge hente-/leveringsalternativ for alle produsenter',
										}
									}
									return undefined
								}}
								// initialErrors={{
								//   shippingOptions: 'Du må velge hente-/leveringsalternativ for alle produsenter',
								// }}
								onSubmit={onSubmit}
							>
								{({ values, isValid, isSubmitting, status }) => {
									return (
										<Form>
											<Flex flexWrap="wrap" justifyContent="space-between">
												<Box width={[1, 1, 7 / 12, 7 / 12]}>
													{isCartEmpty(cart) ? (
														<Text5>Din handlekurv er tom</Text5>
													) : (
														getLines(values)
													)}
												</Box>

												{/* CART OVERVIEW */}
												{merchantsTotalPrice > 0 && (
													<Box
														width={[1, 1, 4 / 12, 4 / 12]}
														sx={{
															height: '100%',
															position: 'sticky',
															top: '40px',
															border: '1px solid #E6EDED',
															borderRadius: '5px',
														}}
													>
														<Flex
															justifyContent="space-between"
															alignItems="center"
															bg="deepGreen10"
															px={3}
															py={2}
															mb={3}
															height="48px"
														>
															<Text7 fontWeight="semibold">Totalsum</Text7>
														</Flex>
														<Flex justifyContent="space-between" alignItems="center" px={3}>
															<Text7 color="gray70">Bestilling</Text7>
															<Text7 fontWeight="regular" color="gray70">
																<Price value={merchantsTotalPrice} />
															</Text7>
														</Flex>
														<Flex justifyContent="space-between" alignItems="center" px={3}>
															<Text7 color="gray70">Frakt</Text7>
															<Text7 fontWeight="regular" color="gray70">
																<Price value={getShippingCosts(values)} />
															</Text7>
														</Flex>

														{dataCheckGiftCard?.check.availableAmount && (
															<Flex
																justifyContent="space-between"
																alignItems="center"
																px={3}
																mb={3}
															>
																<Text7 color="gray70">Gavekort</Text7>
																<Text7 fontWeight="regular" color="gray70">
																	-
																	<Price value={dataCheckGiftCard.check.availableAmount} />
																</Text7>
															</Flex>
														)}

														<Flex
															justifyContent="space-between"
															alignItems="center"
															mx={3}
															py={2}
															mb={3}
															sx={{
																borderTop: '1px solid #949494',
																borderBottom: '1px solid #949494',
															}}
														>
															<Text7 fontWeight="semibold">Total</Text7>
															<Text7 fontWeight="regular">
																<Price value={getFinalPriceWithShipping(values)} />
															</Text7>
														</Flex>

														<Box mx={3} mb={3}>
															<InputLabel htmlFor="input-giftcard">Gavekort</InputLabel>
															<Input
																id="input-giftcard"
																placeholder="Har du et gavekort?"
																value={giftCard}
																onChange={(e) => {
																	setGiftCard(e.currentTarget.value)
																}}
																fullWidth
																startAdornment={
																	<InputAdornment position="start">
																		<CardGiftcard />
																	</InputAdornment>
																}
																endAdornment={
																	<InputAdornment position="end">
																		{dataCheckGiftCard && (
																			<Chip
																				size="small"
																				label={
																					<React.Fragment>
																						<Price
																							value={dataCheckGiftCard.check.availableAmount}
																						/>
																					</React.Fragment>
																				}
																			/>
																		)}
																	</InputAdornment>
																}
															/>

															{errorCheckGiftCard && <Text5>{errorCheckGiftCard.message}</Text5>}
														</Box>

														<Box mx={3}>
															{status && (
																<Alert severity={'error'} sx={{ marginBottom: 2 }}>
																	{status}
																</Alert>
															)}

															{problems.length > 0 &&
																problems.map((p, i) => (
																	<Alert
																		key={i}
																		severity={'error'}
																		sx={{
																			alignItems: 'center',
																			marginBottom: 2,
																		}}
																	>
																		{p}
																	</Alert>
																))}

															{!allShippingOptionsChecked(values) && (
																<Alert severity={'warning'} sx={{ marginBottom: 2 }}>
																	Husk! Du må velge hente eller leveringsalternativ for alle
																	produsenter.
																</Alert>
															)}

															<SubmitButton
																type="submit"
																disabled={!merchantsTotalPrice || !isValid || isSubmitting}
																variant="solid"
																width={1}
															>
																Gå til kontaktinformasjon
															</SubmitButton>

															<NextLink href="/produkter" passHref>
																<ArrowLink my="4" backwards justifyContent="center">
																	<Text7>Fortsett å handle</Text7>
																</ArrowLink>
															</NextLink>

															{/*<ErrorMessage name="shippingOptions">*/}
															{/*  {(msg) => (*/}
															{/*    <Text7 textAlign="center" color="red">*/}
															{/*      {msg}*/}
															{/*    </Text7>*/}
															{/*  )}*/}
															{/*</ErrorMessage>*/}
														</Box>
													</Box>
												)}
											</Flex>
										</Form>
									)
								}}
							</Formik>
						</Flex>
					</Col>
				</Row>
			</Container>
		</NoSsr>
	)
}

const SubmitButton = styled(Button)`
	opacity: ${(props) => (props.disabled ? 0.2 : 1)};

	:hover {
		background: ${(props) => (props.disabled ? '#27AE60' : '#52BE80')};
		cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	}
`

export default Cart
