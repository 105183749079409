import theme from './theme'

export const muiThemeProps = {
	palette: {
		primary: {
			main: theme.colors.primary,
			'100': theme.colors.green100,
		},
		secondary: {
			main: theme.colors.deepGreen100,
			'700': theme.colors.deepGreen70,
			'300': theme.colors.deepGreen30,
			'200': theme.colors.deepGreen20,
			'100': theme.colors.deepGreen10,
		},
		warning: {
			main: theme.colors.yellow50,
		},
		error: {
			main: theme.colors.red40,
		},
		background: {
			paper: theme.colors.deepGreen10,
		},
	},
}
