import React from 'react'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'

const StyledRow = styled(Flex)`
	justify-content: center;
	@media screen and (max-width: 40em) {
		display: none;
	}
`

const OrderSteps = ({ children }) => {
	return <StyledRow mb={4}>{children}</StyledRow>
}

export default OrderSteps
