import React from 'react'
import styled from 'styled-components'
import NextLink from 'next/link'
import { Link } from 'rebass/styled-components'
import { Col } from '../Grid'
import { Text3 } from '../Typography'
import { White as WhiteLogo } from '../Logo'
import { Container, Box, Badge } from '@mui/material'

interface IMenuWrapperProps {
	active: string // TODO: Transform all the active properties from string to type ('open' / 'closed')
}

const MenuWrapper = styled(Box)<IMenuWrapperProps>`
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors.deepGreen100};
	left: 0;
	top: 0;
	z-index: 3;
	transition:
		opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out;
	opacity: ${(props) => (props.active === 'closed' ? 0 : 1)};
	visibility: ${(props) => (props.active === 'closed' ? 'hidden' : 'visible')};
`

const Inner = styled(Box)`
	position: relative;
	height: 100%;
`

const MobileMenu = (props) => {
	const { setActiveMobileMenu, active } = props
	const isOpen = active ? 'open' : 'closed'

	const onClickMenu = () => {
		setActiveMobileMenu(false)
	}

	return (
		<MenuWrapper active={isOpen}>
			<Container>
				<Inner mt={15}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							padding: 1,
							gap: 2,
						}}
					>
						<NextLink href={`/produkter`} passHref>
							<Link onClick={onClickMenu}>
								<Text3 color="white">Produkter</Text3>
							</Link>
						</NextLink>
						<NextLink href={`/henteskap`} passHref>
							<Link onClick={onClickMenu}>
								<Badge color="primary" badgeContent={'Nytt!'}>
									<Text3 color="white">Henteskap</Text3>
								</Badge>
							</Link>
						</NextLink>
						<NextLink href={`/om-oss`} passHref>
							<Link onClick={onClickMenu}>
								<Text3 color="white">Om oss</Text3>
							</Link>
						</NextLink>
						<NextLink href={`/bli-produsent`} passHref>
							<Link onClick={onClickMenu}>
								<Text3 color="white">Selg lokalt</Text3>
							</Link>
						</NextLink>
						<Link
							as="a"
							target="_blank"
							rel="noreferrer noopener"
							color="white"
							href="https://blogg.matfra.no"
							onClick={onClickMenu}
						>
							<Text3 color="white" fontWeight="regular" textAlign="left">
								Blogg
							</Text3>
						</Link>
						<NextLink href={`/profil`} passHref>
							<Link onClick={onClickMenu}>
								<Text3 color="white">Min Matfra</Text3>
							</Link>
						</NextLink>
					</Box>
				</Inner>

				<Col textAlign="right" style={{ position: 'absolute', bottom: 20, right: 20 }}>
					<NextLink href={`/`} passHref>
						<Link onClick={onClickMenu} style={{ display: 'block' }}>
							<WhiteLogo />
						</Link>
					</NextLink>
				</Col>
			</Container>
		</MenuWrapper>
	)
}

export default MobileMenu
