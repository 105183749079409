import React, { Context, createContext, useEffect, useReducer } from 'react'
import { IProfileContext } from './context'
import { initialState, reducer } from './reducer'
import { useAuth0 } from '@auth0/auth0-react'
import { Role, useReadUserLazyQuery } from '../../apis/generated/graphql'
import { frontendUrl } from '../../lib/url'

const ProfileContext: Context<IProfileContext> = createContext({} as IProfileContext)

const ProfileProvider: React.FC = ({ children }) => {
	const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
	const [profile, dispatch] = useReducer(reducer, initialState)
	const [readProfile, { data, loading }] = useReadUserLazyQuery()

	useEffect(() => {
		if (isAuthenticated) {
			readProfile()
		}
	}, [isAuthenticated])

	useEffect(() => {
		if (isAuthenticated && data) {
			dispatch({
				type: 'update',
				value: {
					id: data.user.id,
					firstName: data.user.firstName,
					lastName: data.user.lastName,
					birthdate: data.user.birthdate,
					img: data.user.profileImage,
					email: data.user.email,
					phoneNumber: data.user.phoneNumber,
					admin:
						data.user.roles.find((r) => r === Role.Merchant) && data.user.merchant
							? {
									name: data.user.merchant.title,
									img: data.user.merchant.imgId,
								}
							: undefined,
				},
			})
		}
	}, [isAuthenticated, data])

	return (
		<ProfileContext.Provider
			value={{
				profile,
				isLogged: isAuthenticated && profile.id !== null,
				isLoading: isLoading || loading,
				login: loginWithRedirect,
				logout: () => {
					logout({ returnTo: frontendUrl })
				},
				dispatch,
			}}
		>
			{children}
		</ProfileContext.Provider>
	)
}

export { ProfileProvider, ProfileContext }
