import React, { useContext, useMemo } from 'react'
import { Avatar, Badge, Box, Container } from '@mui/material'
import styled from 'styled-components'
import { Button as LegacyButton, Flex, Link } from 'rebass/styled-components'
import { NoSsr } from '@mui/base'
import { Text5 } from '../Typography'
import NextLink from 'next/link'
import { Col } from '../Grid'
import CartSidebar from '../CartSidebar'
import { Default as DefaultLogo } from '../Logo'
import Hamburger from './Hamburger'
import { AppContext } from '../../context/store/context'
import { ProfileContext } from '../../context/profile/provider'
import { Loading } from '../Loading'

const Logo = () => {
	return (
		<Flex justifyContent="center">
			<NextLink href={`/`} passHref>
				<Link>
					<DefaultLogo color1="#084646" color2="#068751" color3="#084646" />
				</Link>
			</NextLink>
		</Flex>
	)
}

const Navbar = (props) => {
	const { state, dispatch } = useContext(AppContext)
	const { isLogged, isLoading, profile, login } = useContext(ProfileContext)
	const { cart, activeSidebar } = state

	const setActiveSidebar = (active: boolean) => {
		dispatch({ type: 'TOGGLE_SIDEBAR', payload: active })
	}

	const customerProfile = useMemo(() => {
		return (
			<Box
				pl={1}
				ml={3}
				sx={{
					display: {
						xs: 'none',
						md: 'flex',
					},
				}}
			>
				<NextLink href={`/profil`} passHref>
					<Link variant={'link'}>
						<Avatar alt={profile.firstName} src={profile.img}>
							{/* Null value is required to have the first letter used inside the avatar. */}
							{isLoading ? <Loading /> : null}
						</Avatar>
					</Link>
				</NextLink>
			</Box>
		)
	}, [isLoading, isLogged, profile, login])

	return (
		<NavbarWrapper alignItems="center" justifyContent="center">
			<Container>
				<Flex alignItems="center" justifyContent="space-between">
					<Col
						sx={{
							display: {
								xs: 'block',
								lg: 'none',
							},
						}}
					>
						<Box>
							<Hamburger />
						</Box>
					</Col>

					<Col>
						<Box width={150}>
							<Logo />
						</Box>
					</Col>

					<Col>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								alignItems: 'center',
								gap: 2,
							}}
						>
							<Box
								sx={{
									display: {
										xs: 'none',
										lg: 'flex',
									},
									flexDirection: 'row',
									flexWrap: 'wrap',
									alignItems: 'center',
									gap: 4,
								}}
							>
								<NextLink href={`/produkter`} passHref>
									<Link variant={'link'}>
										<Text5>Produkter</Text5>
									</Link>
								</NextLink>
								<NextLink href={`/henteskap`} passHref>
									<Link variant={'link'}>
										<Badge color="primary" badgeContent={'Nytt!'}>
											<Text5>Henteskap</Text5>
										</Badge>
									</Link>
								</NextLink>
								<NextLink href={`/om-oss`} passHref>
									<Link variant={'link'}>
										<Text5>Om oss</Text5>
									</Link>
								</NextLink>
								<NextLink href={`/bli-produsent`} passHref>
									<Link variant={'link'}>
										<Text5>Selg lokalt</Text5>
									</Link>
								</NextLink>
								<Link
									as="a"
									target="_blank"
									rel="noreferrer noopener"
									color={'black'}
									href="https://blogg.matfra.no"
								>
									<Text5 color={'black'} fontWeight="regular" textAlign="left">
										Blogg
									</Text5>
								</Link>
								{customerProfile}
							</Box>

							<Box>
								<CartButton
									aria-label="Handlekurv"
									ml={3}
									onClick={() => setActiveSidebar(!activeSidebar)}
								>
									<svg
										width="22"
										height="30"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 22 30"
										{...props}
									>
										<path
											fill="none"
											stroke={'#000'}
											strokeWidth="1.5"
											d="M2 8.8h18c.7 0 1.2.6 1.2 1.2v18c0 .7-.6 1.2-1.2 1.2H2c-.7 0-1.2-.6-1.2-1.2V10c0-.7.5-1.2 1.2-1.2zM6.8 5C6.8 2.7 8.7.8 11 .8c2.3 0 4.2 1.9 4.2 4.2v3.6H6.8V5z"
										/>
									</svg>
									<NoSsr>
										{cart.countProducts > 0 && <CartItems>{cart.countProducts}</CartItems>}
									</NoSsr>
								</CartButton>
							</Box>
						</Box>
					</Col>
				</Flex>
			</Container>

			<CartSidebar
				activeSidebar={activeSidebar}
				onSetActiveSidebar={setActiveSidebar}
				cart={cart}
			/>
		</NavbarWrapper>
	)
}

export default Navbar

const CartButton = styled(LegacyButton)`
	cursor: pointer;
	background: transparent;
	border: 0;
	border-radius: 0;
	padding: 0;
	position: relative;
`

const CartItems = styled(Text5)`
	font-size: 12px;
	position: absolute;
	top: calc(50% + 3px);
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #027b48;
	line-height: 12px;
	font-weight: 600;
`

const NavbarWrapper = styled(Flex)`
	position: relative;
	top: 0;
	padding-top: 15px;
	padding-bottom: 30px;
	width: 100%;
	background-color: ${(props) => props.theme.colors.white};
`
