import { useEffect } from 'react'

export function usePersistedContext(context, key = 'matfra-state') {
	if (typeof window !== 'undefined') {
		const persistedContext = localStorage.getItem(key)
		return persistedContext ? JSON.parse(persistedContext) : context.state
	}
	return context.state
}

export function usePersistedReducer([state, dispatch], key = 'matfra-state') {
	if (typeof window !== 'undefined') {
		useEffect(() => localStorage.setItem(key, JSON.stringify(state)), [state])
	}
	return [state, dispatch]
}
