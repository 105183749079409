import React from 'react'
import { Text } from 'rebass/styled-components'

export const Text1 = (props) => (
	<Text
		as="h1"
		{...props}
		sx={{
			fontSize: '32px',
			lineHeight: '42px',
			'@media screen and (min-width: 40em)': {
				fontSize: '48px',
				lineHeight: '56px',
			},
		}}
	/>
)

export const Text2 = (props) => (
	<Text
		as="h2"
		{...props}
		sx={{
			fontSize: '28px',
			lineHeight: '38px',
			'@media screen and (min-width: 40em)': {
				fontSize: '40px',
				lineHeight: '48px',
			},
		}}
	/>
)

export const Text3 = (props) => (
	<Text
		as="h3"
		{...props}
		sx={{
			fontSize: '24px',
			lineHeight: '34px',
			'@media screen and (min-width: 40em)': {
				fontSize: '32px',
				lineHeight: '40px',
			},
		}}
	/>
)

export const Text4 = (props) => (
	<Text
		as="h4"
		{...props}
		sx={{
			fontSize: '20px',
			lineHeight: '30px',
			'@media screen and (min-width: 40em)': {
				fontSize: '24px',
				lineHeight: '36px',
			},
		}}
	/>
)

export const Text5 = (props) => (
	<Text
		as="h5"
		{...props}
		sx={{
			fontSize: '18px',
			lineHeight: '26px',
			'@media screen and (min-width: 40em)': {
				fontSize: '20px',
				lineHeight: '32px',
			},
		}}
	/>
)

export const Text6 = (props) => (
	<Text
		as="p"
		{...props}
		sx={{
			fontSize: '16px',
			lineHeight: '20px',
			'@media screen and (min-width: 40em)': {
				fontSize: '18px',
				lineHeight: '30px',
			},
		}}
	/>
)

export const Text7 = (props) => (
	<Text
		as="p"
		{...props}
		sx={{
			fontSize: '14px',
			lineHeight: '20px',
			'@media screen and (min-width: 40em)': {
				fontSize: '16px',
				lineHeight: '24px',
			},
		}}
	/>
)

export const Text8 = (props) => (
	<Text
		as="p"
		{...props}
		sx={{
			fontSize: '12px',
			lineHeight: '18px',
			'@media screen and (min-width: 40em)': {
				fontSize: '14px',
				lineHeight: '20px',
			},
		}}
	/>
)
