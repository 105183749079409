import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import StoreProvider from '../context/store'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../i18n/config'
import { ApolloProvider } from '@apollo/client'
import { Auth0Provider } from '@auth0/auth0-react'
import App, { AppContext } from 'next/app'
import { useApollo } from '../lib/apolloClient'
import { ProfileProvider } from '../context/profile/provider'

// Styles
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import theme from '../styles/theme'
import GlobalFontStyles from '../styles/GlobalFontStyles'

// Sentry
import * as Sentry from '@sentry/browser'

// Stripe
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

// SEO
import { DefaultSeo } from 'next-seo'

// Dayjs i18n
import 'dayjs/locale/nb'

// https://github.com/apollographql/apollo-client/issues/6765
import fetch from 'node-fetch'
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill'
import { muiThemeProps } from '../styles/muiTheme'
import { apolloUrl, frontendUrl } from '../lib/url'
import { ProductFilterProvider } from '../context/product/provider'
import { GdprScripts } from '../components/Gdpr/Scripts'

global.fetch = abortableFetch(fetch).fetch

Sentry.init({
	enabled: process.env.NODE_ENV === 'production',
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
})

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK_KEY)
const muiTheme = createTheme(muiThemeProps)

// TODO: https://stackoverflow.com/questions/64792217/how-to-add-facebook-pixel-on-next-js-react-app

const MatfraApp = ({ Component, pageProps, err }) => {
	const router = useRouter()

	// Use Facebook Pixel for router if provided
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (!window.fbq) {
			return
		}

		// This pageview only triggers the first time (it's important for Pixel to have real information)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.fbq('track', 'PageView')
		console.debug('[Facebook Pixel] - Track HomePageView')

		const handleRouteChange = () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.fbq('track', 'PageView')
			console.debug('[Facebook Pixel] - Track PageView')
		}

		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router.events])

	// GDPR on init
	// It provides information about preferences
	// TODO: We have to integrate a logic where we say: if GDPR is accepted, load React Pixel, etc.
	const onGdprChange = async (preferences) => {
		// console.debug("GDPR Preferences", preferences);
		//
		// if (preferences?.isEssentialAccepted) {
		//
		//   // Grant Facebook Pixel
		//   // Facebook Pixel ID is defined only on production
		//   if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
		//     const { default: ReactPixel } = await import("react-facebook-pixel");
		//     ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, null, {
		//       autoConfig: true,
		//       debug: true
		//     });
		//     ReactPixel.grantConsent();
		//     ReactPixel.pageView();
		//     router.events.on("routeChangeComplete", () => {
		//       ReactPixel.pageView();
		//     });
		//   }
		//
		// }
	}

	// Workaround for https://github.com/zeit/next.js/issues/8592
	const modifiedPageProps = { ...pageProps, err }

	return (
		<>
			<DefaultSeo
				title={'Matfra.no – Ferske råvarer, direkte fra din lokale bonde'}
				description={
					'Utforsk lokale produsenter og varer, og betal på nett. Hent dine varer ferdig pakket hos din lokale bonde. Spis med god samvittighet!'
				}
				openGraph={{
					url: router.pathname,
					title: 'Matfra.no – Ferske råvarer, direkte fra din lokale bonde',
					description:
						'Utforsk lokale produsenter og varer, og betal på nett. Hent dine varer ferdig pakket hos din lokale bonde. Spis med god samvittighet!',
					images: [
						{
							url: 'https://www.matfra.no/static/share.jpg',
							type: 'image/jpeg',
						},
					],
					site_name: 'Matfra.no',
				}}
			/>

			<Auth0Provider
				domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ''}
				clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ''}
				redirectUri={`${frontendUrl}/profil`}
				cacheLocation="localstorage"
			>
				<Auth0Apollo pageProps={pageProps}>
					<Elements stripe={stripePromise}>
						<ProfileProvider>
							<ProductFilterProvider>
								<ThemeProvider theme={muiTheme}>
									<StyledThemeProvider theme={theme}>
										<StoreProvider>
											<GdprScripts />
											{/*<GdprDialog onInit={onGdprChange} onChange={() => {*/}
											{/*  console.log("ON change");*/}
											{/*}} />*/}
											<GlobalFontStyles />
											<Navbar />
											<main style={{ minHeight: '100vh' }}>
												<Component {...modifiedPageProps} />
											</main>
											<Footer />
										</StoreProvider>
									</StyledThemeProvider>
								</ThemeProvider>
							</ProductFilterProvider>
						</ProfileProvider>
					</Elements>
				</Auth0Apollo>
			</Auth0Provider>
		</>
	)
}

interface ICProps {
	pageProps: any
	children: any
}

const Auth0Apollo = ({ pageProps, children }: ICProps) => {
	const apolloClient = useApollo(apolloUrl, pageProps)
	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

MatfraApp.getInitialProps = async (appContext: AppContext) => {
	const appProps = await App.getInitialProps(appContext)
	return {
		pageProps: {
			...appProps.pageProps,
		},
	}
}

export default MatfraApp
