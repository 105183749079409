import i18n from 'i18next'
import noCommon from './no/common.json'
import noProduct from './no/product.json'
import { initReactI18next } from 'react-i18next'

export const resources = {
	no: {
		common: noCommon,
		product: noProduct,
	},
} as const

i18n.use(initReactI18next).init({
	lng: 'no',
	ns: ['common', 'product'],
	interpolation: {
		escapeValue: false,
	},
	resources,
})
