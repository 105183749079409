import React from 'react'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'
import { Text4 } from '../Typography'

interface IStepperProps {
	amount: number
	setAmount: (amount: number) => void
}

const Stepper = ({ amount, setAmount }: IStepperProps) => {
	return (
		<React.Fragment>
			<Flex justifyContent="center" alignItems="center">
				<Ticker type="button" onClick={() => setAmount(amount > 0 ? amount - 1 : 0)}>
					<svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<circle cx="10" cy="10" r="9.5" fill="#fff" stroke="#027b48" />
						<path fill="#fff" stroke="#027b48" strokeWidth=".482" d="M6.2 10.2h6.5v.5H6.2z" />
					</svg>
				</Ticker>
				<Number>{amount}</Number>
				<Ticker type="button" onClick={() => setAmount(amount + 1)}>
					<svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<circle cx="10" cy="10" r="9.5" fill="#fff" stroke="#027b48" />
						<path
							d="M10.5 6.4h-.8v3.3H6.4v.8h3.3v3.3h.8v-3.3h3.3v-.8h-3.3V6.4z"
							fillRule="evenodd"
							clipRule="evenodd"
							fill="#027b48"
						/>
					</svg>
				</Ticker>
			</Flex>
		</React.Fragment>
	)
}

const Ticker = styled.button`
	width: 20px;
	height: 20px;
	border: none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	margin-top: 2px;
	background: transparent;
	/* border: 1px solid ${(props) => props.theme.colors.green100}; */
`

const Number = styled(Text4)`
	min-width: 32px;
	color: black;
	text-align: center;
	line-height: 0px;
	padding: 0;
`

export default Stepper
