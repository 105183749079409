import React from 'react'
import { Flex, Box } from 'rebass/styled-components'
import { Text4 } from '../Typography'
import LineItem from './LineItem'
import { useReadCartMerchantQuery } from '../../apis/generated/graphql'

interface IProps {
	merchantId: string
	products: Array<string>
}

export const MerchantLine = ({ merchantId, products }: IProps) => {
	const { data, loading, error } = useReadCartMerchantQuery({
		variables: {
			merchantId,
		},
	})

	if (loading) {
		return null
	}

	if (error) {
		return <p>{error.message}</p>
	}

	return (
		<Box mt={4} key={`group-${merchantId}`}>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				bg="deepGreen10"
				mb={3}
				style={{ padding: '15px 24px' }}
			>
				<Text4 fontWeight="medium">{data?.merchant.title}</Text4>
			</Flex>
			{products.map((productId) => (
				<LineItem key={`lineitem-${productId}`} merchantId={merchantId} productId={productId} />
			))}
		</Box>
	)
}
