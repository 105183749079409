import React, { useContext, useReducer } from 'react'
import { AppContext } from './context'
import reducer, { Action } from './reducer'
import { usePersistedContext, usePersistedReducer } from './usePersist'

// Store provides a permanent context-provider using local storage.
const Store = ({ children }) => {
	const globalStore = usePersistedContext(useContext(AppContext), 'matfraState')
	const [state, dispatch] = usePersistedReducer(useReducer(reducer, globalStore), 'matfraState')
	return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}

export default Store
