import { useMemo } from 'react'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { apolloUrl } from './url'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient

function createApolloClient() {
	return new ApolloClient({
		ssrMode: typeof window === 'undefined',
		cache: new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {
						// https://www.apollographql.com/docs/react/pagination/key-args/
						readMerchantsWithProducts: relayStylePagination([
							'categoryId',
							'pickupId',
							'location',
							'maxDistanceInKm',
							'maxProductsPerMerchant',
							'searchTerm',
							'filterByAllergens',
							'filterByCertificates',
							'filterByDiets',
						]),
						readOrdersAsUser: relayStylePagination(),
					},
				},
			},
		}),
	})
}

export function initializeApollo(
	uri: string,
	link?: ApolloLink,
	initialState = null
): ApolloClient<any> {
	const _apolloClient = apolloClient ?? createApolloClient()

	// Set link if provided (for SSR, the initialization of the Apollo client does not need authorization API calls, see `initializeApolloForSSR`)
	if (link) {
		_apolloClient.setLink(link)
	}

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract()

		// Merge the initialState from getStaticProps/getServerSideProps in the existing cache
		const data = merge(existingCache, initialState, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
			],
		})

		// Restore the cache with the merged data
		_apolloClient.cache.restore(data)
	}

	// For SSG and SSR always create a new Apollo Client
	// (TODO: it seems correct here, if SSR we return the apollo client without assigning it to the global one, but is it possible that SSR uses the apollo client set by client rendering)
	if (typeof window === 'undefined') return _apolloClient

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient

	return _apolloClient
}

export function initializeApolloForSSR(uri: string, initialState = null) {
	const httpLink = new HttpLink({ uri })
	const _apolloClient = createApolloClient()
	_apolloClient.setLink(httpLink)
	return _apolloClient
}

export function addApolloState(client, pageProps) {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
	}
	return pageProps
}

export const useApollo = (uri: string, pageProps) => {
	const state = pageProps[APOLLO_STATE_PROP_NAME]
	const { getIdTokenClaims } = useAuth0()

	const httpLink = new HttpLink({ uri })

	const authLink = setContext(async (_, { headers, ...rest }) => {
		let token
		try {
			token = await getIdTokenClaims()
		} catch (error) {
			console.log(error)
		}

		if (!token) return { headers, ...rest }

		return {
			...rest,
			headers: {
				...headers,
				Authorization: `Bearer ${token.__raw}`,
			},
		}
	})

	return useMemo(() => initializeApollo(uri, authLink.concat(httpLink), state), [state])
}
