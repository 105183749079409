import KitchenIcon from '@mui/icons-material/Kitchen'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import FoodBankIcon from '@mui/icons-material/FoodBank'
import PlaceIcon from '@mui/icons-material/Place'
import { TemperatureModeEnum } from '../../apis/generated/graphql'

//Added default icon to ensure that the site does not crash if we get unexpected temperatureModes from StrongPoint
export const getIconByTemperature = (temp: TemperatureModeEnum) => {
	let icon: any
	switch (temp) {
		case TemperatureModeEnum.Ambient:
			icon = FoodBankIcon
			break
		case TemperatureModeEnum.Chilled:
			icon = KitchenIcon
			break
		case TemperatureModeEnum.Frozen:
			icon = AcUnitIcon
			break
		default:
			icon = PlaceIcon
	}
	return icon
}
