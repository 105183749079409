import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  normal;
    font-weight: 100;
    src: url("/static/fonts/FoundersGroteskWeb-Light.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-Light.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-Light.woff") format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  italic;
    font-weight: 100;
    src: url("/static/fonts/FoundersGroteskWeb-LightItalic.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-LightItalic.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-LightItalic.woff") format("woff");
  }
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  normal;
    font-weight: 400;
    src: url("/static/fonts/FoundersGroteskWeb-Regular.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-Regular.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-Regular.woff") format("woff");
  }
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  italic;
    font-weight: 400;
    src: url("/static/fonts/FoundersGroteskWeb-RegularItalic.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-RegularItalic.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-RegularItalic.woff") format("woff");
  }
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  normal;
    font-weight: 500;
    src: url("/static/fonts/FoundersGroteskWeb-Medium.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-Medium.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-Medium.woff") format("woff");
  }
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  italic;
    font-weight: 500;
    src: url("/static/fonts/FoundersGroteskWeb-MediumItalic.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-MediumItalic.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-MediumItalic.woff") format("woff");
  }
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  normal;
    font-weight: 600;
    src: url("/static/fonts/FoundersGroteskWeb-Semibold.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-Semibold.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-Semibold.woff") format("woff");
  }
  @font-face {
    font-display: swap;
    font-family: 'Founders';
    font-style:  italic;
    font-weight: 600;
    src: url("/static/fonts/FoundersGroteskWeb-SemiboldItalic.woff2") format("woff2"),
         url("/static/fonts/FoundersGroteskWeb-SemiboldItalic.eot") format("eot"),
         url("/static/fonts/FoundersGroteskWeb-SemiboldItalic.woff") format("woff");
  }
  a, button {
    font-family: "Founders", system-ui, sans-serif;
  }
  body {
    margin: 0;
    font-family: "Founders", system-ui, sans-serif;
    font-size: 16px;
    font-weight: normal;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-width: 100%;
    border: 0;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: auto;
  }

  .no-flicker {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
}

.absolutecenter {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%)
}

/* Main input wrapper */
.algolia-places {
  position: relative;
  font-size:16px;
  line-height:20px;
  @media screen and (min-width: 40em) {
    font-size:18px;
    line-height:30px;
  }
  /* :after {
    content: '';
    position: absolute;
    left: 15px;
    top: 20px;
    width: 16px;
    height: 16px;
    background-image: url('/info.svg');
    background-repeat: no-repeat;
  } */
}

/* The algolia-places input */
.ap-input, .ap-hint {
  padding: 11px 15px 12px 12px;
  height:auto;
}

.ap-input, .ap-hint, .ap-dropdown-menu {
  border: 1px solid #D1D1D1;
  border-radius:4px;
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000;
    }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #000;
  }
}

/* The dropdown style */
.ap-dropdown-menu {
  margin-top:-6px;
  box-shadow:none;
  z-index:2;
  position:relative;
  border-top-left-radius:0px;
  border-top-right-radius:0px;
}

/* The style of the svg icons when the input is on hover */
.ap-input:hover ~ .ap-input-icon svg,
.ap-input:focus ~ .ap-input-icon svg,
.ap-input-icon:hover svg {}

/* The suggestions style */
.ap-suggestion {}

/* The highlighted names style */
.ap-suggestion em {}

/* The addresses style */
.ap-address {}

/* The icons of each suggestions ( can be a building or a pin ) */
.ap-suggestion-icon {}

/* The style of the svg inside the .ap-suggestion-icon */
.ap-suggestion-icon svg {
}

/* The icons inside the input ( can be a pin or a cross ) */
.ap-input-icon {}

/* The style of the svg inside the .ap-input-icon */
.ap-input-icon svg {}

/* .a-cursor is the class a suggestion go on hover */
.ap-cursor {}

/* The style of the svg icon, when the .ap-suggestion is on hover */
.ap-cursor .ap-suggestion-icon svg {}

/* The styles of the Algolia Places input footer */
.ap-footer {}

/* The styles of the Algolia Places input footer links */
.ap-footer a {}

/* The styles of the Algolia Places input footer svg icons */
.ap-footer a svg {}

/* The styles of the Algolia Places input footer on hover */
.ap-footer:hover {}


`

export default GlobalStyles
