import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Flex, Box, Button } from 'rebass/styled-components'
import { Text4, Text5, Text7 } from '../Typography'
import Price from '../Price'
import {
	Cart,
	getCartTotal,
	getMerchantIdsFromCart,
	getProductIdsByMerchant,
	isCartEmpty,
} from '../../context/store/context'
import { MerchantLine } from './MerchantLine'
import { NoSsr } from '@mui/base'

interface IProps {
	activeSidebar: boolean
	onSetActiveSidebar: (v: boolean) => void
	cart: Cart
}

const CartSidebar = ({ activeSidebar, onSetActiveSidebar, cart }: IProps) => {
	const router = useRouter()
	const total = getCartTotal(cart)

	const lines = useMemo(() => {
		return getMerchantIdsFromCart(cart).map((merchantId) => {
			const productsIds = getProductIdsByMerchant(cart, merchantId)
			return <MerchantLine key={merchantId} merchantId={merchantId} products={productsIds} />
		})
	}, [cart])

	return (
		<NoSsr>
			<Sidebar width={['auto', '480px']} active={activeSidebar}>
				<Flex height="100%" flexDirection="column" justifyContent="space-between">
					<Box>
						<Flex alignItems="center" justifyContent="space-between">
							<Text4 fontWeight="semibold">
								<Text4 as="span">Din handlekurv</Text4>
								{cart.countProducts > 0 && <Text4 as="span"> ({cart.countProducts})</Text4>}
							</Text4>
							<Button
								aria-label="Lukk handlekurv"
								pr={0}
								variant="blank"
								onClick={() => onSetActiveSidebar(false)}
							>
								<svg width={24} height={24} fill="none">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
									/>
								</svg>
							</Button>
						</Flex>

						{isCartEmpty(cart) ? <Text5>Din handlekurv er tom</Text5> : lines}
					</Box>

					{!isCartEmpty(cart) && (
						<Box>
							<Box bg="yellow40" textAlign="center" py={3} px={4} mb={3}>
								<Text7>
									Du handler fra{' '}
									{cart.countUniqueMerchants > 1
										? `${cart.countUniqueMerchants} produsenter`
										: 'produsenten'}
									. Velg hente-/leveringsalternativ i kassen.
								</Text7>
							</Box>
							<Flex
								mb={4}
								py={2}
								justifyContent="space-between"
								style={{
									borderBottom: '1px solid #e6e6e6',
									borderTop: '1px solid #e6e6e6',
								}}
							>
								<Text4 fontWeight="regular">Totalt</Text4>
								<Text4 fontWeight="semibold">{total && <Price value={total} />}</Text4>
							</Flex>
							<SubmitButton
								onClick={() => {
									onSetActiveSidebar(false)
									router.push('/ordre/handlekurv')
								}}
								disabled={!total}
								variant="solid"
								width={1}
								mb={3}
							>
								Gå til kassen
							</SubmitButton>
							{/* 
              <Button variant="blank" mt={2} onClick={() => emptyCart()} width={1} mb={3}>
                Tøm handlekurven
              </Button> 
              */}
						</Box>
					)}
				</Flex>
			</Sidebar>
			<SidebarOverlay onClick={() => onSetActiveSidebar(false)} active={activeSidebar} />
		</NoSsr>
	)
}

interface ISidebarProps {
	active: boolean
}

const Sidebar = styled(Box)<ISidebarProps>`
	position: fixed;
	/* width: 481px; */
	height: 100%;
	right: 0;
	top: 0;
	background: white;
	box-shadow: ${(props) => (props.active ? '0px 0px 5px rgba(0, 0, 0, 0.16)' : 'none')};
	overflow-y: scroll;
	padding: 24px 24px;
	z-index: 999;
	transition: transform 0.7s cubic-bezier(0.19, 1, 0, 0.99);
	transform: ${(props) => (props.active ? 'translateX(0%)' : 'translateX(100%)')};
`
const SidebarOverlay = styled(Box)<ISidebarProps>`
	background: rgba(31, 35, 33, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8;
	transition:
		opacity 0.3s ease-in-out,
		visibility 0.3s ease-in-out;
	opacity: ${(props) => (props.active ? 1 : 0)};
	visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
`

const SubmitButton = styled(Button)`
	opacity: ${(props) => (props.disabled ? 0.2 : 1)};
`

export default CartSidebar
