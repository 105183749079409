import React from 'react'
import { Text6 } from '../Typography'
import OrderSteps from '../OrderSteps'
import { useRouter } from 'next/router'

const paths = [
	{
		name: 'Din bestilling',
		path: '/ordre/handlekurv',
	},
	{
		name: 'Kontaktinformasjon',
		path: '/ordre/personopplysninger',
	},
	{
		name: 'Betaling',
		path: '/ordre/betaling',
	},
	{
		name: 'Bekreftelse',
		path: '/ordre/bekreftelse',
	},
]

const BreadcrumbOrder = (props) => {
	const router = useRouter()
	const currentIndex = paths.findIndex((p) => p.path === router.pathname)

	return (
		<OrderSteps>
			{paths.map((p, index) => (
				<React.Fragment key={index}>
					{index === currentIndex ? (
						<Text6 fontWeight="medium">{p.name}</Text6>
					) : (
						<Text6>{p.name}</Text6>
					)}

					{index !== paths.length - 1 && (
						<Text6 color="gray50" mx={3} lineHeight={['20px', '34px']}>
							→
						</Text6>
					)}
				</React.Fragment>
			))}
		</OrderSteps>
	)
}

export default BreadcrumbOrder
